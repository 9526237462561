/* @media (min-width: 576px){
    .padding-0{
        padding: 0 !important;
    }
    .img-fluid {
        max-width: 100%;
        height: auto;
        min-height: 100px;
        max-height: 200px;
    }
} */

.card-style {
  display: inline-block;
  margin-bottom: 2em;
  /* margin-left: 50px; */
  margin-top: 50px;
}
.h-title {
  color: black;
  font-size: 18px;
}
.text-secondary {
  color: #6c757d !important;
  max-height: 200px;
  min-height: 100px;
  padding: 0 10px;
  text-align: justify;
  font-size: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.image-container {
  text-align: center;
  border: 1px solid darkgrey;
  background-color: white;
  margin-top: 1em;
  border-radius: 15px;
  box-shadow: 0 2px 8px 0 white;
}
.no-cards-available {
  display: flex;
  justify-content: center;
  margin-top: 5em;
}
.btn-outline-success {
  color: #28a745 !important;
  border-color: #28a745;
  border-radius: 15px !important;
}
.btn-outline-success:hover {
  color: #fff !important;
  background-color: #28a745;
  border-color: #28a745;
}
.CardsLoader {
  margin-top: 40%;
  margin-left: 55%;
}
@media screen and (min-width: 250px) and (max-width: 750px) {
  .CardsLoader {
    margin-top: 60%;
    margin-left: 40%;
  }
  .rightContainer {
    padding-left: 0;
    padding-right: 0;
  }
}

/* Portrait and Landscape */
@media only screen and (min-device-width: 240px) and (max-device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
  .CardsLoader {
    margin-top: 60%;
    margin-left: 40%;
  }
  .rightContainer {
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (min-device-width: 321px) and (max-device-width: 360px) and (-webkit-min-device-pixel-ratio: 2) {
  .rightContainer {
    padding-left: 0;
    padding-right: 0;
  }

  .CardsLoader {
    margin-top: 60%;
    margin-left: 40%;
  }
}
@media only screen and (min-device-width: 361px) and (max-device-width: 375px) and (-webkit-min-device-pixel-ratio: 2) {
  .rightContainer {
    padding-left: 0;
    padding-right: 0;
  }

  .CardsLoader {
    margin-top: 60%;
    margin-left: 40%;
  }
}
@media only screen and (min-device-width: 376px) and (max-device-width: 414px) and (-webkit-min-device-pixel-ratio: 2) {
  .CardsLoader {
    margin-top: 60%;
    margin-left: 40%;
  }
  .rightContainer {
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (min-device-width: 415px) and (max-device-width: 768px) and (-webkit-min-device-pixel-ratio: 2) {
  .CardsLoader {
    margin-top: 60%;
    margin-left: 40%;
  }

  .rightContainer {
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (min-device-width: 400px) and (max-device-width: 850px) and (-webkit-min-device-pixel-ratio: 2) {
  .rightContainer {
    padding-left: 0;
    padding-right: 0;
  }
}
