.selfContainer{
    height: 100vh;
    
    display: flex;
    justify-content: center;
    
}
.nameContainer{
    color: red;
}

.selfContainer .selfchildContainer{
    background-color:transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 50px 50px;
    height: 100%;
    width: 400px;
    text-align: center;
}
.contineBtn{
    width: 50%;
    height: 7%;
    text-align: center;
    padding: 4px;
    border-radius: 20px
}