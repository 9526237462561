/* .sidenav {
  min-height: 100vh;
  max-height: 200vh;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #f0f0f0;
  overflow-x: hidden;
  color: white;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.sidenav div{
  margin-top: 72px;
}

.sidenav div a {
  padding: 6px 0px 6px 0px;
  text-decoration: none;
  font-size: 20px;
  color: #293846;
  display: block;
  text-align: center;
}

.active {
  background-color: #293846;
  color: white !important;
  border-radius: 20px;
}

.sidenav div a:hover {
  background-color: #293846;
  color: white !important;
  cursor: pointer;
  border-radius: 20px;
}
  */

.mob_sidebar {
  height: 100%;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  background-color: #f0f0f0;
  color: white;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}
.active {
  background-color: #293846;
  color: white !important;
  border-radius: 20px;
}
.mob_sidebar a,
.closebutton_link {
  /* padding: 8px 8px 8px 32px; */
  padding: 0.5rem 1rem;
  text-decoration: none;
  font-size: 25px;
  color: #293846;
  display: block;
  transition: 0.3s;
  /* margin-top:20px; */
}

.mob_sidebar a:hover,
.closebutton_link:hover {
  /* color:  #293846; */
  color: white;
  background-color: #293846;
  cursor: pointer;
  border-radius: 20px;
}

.mob_sidebar .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.openbtn {
  font-size: 20px;
  cursor: pointer;
  background-color: #293846;
  color: white;
  padding: 10px 15px;
  border: none;
}

.openbtn:hover {
  background-color: #293846;
}

#main {
  transition: margin-left 0.5s;
  padding: 16px;
}

.sidebar__notication {
  display: flex;
  justify-content: space-between;
}

.sidebar__notication span {
  background-color: green;
  /* width: 25px; */
  /* height: 27px; */
  padding: 5px 8px;
  width: 5vw;
  height: 5vw;

  margin-left: 4px;
  color: white;
  border-radius: 50%;
  text-align: center;
}

.sidebar__notication span:nth-child(2) {
  background-color: red;
}

@media screen and (max-height: 450px) {
  .mob_sidebar {
    padding-top: 15px;
  }
  .mob_sidebar a {
    font-size: 18px;
  }
}

@media screen and (min-width: 850px) and (max-width: 2800px) {
  .mob_sidebar {
    padding-top: 15px;
  }
  .mob_sidebar a,
  .sidebar__notication,
  .sidebar__notication span {
    font-size: 18px;
  }
  #mySidebar {
    width: 250px;
  }
  .mob_sidebar .closebtn {
    display: none;
  }
  #main {
    display: none !important;
  }
}
