@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;400;500;700&family=Quicksand:wght@300;400;500;700&display=swap");
.paradigm_parentcontainer {
  padding: 2rem 0;
}

.paradigm_container {
  /* padding: 2rem; */
  display: flex;
  flex: 1;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;
  /* border: 2px solid cadetblue; */
}

.paradigm_video-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  /* border: 2px solid aqua; */
}
.paradigm_videoCard {
  margin-top: 1rem;
  width: 570px;
  /* height: 50vh; */
  cursor: pointer;
  border: 2px solid white;
  border-radius: 2%;
  margin: 1vw;
  /* margin-bottom: 1vh; */
  aspect-ratio: 16/9;
}
.paradigm_videoCard iframe {
  /* width: 560px; */
  width: 100%;
  height: 315px;
}
.paradigm_videoCard a {
  color: white;
  text-decoration: none;
}

.paradigm_videoCard a:hover {
  color: white;
}

.paradigm_videoCard_a {
  color: white;
  text-decoration: none;
}
.paradigm_videoCard_a:hover {
  color: white;
}
.paradigm_sessions {
  text-align: center;
}
.paradigm_sessions h1 {
  margin: 1rem 0;
}

.paradigm_title-heading {
  margin: 1rem 0;
}

.paradigm_articleList {
  height: 100%;
  /* overflow: scroll; */
  width: 100%;
  padding: 0 2rem;
  /* text-align: center; */
}

.paradigm_articleList h1 {
  font-family: "Josefin Sans", sans-serif;
}
.paradigm_articleList p {
  font-family: "Quicksand", sans-serif;
  font-size: 1rem;
}

.paradigm_sessions_logo {
  width: 15vw;
  height: 15vh;
  margin: 1rem 0;
  object-fit: contain;
}
/* 
? --------------------
? Card
? --------------------
*/
.paradigm__cardContainer {
  width: 25vw;
  height: 100%;
  cursor: pointer;
  border: 2px solid white;
  border-radius: 2%;
  margin: 1vw;
  /* margin-bottom: 1vh; */
  aspect-ratio: 16/9;
}
.paradigm__cardContainer h3 {
  margin-top: 1rem;
  text-decoration: none;
  color: white;
  padding: 0 1rem;
  font-size: 1.5vw;
}

.paradigm__cardContainer p {
  /* max-width: 25vw; */
  text-align: center;
  padding: 0 0.5rem;
  font-size: 1rem;
  color: rgba(170, 162, 162, 0.966);
  overflow: hidden;
  /* border: 2px solid rebeccapurple; */
}

.paradigm__card_img {
  width: 100%;
  /* height: 18vw; */
  /* height: 15vw; */
  object-fit: contain;
  aspect-ratio: 16/9;
}
.paradigm__card_button {
  margin: 1rem auto;
  display: block;
  border-radius: 500px;
}

/* 
* ---------------------------
* Pdf 
* ---------------------------
*/

.paradigm_pdfParentContainer {
  /* border: 2px solid red; */
  min-height: 90vh;
}

.paradigm_pdfCard {
  height: 45vh;
  /* border: 2px solid rebeccapurple; */
}
.paradigm__PDFcard_img {
  width: 100%;
  height: 25vh;
  /* height: 18vw; */
  /* height: 15vw; */
  object-fit: contain;
  aspect-ratio: 16/9;
}
.paradigm__pdfContainer_p {
  height: 10vh;
  /* font-size: 1vw !important; */
  /* margin: 0; */
}

.paradigm__pdfViewer {
  width: 83vw;
  height: 85vh;
}
@media only screen and (max-width: 1025px) {
  .paradigm__pdfViewer {
    width: 75vw;
  }
}

@media only screen and (max-width: 825px) {
  .paradigm_pdfCard {
    height: 50vh;
  }
}

@media only screen and (max-width: 801px) {
  .paradigm__pdfViewer {
    width: calc(100vw - 16rem);
  }
}

@media only screen and (min-width: 769px) {
  .paradigm_vid_Box {
    /* width: 39vw !important; */
    width: 560px !important;
  }

  .paradigm__pdfContainer_p {
    font-size: 1.25vw !important;
    margin: 0;
  }
}

@media only screen and (max-width: 769px) {
  .paradigm_container {
    margin-left: 33vw;
  }
  .paradigm__cardContainer {
    margin-bottom: 1vh;
  }
  .paradigm__pdfViewer {
    width: 90%;
    height: 99vh;
  }
}
@media only screen and (max-width: 769px) {
  .paradigm_container {
    margin-left: 0;
    display: block;
  }
  /* .paradigm__cardContainer {
    width: 90vw;
  }
  .paradigm__cardContainer h3 {
    font-size: 6vw;
  } */
  .paradigm_videoCard {
    width: 90vw;
  }
}

@media only screen and (max-width: 500px) {
  .paradigm__cardContainer {
    width: 90vw;
  }
  .paradigm__cardContainer h3 {
    font-size: 6vw;
  }
  .paradigm_pdfCard {
    height: 20rem;
  }
  .paradigm__PDFcard_img {
    height: 10rem;
  }
}
