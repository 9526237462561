.select-mupp-checkbox{
width: 25px;
height: 25px;
cursor: pointer;
}
.muppOrderDiv{
    padding:0 !important;
}
.muppOrderTable{
    position: sticky;
    z-index: 9999;
    background-color: white;
    color: black;  
    margin-top: 10px;
}