
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');

* {
	box-sizing: border-box;
}

h1 {
	font-weight: bold;
	margin: 0;
}

h2 {
	text-align: center;
}

p {
	font-size: 14px;
	font-weight: 100;
	line-height: 20px;
	letter-spacing: 0.5px;
	margin: 20px 0 30px;
}

span {
	font-size: 12px;
}

a {
	color:#007bff;
	font-size: 18px;
	text-decoration: none;
	margin: 15px 0;
}

button {
	border-radius: 20px;
	border: 1px solid white;
	background-color:black;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
	transition: transform 80ms ease-in;
}

button:active {
	transform: scale(0.95);
}

button:focus {
	outline: none;
}

button.ghost {
	background-color: transparent;
	border-color: #FFFFFF;
}
.btn-signin{
	border-radius: 5px;
}

form {
	background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 50px 50px;
    height: 100%;
    width: 400px;
	text-align: center;
	
}

.loginform input {
	background-color:rgb(48, 50, 64);
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 380px;
	color: white;
}

.loginform input::placeholder{
	color: white;
}


.loginform {
	background-color:transparent;
	position: relative;
	overflow: hidden;
	width: 400px;
	max-width: 100%;
	min-height: 480px;
	left: 35%;
	margin-top:50px;
	
}

.form-container {
	position: absolute;
	top: 0;
	height: 100%;
	transition: all 0.6s ease-in-out;
}
#rememberContainer{
	margin-right: 90px;
}

.sign-in-container {
	left: 0;
	width: 50%;
	z-index: 2;
	
}

.container.right-panel-active .sign-in-container {
	transform: translateX(100%);
}

.sign-up-container {
	left: 0;
	width: 50%;
	opacity: 0;
	z-index: 1;
}

.container.right-panel-active .sign-up-container {
	transform: translateX(100%);
	opacity: 1;
	z-index: 5;
	animation: show 0.6s;
}

@keyframes show {
	0%, 49.99% {
		opacity: 0;
		z-index: 1;
	}
	
	50%, 100% {
		opacity: 1;
		z-index: 5;
	}
}

.overlay-container {
	position: absolute;
	top: 0;
	left: 50%;
	width: 50%;
	height: 100%;
	overflow: hidden;
	transition: transform 0.6s ease-in-out;
	z-index: 100;
}

.container.right-panel-active .overlay-container{
	transform: translateX(-100%);
}

.overlay {
	background: #FF416C;
	background: -webkit-linear-gradient(to right, #FF4B2B, #FF416C);
	background: linear-gradient(to right, #FF4B2B, #FF416C);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0;
	color: #FFFFFF;
	position: relative;
	left: -100%;
	height: 100%;
	width: 200%;
  	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}

.container.right-panel-active .overlay {
  	transform: translateX(50%);
}

.overlay-panel {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 40px;
	text-align: center;
	top: 0;
	height: 100%;
	width: 50%;
	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}

.overlay-left {
	transform: translateX(-20%);
}

.container.right-panel-active .overlay-left {
	transform: translateX(0);
}

.overlay-right {
	right: 0;
	transform: translateX(0);
}

.container.right-panel-active .overlay-right {
	transform: translateX(20%);
}

.social-container {
	margin: 20px 0;
}

.social-container a {
	border: 1px solid #DDDDDD;
	border-radius: 50%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin: 0 5px;
	height: 40px;
	width: 40px;
}

#Remember{
	color: white;
	margin-top: 5px;
	margin-left: 10px;
}
#rememberContainer{
	width: 100%;
	display: flex;
}
#ischecked{
	height: 20px;
	width: 20px;
	margin: 5px;
}
.imageSignup{
	justify-content: center;
	width:400px
}
@media screen and (min-width: 250px) and (max-width: 750px)  {
	.loginform {
		background-color: transparent;
		position: relative;
		overflow: hidden;
		width: 400px;
		max-width: 100%;
		min-height: 480px;
		left: 0%;
		margin-top: 30px;
	}
	form{
		display: inline-grid;
		align-items: center;
  
    flex-direction: unset;
    padding: unset;
    height: 100%;
    width: -webkit-fill-available;
    text-align: center;
	}
	.imageSignup{
		justify-content: center;
		width:285px
	}
}


 /* Portrait and Landscape */
 @media only screen 
 and (min-device-width: 240px) 
 and (max-device-width: 320px)
 and (-webkit-min-device-pixel-ratio: 2) {


.loginform input {
	background-color:rgb(48, 50, 64);
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 300px;
	color: white;
}
  

}
 @media only screen 
   and (min-device-width: 321px) 
   and (max-device-width: 360px)
   and (-webkit-min-device-pixel-ratio: 2) {


.loginform input {
	background-color:rgb(48, 50, 64);
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 300px;
	color: white;
}

	   
	
 }
 @media only screen 
 and (min-device-width: 361px) 
 and (max-device-width: 375px)
 and (-webkit-min-device-pixel-ratio: 2) {

.loginform input {
	background-color:rgb(48, 50, 64);
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 300px;
	color: white;
}
  
  
}
 @media only screen 
 and (min-device-width: 376px) 
 and (max-device-width: 414px)
 and (-webkit-min-device-pixel-ratio: 2) {


.loginform input {
	background-color:rgb(48, 50, 64);
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 300px;
	color: white;
}
  
}
@media only screen 
and (min-device-width: 415px) 
and (max-device-width: 768px)
and (-webkit-min-device-pixel-ratio: 2) {


.loginform input {
	background-color:rgb(48, 50, 64);
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 300px;
	color: white;
}
  

}