.tab-navs a{
    font-size: 16px;
    cursor: pointer;
}
.nav-link{
    padding: 15px;
    margin-top: 7px;
}
.nav-link.active{
    background-color: #293846 !important;
    color: white !important;
    border: none !important;
}
.nodata{
    margin-left: 40%;
}

.text-ellipses-h4-todo{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 20px;
}



.todo-tabs .todo-tab-li{
  background: white;
  color: black;
  padding: 10px;
  padding-left: 20px;
}
.todo-tab-li button{
  border-radius: 12px;
  margin-left:-20px;
}
  .btn-nav-tabs-todo{
    display: flex;
    padding: 0;
    border-style: groove;
    margin-bottom: 50px;
  }
  
  .btn-nav-tabs-todo li {
    padding: 10px;
    text-align: center;
    cursor: pointer;
    border-right: 1px solid;
    border-top: hidden;
    padding-right: 10%;
  }

  .btn-nav-tabs-todo li, .all-categories-tab-todo li{
    list-style-type: none;
  }

  .dailyHabbitBtn{
    margin-left: 0;
  }

  .btn-nav-tabs-todo li {
    padding: 10px;
    text-align: center;
    cursor: pointer;
    border-right: 1px solid;
    padding-right: 10%;

  }

.text-ellipses{
    white-space: nowrap;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.all-categories-tab{
  margin-top: 20px;
  
}
 .all-categories-tab-later li{
  list-style-type: none;
}
.all-categories-li{
  background: white;
  color: black;
  margin-bottom: 5px;
  border-radius: 12px;
  padding: 5px 0 0 10px;
  margin-right:10px;
  margin-left:10px;
}

.todo-tabs{
    padding: 0px 20px;
    margin-bottom: 10px;
    margin-top:20px;
    
}
.todo-tabs li{
    list-style-type: none;
    border-radius: 12px;
    display: flex;
}


a:focus {
    outline: none;
    border: none;
}
@media (max-width: 575px){
    .all-categories-li,  .add-task-header, .inbox-tab-li{
      text-align: center;   
    }
  }
  
  /* Portrait and Landscape */
  @media only screen 
  and (min-device-width: 240px) 
  and (max-device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .all-categories-tab{
      margin-top: -21px;
      float: right;
      width: 337px;
    }
    .elipse-text-for-title{
      width: 250px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
    }
    .all-categories-li {
      background: white;
      color: black;
      margin-bottom: 5px;
      border-radius: 12px;
      padding: 5px 0 0 10px;
      margin-right: 10px;
      margin-left: 10px;
      width: 322px;
  }
  .btn-nav-tabs-todo{
    padding: 0; 
    margin-bottom: 50px;
    display: block;
  }
  .btn-nav-tabs-todo li {
    padding: 10px;
    text-align: center;
    cursor: pointer; 
  }
  .todo-tabs{
    padding: 0px 20px;
    margin-bottom: 10px;
    margin-top:20px;
    margin-left: 15%;
    margin-right: -5%;
}
.todo-tabs .todo-tab-li{
  background: white;
  color: black;
  padding: 10px;
  padding-left: 20px;
  display: flow-root;
}
.dailyHabbitBtn{
  margin-left: 30%;
}
.dis-flex{
  display:grid;
  text-align: center;

}


}
  @media only screen 
    and (min-device-width: 321px) 
    and (max-device-width: 360px)
    and (-webkit-min-device-pixel-ratio: 2) {
      .all-categories-tab{
        margin-top: 20px;
        float: right;
        width: 377px;
      }
      .all-categories-li {
        background: white;
        color: black;
        margin-bottom: 5px;
        border-radius: 12px;
        padding: 5px 0 0 10px;
        margin-right: 10px;
        margin-left: 10px;
        width: 362px;
    }
    .elipse-text-for-title{
      width: 250px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
    }
    .btn-nav-tabs-todo{
      padding: 0; 
      margin-bottom: 50px;
      display: block;
    }
    .btn-nav-tabs-todo li {
      padding: 10px;
      text-align: center;
      cursor: pointer; 
    }
    .todo-tabs{
      padding: 0px 20px;
      margin-bottom: 10px;
      margin-top:20px;
      margin-left: 15%;
      margin-right: -5%;
  }
  .todo-tabs .todo-tab-li{
    background: white;
    color: black;
    padding: 10px;
    padding-left: 20px;
    display: flow-root;
  }
  .dailyHabbitBtn{
    margin-left: 30%;
  }
  .dis-flex{
    display:grid;
    text-align: center;

  }
  }
  @media only screen 
  and (min-device-width: 361px) 
  and (max-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .all-categories-tab{
      margin-top: 20px;
      float: right;
      width: 391px;
    }
    .elipse-text-for-title{
      width: 250px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
    }
    .all-categories-li {
      background: white;
      color: black;
      margin-bottom: 5px;
      border-radius: 12px;
      padding: 5px 0 0 10px;
      margin-right: 10px;
      margin-left: 10px;
      width: 372px;
  }
  .btn-nav-tabs-todo{
    padding: 0; 
    margin-bottom: 50px;
    display: block;

  } 
  
  .btn-nav-tabs li {
    padding: 10px;
    text-align: center;
    cursor: pointer; 
  }
  .todo-tabs{
    padding: 0px 20px;
    margin-bottom: 10px;
    margin-top:20px;
    margin-left: 15%;
    margin-right: -5%;
}
.todo-tabs .todo-tab-li{
  background: white;
  color: black;
  padding: 10px;
  padding-left: 20px;
  display: flow-root;
}
.dailyHabbitBtn{
  margin-left: 30%;
}
.dis-flex{
  display:grid;
  text-align: center;

}

}
  @media only screen 
  and (min-device-width: 376px) 
  and (max-device-width: 414px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .all-categories-tab{
      margin-top: 20px;
      float: right;
      width: 426px;
    }
    .elipse-text-for-title{
      width: 250px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
    }
    .all-categories-li {
      background: white;
      color: black;
      margin-bottom: 5px;
      border-radius: 12px;
      padding: 5px 0 0 10px;
      margin-right: 10px;
      margin-left: 10px;
      width: 412px;
  }
  .btn-nav-tabs-todo{
    padding: 0; 
    margin-bottom: 50px;
    display: block;
  }
  .btn-nav-tabs-todo li {
    padding: 10px;
    text-align: center;
    cursor: pointer; 
  }
  .todo-tabs{
    padding: 0px 20px;
    margin-bottom: 10px;
    margin-top:20px;
    margin-left: 15%;
    margin-right: -5%;
}
.todo-tabs .todo-tab-li{
  background: white;
  color: black;
  padding: 10px;
  padding-left: 20px;
  display: flow-root;
}
.dailyHabbitBtn{
  margin-left: 30%;
}
.dis-flex{
  display:grid;
  text-align: center;

}
}
@media only screen 
and (min-device-width: 415px) 
and (max-device-width: 768px)
and (-webkit-min-device-pixel-ratio: 2) {
  .all-categories-tab{
    margin-top: 20px;
    float: right;
    width: 564px;
  }
  .btn-nav-tabs-todo{
    padding: 0; 
    margin-bottom: 50px;
    display: block;
  }
  .elipse-text-for-title{
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }
  .btn-nav-tabs-todo li {
    padding: 10px;
    text-align: center;
    cursor: pointer; 
  }
  .todo-tabs{
    padding: 0px 20px;
    margin-bottom: 10px;
    margin-top:20px;
    margin-left: 15%;
    margin-right: -5%;
}
.todo-tabs .todo-tab-li{
  background: white;
  color: black;
  padding: 10px;
  padding-left: 20px;
  display: flow-root;
}
.dailyHabbitBtn{
  margin-left: 30%;
}
.dis-flex{
  display:grid;
  text-align: center;
}
}
