.successContainer{
    margin-top: 40vh;
    margin-left: 40vw;

}
#successBtn{
    margin-top: 20px;
    margin-left: 40px;

}
/* Portrait and Landscape */
@media only screen 
and (min-device-width: 240px) 
and (max-device-width: 320px)
and (-webkit-min-device-pixel-ratio: 2) {
    
    .successContainer{
        margin-top: 40vh;
        margin-left: 15vw;
    
    }



  

}
@media only screen 
  and (min-device-width: 321px) 
  and (max-device-width: 360px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .successContainer{
        margin-top: 40vh;
        margin-left: 15vw;
    
    }

   
  
   
      
   
}
@media only screen 
and (min-device-width: 361px) 
and (max-device-width: 375px)
and (-webkit-min-device-pixel-ratio: 2) {
    .successContainer{
        margin-top: 40vh;
        margin-left: 15vw;
    
    }
  
    

 
 
}
@media only screen 
and (min-device-width: 376px) 
and (max-device-width: 414px)
and (-webkit-min-device-pixel-ratio: 2) {
    .successContainer{
        margin-top: 40vh;
        margin-left: 15vw;
    
    }
 
   
 

 
}
@media only screen 
and (min-device-width: 415px) 
and (max-device-width: 768px)
and (-webkit-min-device-pixel-ratio: 2) {
    .successContainer{
        margin-top: 40vh;
        margin-left: 15vw;
    
    }
  
  

 

 

}