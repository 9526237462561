.scienceText{
    margin-bottom: 25px; 
    }

.scienceText:hover{
    
        border-color:blue;
        color:blue;
     
     
}

.completedBtn{
    border-radius: 30px;

}

.scienceText p{
    width: 150px;
    text-decoration: none;
    display: inline-block;
    margin: 0 10px;
    padding:20px 0;
    color: #fff;
    z-index: 1;
    background-color:rgb(51, 50, 50);
    border-radius: 30px;
    font-size: 24px;
    font-weight: 400;
}