.sucess-picture-page, .table{
	background: white;
	margin-top: 2em;
}
.picos-button{
    border-radius: 20px;
	border: 1px solid white;
	background-color:black;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
    transition: transform 80ms ease-in;
}
.no-result-found{
	text-align: center;
    padding: 2em !important;
    font-size: 20px;
    font-weight: bold;
}
