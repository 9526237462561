.react-responsive-modal-modal {
  margin-top: 145px;
  border-radius: 12px;
}
.react-responsive-modal-closeButton {
  top: 2px;
}
.textAreamodal {
  margin-top: 15px;
  height: 10vh;
  width: 50vw;
}
.log__monthlymodalButton {
  /* margin-left: 30%; */
  margin-left: 0;
  border-radius: 12px;
  margin-top: 2vh;
  width: 100%;
}

.react-quill {
  color: black;
}
/* Portrait and Landscape */
@media only screen and (min-device-width: 240px) and (max-device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
  .react-responsive-modal-modal {
    margin-top: 145px;
    border-radius: 12px;
    width: 80%;
  }

  .textAreamodal {
    height: 10vh;
    width: 70vw;
    margin-top: 15px;
  }
  .monthlymodalButton {
    margin-left: 20%;
    border-radius: 12px;
    margin-top: 10px;
  }
}
@media only screen and (min-device-width: 321px) and (max-device-width: 360px) and (-webkit-min-device-pixel-ratio: 2) {
  .react-responsive-modal-modal {
    margin-top: 145px;
    border-radius: 12px;
    width: 80%;
  }
  .textAreamodal {
    height: 10vh;
    width: 70vw;
    margin-top: 15px;
  }
  .monthlymodalButton {
    margin-left: 20%;
    border-radius: 12px;
    margin-top: 10px;
  }
}
@media only screen and (min-device-width: 361px) and (max-device-width: 375px) and (-webkit-min-device-pixel-ratio: 2) {
  .react-responsive-modal-modal {
    margin-top: 150px;
    border-radius: 12px;
    width: 80%;
  }
  .textAreamodal {
    height: 10vh;
    width: 70vw;
    margin-top: 15px;
  }
  .monthlymodalButton {
    margin-left: 20%;
    border-radius: 12px;
    margin-top: 10px;
  }
}
@media only screen and (min-device-width: 376px) and (max-device-width: 414px) and (-webkit-min-device-pixel-ratio: 2) {
  .react-responsive-modal-modal {
    margin-top: 145px;
    border-radius: 12px;
    width: 80%;
  }
  .textAreamodal {
    height: 10vh;
    width: 70vw;
    margin-top: 15px;
  }
  .monthlymodalButton {
    margin-left: 20%;
    border-radius: 12px;
    margin-top: 10px;
  }
}
@media only screen and (min-device-width: 415px) and (max-device-width: 768px) and (-webkit-min-device-pixel-ratio: 2) {
  .react-responsive-modal-modal {
    margin-top: 145px;
    border-radius: 12px;
    width: 80%;
  }
  .textAreamodal {
    height: 10vh;
    width: 70vw;
    margin-top: 15px;
  }
  .monthlymodalButton {
    margin-left: 20%;
    border-radius: 12px;
    margin-top: 10px;
  }
}
