
/* Work Mupp Picos Css */
.work-mupp-picos .table thead tr th, .mupp-picos-project .table thead tr th, .inner-table tbody tr td:nth-child(2), 
.inner-table tbody tr td:nth-child(3), .project-review-inner-table tbody tr td:nth-child(2), 
.project-review-inner-table tbody tr td:nth-child(3), .project-review .table thead tr th, .rank-priority-table tbody tr td{
   text-align: center;
} 
.work-mupp-picos .table tbody tr td:first-child, .work-mupp-picos .table tbody tr td:nth-child(2){
    width: 45% !important;
} 
.work-mupp-picos .table tbody tr td:nth-child(3){
    width: 10% !important;
} 

/* Mupp Picos Projects Css */
.mupp-picos-project .table th, .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: none !important;
}
/* .mupp-picos-project .outer-table tbody tr td:first-child{
    width: 10%;
}  */
/* .mupp-picos-project .outer-table tbody tr td:nth-child(2){
    width: 40%;*/
    /* line-height: 2.5; */
/*} */
.padd-0{
    padding: 0 !important;
}
/* .mupp-picos-project .outer-table tbody tr td:nth-child(3){
    width: 50% !important;
} */
.mupp-picos-project .outer-table tbody tr td:nth-child(3) .table-bordered{
    margin: 0;
}
.mupp-picos-project .add-project-btn-div{
    text-align: right;
}
.mupp-picos-project .add-project-btn-div .modal.show .modal-dialog {
    transform: none;
    color: black;
}
.add-project-btn-div .modal-dialog{
    text-align: left;
}
.modal-div{
    display: flex !important;
    align-items: baseline !important;
}
.is-project-checkbox{
    cursor: pointer;
    width:25px;
    height:25px;
}
.inner-table thead tr th:first-child{
    width: 90%;
}
.inner-table thead tr th:nth-child(2), .inner-table thead tr th:nth-child(3){
    width: 10%;
}
/* Review Project */
.project-review .table th, .table td {
    border-top: none !important;
}
.project-review .table tbody tr td:nth-child(3) .table-bordered{
    margin: 0;
}
.project-review-inner-table tbody tr td:first-child{
    width: 90%;
}

.project-td{
    display: flex;
}
@media (min-width: 576px){
    .title-table{
        width: 480px !important;
    }
}

button:focus {
    outline: none !important;
}
button:disabled{
    cursor: not-allowed;
}

.mupptitle{
    width: 20% !important;

}


/* .mupp .picos {
    padding: 0 !important;
    cursor: pointer;
    height: 10vh;
    overflow-y:scroll;
}

tbody {
    height: 100px;       
    overflow-y: auto;    
    overflow-x: hidden;  
} */

