.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }
  
  .modal-main {
    position:fixed;
    background: white;
    width: 80%;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
  }
  
  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }
  .nowButton{
    height: 30%;
     width: 40%
  }
  .laterButton{
    margin-left: 20%;
     height: 30%;
      width: 40%
  }

  /* Portrait and Landscape */
 @media only screen 
 and (min-device-width: 240px) 
 and (max-device-width: 320px)
 and (-webkit-min-device-pixel-ratio: 2) {
 
  
 
  .nowButton{
    height: 40%;
    width: 60vw;
  }
  .laterButton{
    margin-left: 10%;
    height: 40%;
    width: 60vw;
  }
    
  
 

}
 @media only screen 
   and (min-device-width: 321px) 
   and (max-device-width: 360px)
   and (-webkit-min-device-pixel-ratio: 2) {
 
    .nowButton{
      height: 40%;
      width: 60vw;
    }
    .laterButton{
      margin-left: 1%;
      height: 40%;
      width: 35vw;
    }
      
      
    
 }
 @media only screen 
 and (min-device-width: 361px) 
 and (max-device-width: 375px)
 and (-webkit-min-device-pixel-ratio: 2) {
  
  .nowButton{
    height: 40%;
    width: 60vw;
  }
  .laterButton{
    margin-left: 10%;
    height: 40%;
    width: 60vw;
  }
    
  
  
}
 @media only screen 
 and (min-device-width: 376px) 
 and (max-device-width: 414px)
 and (-webkit-min-device-pixel-ratio: 2) {
  
  .nowButton{
    height: 40%;
    width: 60vw;
  }
  .laterButton{
    margin-left: 10%;
    height: 40%;
    width: 60vw;
  }
    
  
  
}
@media only screen 
and (min-device-width: 415px) 
and (max-device-width: 768px)
and (-webkit-min-device-pixel-ratio: 2) {


  .nowButton{
    height: 40%;
    width: 60vw;
  }
  .laterButton{
    margin-left: 10%;
    height: 40%;
    width: 60vw;
  }
    
  

}