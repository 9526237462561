@media (min-width: 576px){
    .success-picture-picos-page .col-sm-4, .success-picture-picos-page .col-sm-8{
        padding: 0 !important;
    }
    .success-picture-picos-page{
        display: flex;
    }
    .path-div, .desc-div{
        text-align: center;
    }
    .continue{
        display: flex;
        justify-content: flex-end;
    }
}
@media (max-width: 576px){
    .desc-div{
        padding: 1em;
        min-height: 180px;
        border-bottom: 1px solid white;
    }
}
.success-picture-picos-page{
    margin-top: 40px;
}

.image1-div{
    padding: 2em;
    min-height: 180px;
    border-bottom: 1px solid white;
}

.image2-div{
    padding: 3em;
    min-height: 290px;
}

.left-block{
    border-right: 1px solid white;
}
.path-div, .rough-div, .heading-div{
    border-bottom: 1px solid white;
}
.path-div{
    padding: 2em;
    min-height: 180px;
}
.desc-div{
    padding: 3em;
}
.rough-div, .heading-div, .description-div{
    min-height: 180px;
    /* padding: 2em; */
}

.continue-button{
    border-radius: 20px;
	border: 1px solid white;
	background-color:black;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
    transition: transform 80ms ease-in;
}

.image1, .image2{
    display: inline-grid;
}

.thumbnail{
    max-width: 75%;
    max-height: 250px;
    cursor: pointer;
    border: 1px solid;
    cursor: pointer;
    margin-left:-20px;
}
.image-input{
    cursor: pointer;
    margin-left:-18px;
}

.hidden{
    visibility: hidden;
}
.path-div-label{
    margin-top: 1em;
    font-size: 20px;
}
.cross-icon{
    font-size: 20px;
    color:black;
    margin-top:-20px ;
    margin-left: 90px;
    cursor: pointer;
}