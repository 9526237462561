h3 {
  text-align: center;
}

/* body {
    background-color: rgb(25, 25, 35);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-family: 'Montserrat', sans-serif;
	height: 100vh;
	margin: -20px 0 50px;
} */

.journal_parent {
  /* height: 400px; */
  /* width: 400px; */
  border-radius: 4px;
  position: relative;
  background-color: transparent;
  transition: 0.3s all;
  display: flex;
  justify-content: center;
  align-items: center;
}

.journal_child {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px 50px;
  height: 100%;
  width: 400px;
  text-align: center;
}

.journal_parent input {
  position: relative;
  padding: 0px 16px;
  border: none;
  border-radius: 4px;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  background-color: transparent;
  color: #e6dcdc;
  outline: none;
  box-shadow: 0px 4px 20px 0px transparent;
  transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out,
    0.1s padding ease-in-out;
  -webkit-appearance: none;
  background: white;
}

.journal_parent input {
  background-color: rgb(48, 50, 64);
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}

.journal_button {
  border-radius: 20px;
  border: 1px solid white;
  background-color: black;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}

#h6 {
  color: white;
}

@media screen and (min-width: 250px) and (max-width: 750px) {
  #btn {
    float: right;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-right: 45px;
  }
  .test-container {
    padding: 0 0;
  }
  .testChild {
    height: 50vh;
  }
}

.menuVertical {
  margin-left: 2rem;
}

.menuVertical_dropdown {
  font-size: 1.3rem;
  background-color: #e6dcdc;
  padding: 0.7rem;
  border-radius: 5px;
  color: black;
  position: fixed;
  z-index: 20;
  cursor: pointer;
  /* width: fit-content; */
}

/* Portrait and Landscape */
/* @media only screen and (min-device-width: 240px) and (max-device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
  .journal_child {
    margin-left: -80%;
  }
}
@media only screen and (min-device-width: 321px) and (max-device-width: 360px) and (-webkit-min-device-pixel-ratio: 2) {
  .journal_child {
    margin-left: -75%;
  }
}
@media only screen and (min-device-width: 361px) and (max-device-width: 375px) and (-webkit-min-device-pixel-ratio: 2) {
  .journal_child {
    margin-left: -75%;
  }
}
@media only screen and (min-device-width: 376px) and (max-device-width: 414px) and (-webkit-min-device-pixel-ratio: 2) {
  .journal_child {
    margin-left: -70%;
  }
}
@media only screen and (min-device-width: 415px) and (max-device-width: 768px) and (-webkit-min-device-pixel-ratio: 2) {
  .journal_child {
    margin-left: -30%;
  }
} */
