
h3{
    text-align: center;
}

/* body {
    background-color: rgb(25, 25, 35);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-family: 'Montserrat', sans-serif;
	height: 100vh;
	margin: -20px 0 50px;
} */

.parent{
    height: 400px;
    width: 400px;
    border-radius: 4px;
    position: relative;
    background-color: transparent;
    transition: 0.3s all;
    display: flex;
    justify-content:center;
    align-items: center;
    margin-top: 5%;
    left:35%
}

.child{
    background-color:transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 50px 50px;
    height: 100%;
    width: 400px;
    text-align: center;
}


  
 .child input{
    border: none;
    padding: 12px 15px;
    margin: 8px 0;
    width: 100%;
    color: black !important;
    
}


button {
	border-radius: 20px;
	border: 1px solid white;
	background-color:black;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
    transition: transform 80ms ease-in;
    margin-top: 10px;
}
