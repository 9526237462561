.Page4-container{
    min-height: 100vh;
    display: flex;
    justify-content: center;
    overflow-y: auto;
}
a:hover {
    text-decoration: none !important;
}
.Page4-childContainer{
    /* height: 100%; */
    width: 50%;
    position: relative;
}
.Page4-childContainer.childContainer{
    height: 100;
}
/* #childContainer1{
    height: 80%;
    width: 90%;
    position:absolute;
    left: 5%;
    top: 35%;
} */
#childContainer1 a:hover {
    color: white !important;
}
.page4-title{
    color: white;
    font-size: 18px;
}
.review-project {
    font-size: 18px;
}
.imgContainer{
    position: absolute;
     left: 15%;
     top: 5% 
}
#PageImage{
    height:150px;
     width:350px
}


 
  /* Portrait and Landscape */
  @media only screen 
  and (min-device-width: 240px) 
  and (max-device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .Page4-childContainer{
        width: 100%;

        
    }
   
    #PageImage{
        height: 150px;
        width: 290px;
    }
    .imgContainer{
        left: 0;
        top:0

    }

}
  @media only screen 
    and (min-device-width: 321px) 
    and (max-device-width: 360px)
    and (-webkit-min-device-pixel-ratio: 2) {
        .Page4-childContainer{
            width: 100%;
        }
       
        #PageImage{
            height: 150px;
            width: 290px;
        }
        .imgContainer{
            left:5%;
        }
     
  }
  @media only screen 
  and (min-device-width: 361px) 
  and (max-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .Page4-childContainer{
        width: 100%;
    }
 
    #PageImage{
        height: 250px;
        width: 300px;
    }
    .imgContainer{
        left:10%;
    }
   
}
  @media only screen 
  and (min-device-width: 376px) 
  and (max-device-width: 414px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .Page4-childContainer{
        width: 100%;
    }
   
    #PageImage{
        height: 200px;
        width: 350px;
    }
    .imgContainer{
        left:5%;
    }
}
@media only screen 
and (min-device-width: 415px) 
and (max-device-width: 768px)
and (-webkit-min-device-pixel-ratio: 2) {
    .Page4-childContainer{
        width: 100%;
    }
  
     #PageImage{
        margin-left: 40%;
        height: 150px;
        width: 290px;
    } 

}