.mupps-accordion {
    height: 100%;
    z-index: 1;
    top: 0;
    left: -25px;
    /* background-color: #2F4050; */
    overflow-x: hidden;
    color: white;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.task-accordion{
    position: fixed;
    height:52rem;
    padding: 1em;
    z-index: 1;
    top: 0;
    background-color: #f0f0f0;
    width:20%;
    overflow-x: hidden;
    padding-top: 20px;
    color: black;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.task-accordion h4{
    margin-top: 5px;
}
.aPath{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 90%;
    margin-left: 10px;

}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #2F4050;
    color: white;
}
.accordion{
    margin-top: 5px;
}
.accordion > .card > .card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0 !important;
    background-color: rgb(191 44 44 / 3%);
    border-bottom: 1px solid white;
}
.card-header a:hover {
    color: white !important;
    text-decoration: none !important;
}
.card-title {
    font-size: 18px;
    color: white;
}
.inside-mupps{
    padding: 10px;
    font-size: 16px;
}
.inside-mupps label{
    margin-right: 6px !important;
}
.accordion .card-body {
    padding: 5px 0px !important;
}
.last-div{
    /* background-color: #2F4050; */
     color: white;
     height:100%;
}
.plus-icon, .vertical-ellipses{
    float: right;
    cursor: pointer;
}
.card-body ul{
    margin: 0 !important;
    background: rgb(25,25,36)
}
.elipse-text-for-title-mupp{
    width: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.card-body ul li{
    margin: 0;
    padding: 5px 0;
}
.nested-collabpse-div{
    text-align: right;
}
.edit-icon{
    margin-right: 15px;
    cursor: pointer;
}
.trash-icon{
    margin-right: 10px;
    cursor:pointer;
}
.edit-input-field{
    cursor: pointer;
    margin: 0 !important;
}
.check-icon{
    position: absolute;
    right: 25px;
    top: 70px;
}
.home-mupps-form-div{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top:50px;
}
.home-mupps-form-div .form-group{
    text-align: center;
    background: #2F4050;
    padding: 2em;
    color: white;
}
.home-mupps-form-div .form-group label{
    font-size: 22px;
}
.home-mupps-form-div .form-group button{
    border-radius: 35px;
    margin-top: 10px;
    padding: 6px 26px !important;
}
.back-button{
    /* text-align: right; */
    margin-right: 10px;
}
.fa {
    cursor: pointer;
}
.yes-button{
    margin-right: 5px;
}
/* .project-cursor{
    cursor: pointer;
} */
.render-task{
    padding: 0 !important;
    cursor: pointer;
    /* height: 90vh; */
    overflow-y:auto;
}
.project-renderer{
    max-height: 90vh;
    overflow-y:auto;
}

/* .render-task li{
    background: white;
    color: black;
    padding: 2px 10px;
    margin: 1em;
    list-style-type: none;
    border-radius: 5px; 
} */

.border-left-only{
    border-width: 1px 2em 5px 0; /* top right bottom left */
border-style: solid ;
border-color: #191922 #191922 #191922 #191922;
}
.render-task div label {
    font-size: 14px;
    margin-right: 20px;
}
.render-task h4{
    font-size: 20px !important;
}
.render-task li h4{
    text-align: left;
}
.form-div{
    display: flex;
    justify-content: center;
    margin-top:50px;
}
.inline-text{
    display: inline-block;
}
.inline-text h4{
    font-size: 18px !important;
}
.add-mupps-button{
    text-align: right;
}
.add-mupps-button button{
    border-radius: 35px;
    margin-top: 10px;
    padding: 6px 26px !important;
}
.task-type{
    margin-right: 1em;
}
.close-icon{
    font-size: 25px;
    position: fixed;
    right: 15px;
    z-index: 9999;
    top: 15px;
    color: black;
}
.calendar-icon{
    margin-right: 5px;
}

.active-button{
    color: green;
}
.project-header {
    padding-left: 0.75rem;
    margin-bottom: 0 !important;
    background:rgb(48, 47, 47);
    border-bottom: 1px solid white;
    cursor: pointer;
}
.project-header a:hover {
    color: white ;
    text-decoration: none !important;
}
 
.active-background{
    padding-left: 0.75rem;
    margin-bottom: 0 !important;
    background-color:rgb(13, 13, 32);
    border-bottom: 1px solid white;
    cursor: pointer;
} 
.project-imp{
    padding-left: 0.75rem;
    margin-bottom: 0 !important;
    background:#1b2c40;
    border-bottom: 1px solid white;
    cursor: pointer;
}

.elipse-text-mupps{
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .borderStrip{
     border-style: solid;
      border-left-color: red;
         border-width: 8px 
  }

 /* Portrait and Landscape */
 @media only screen 
 and (min-device-width: 240px) 
 and (max-device-width: 320px)
 and (-webkit-min-device-pixel-ratio: 2) {
    .task-accordion{
        position: absolute;
        right: -30px;
        height:52rem;
        padding: 1em;
        z-index: 1;
        top: 0;
        background-color: #f0f0f0;
        width: 70%;
        overflow-x: hidden;
        padding-top: 20px;
        color: black;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    }
   
    
  
 

}
 @media only screen 
   and (min-device-width: 321px) 
   and (max-device-width: 360px)
   and (-webkit-min-device-pixel-ratio: 2) {
    .task-accordion{
        position: absolute;
        right: -30px;
        height:52rem;
        padding: 1em;
        z-index: 1;
        top: 0;
        background-color: #f0f0f0;
        width: 70%;
        overflow-x: hidden;
        padding-top: 20px;
        color: black;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    }
   
     
      
    
 }
 @media only screen 
 and (min-device-width: 361px) 
 and (max-device-width: 375px)
 and (-webkit-min-device-pixel-ratio: 2) {
 
    .task-accordion{
        position: absolute;
        right: -30px;
        height:58rem;
        padding: 1em;
        z-index: 1;
        top: 0;
        background-color: #f0f0f0;
        width: 70%;
        overflow-x: hidden;
        padding-top: 20px;
        color: black;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    }
   
  
}
 @media only screen 
 and (min-device-width: 376px) 
 and (max-device-width: 414px)
 and (-webkit-min-device-pixel-ratio: 2) {
    .task-accordion{
        position: absolute;
        right: -30px;
        height:78rem;
        padding: 1em;
        z-index: 1;
        top: 0;
        background-color: #f0f0f0;
        width: 70%;
        overflow-x: hidden;
        padding-top: 20px;
        color: black;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    }
   
  
  
}
@media only screen 
and (min-device-width: 415px) 
and (max-device-width: 768px)
and (-webkit-min-device-pixel-ratio: 2) {
    .task-accordion{
        position: absolute;
        right: -30px;
        height:68rem;
        padding: 1em;
        z-index: 1;
        top: 0;
        background-color: #f0f0f0;
        width: 70%;
        overflow-x: hidden;
        padding-top: 20px;
        color: black;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    }
   
  
  

}

th, td {
        border: none;
}


/* 
overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 90%;
} */