.text{
    font-size: 25px;
    color: 'white';
    cursor: pointer;
    font-weight: 400;
    margin-top: 15px;
    margin-bottom: 50px;
    color:white;
    
}


.ItemContainer{
   
    margin-bottom: 25px;
    cursor: pointer;
}

.ItemContainer:hover{
   color:blue;

}

.ItemContainer p{
    width: 50vh;
    text-decoration: none;
    display: inline-block;
    margin: 0 10px;
    padding:20px 0;
    color: #fff;
    z-index: 1;
    background-color:rgb(51, 50, 50);
    border-radius: 30px;
    font-size: 20px;
    font-weight: 400;
    line-height: normal;


}
