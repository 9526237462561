.homeContainer{
    height: 100vh;
    width: 100vw;
    background: green;
    display: flex
}

.sideBar{
    height: 100%;
    width: 20%;
    background:black;
}
.content{
    height: 100%;
    width: 80%;
}
.menu{
    height: 5%;
    width: 100%;
    background: grey;
    
}
.content1{
    height: 100%;
    width: 100%;
    background: blueviolet;
}
.content2{
    height: 100%;
    width: 100%;
    background:rgb(92, 6, 6);
}