body{
  background : rgb(48, 50, 64); 
  height: 100vh;
  position: relative;
}
.App{
  margin: 50px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  width: 100%;
}

*{
  margin: 0;
}

.rightContainer{
 padding-left: 250px;
  padding-right: 0;
}
.app-route-module{
  overflow:hidden;
}
@media (min-width: 576px){
  .app-route-module{
    padding: 0 !important;
  }
  .container-fluid {
    padding: 0 !important;
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
    margin-right: 0px;
    margin-left: 0px;
  }
}
@media (max-width: 575px){
  .padding-0, .card-style, .right-cards{
      padding: 0 !important;
  }
  .container-fluid {
    padding: 0 !important;
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
    margin-right: 0px;
    margin-left: 0px;
  }
}
.self-discovery-div{
  text-align: center;
  top: 50%;
  position: inherit;
  margin-top:100px;
}
.self-discovery-div p{
  font-size: 35px;
  margin-bottom: 1em;
  cursor: pointer;
}


.App {
  text-align: center;
  }
  .App-header {
  padding:10px;
  display: flex;
  background-color: white;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  }
  .Controls {
  border: 1px solid ;
  width: 50vw;
  text-align: left;
  padding: 10px;
  }
  .textEditor{
      padding-top: 10px;
      color:black;
      font-size: 15px;
      padding:10px;
  }

  .Text {
  font-size: calc(5px + 2vmin);
  width: 50vw;
  padding: 10px;
  }
  .highlightBtn{
      padding: 5px 10px;
  border-radius: 15px;
  font-size: 20px;
  }
  .buttonContainer{
      margin-left: 45%;
      margin-top: 20px;
      margin-bottom: 20px;
      
  }
