@import url(https://fonts.googleapis.com/css?family=Montserrat:400,800);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,800);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;400;500;700&family=Quicksand:wght@300;400;500;700&display=swap);
body,
html {
  height: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  background-color: rgb(25, 25, 35) !important;
  color: white !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (min-width: 576px) {
  .container-fluid {
    padding: 0 !important;
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
    margin-right: 0px;
    margin-left: 0px;
  }
  .cards-container {
    display: flex;
    padding: 0 !important;
  }
}

body{
  background : rgb(48, 50, 64); 
  height: 100vh;
  position: relative;
}
.App{
  margin: 50px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  width: 100%;
}

*{
  margin: 0;
}

.rightContainer{
 padding-left: 250px;
  padding-right: 0;
}
.app-route-module{
  overflow:hidden;
}
@media (min-width: 576px){
  .app-route-module{
    padding: 0 !important;
  }
  .container-fluid {
    padding: 0 !important;
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
    margin-right: 0px;
    margin-left: 0px;
  }
}
@media (max-width: 575px){
  .padding-0, .card-style, .right-cards{
      padding: 0 !important;
  }
  .container-fluid {
    padding: 0 !important;
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
    margin-right: 0px;
    margin-left: 0px;
  }
}
.self-discovery-div{
  text-align: center;
  top: 50%;
  position: inherit;
  margin-top:100px;
}
.self-discovery-div p{
  font-size: 35px;
  margin-bottom: 1em;
  cursor: pointer;
}


.App {
  text-align: center;
  }
  .App-header {
  padding:10px;
  display: flex;
  background-color: white;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  }
  .Controls {
  border: 1px solid ;
  width: 50vw;
  text-align: left;
  padding: 10px;
  }
  .textEditor{
      padding-top: 10px;
      color:black;
      font-size: 15px;
      padding:10px;
  }

  .Text {
  font-size: calc(5px + 2vmin);
  width: 50vw;
  padding: 10px;
  }
  .highlightBtn{
      padding: 5px 10px;
  border-radius: 15px;
  font-size: 20px;
  }
  .buttonContainer{
      margin-left: 45%;
      margin-top: 20px;
      margin-bottom: 20px;
      
  }


h3{
    text-align: center;
}

/* body {
    background-color: rgb(25, 25, 35);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-family: 'Montserrat', sans-serif;
	height: 100vh;
	margin: -20px 0 50px;
} */

.parent{
    height: 400px;
    width: 400px;
    border-radius: 4px;
    position: relative;
    background-color: transparent;
    transition: 0.3s all;
    display: flex;
    justify-content:center;
    align-items: center;
    margin-top: 5%;
    left:35%
}

.child{
    background-color:transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 50px 50px;
    height: 100%;
    width: 400px;
    text-align: center;
}


  
 .child input{
    border: none;
    padding: 12px 15px;
    margin: 8px 0;
    width: 100%;
    color: black !important;
    
}


button {
	border-radius: 20px;
	border: 1px solid white;
	background-color:black;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
    transition: -webkit-transform 80ms ease-in;
    transition: transform 80ms ease-in;
    transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
    margin-top: 10px;
}


* {
	box-sizing: border-box;
}
/* 
body  {
    background-color: rgb(25, 25, 35);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-family: 'Montserrat', sans-serif;
	height: 100vh;
	margin: -20px 0 50px;
} */
.imageSignup{
	justify-content: center;
	width:400px
}
h1 {
	font-weight: bold;
	margin: 0;
}

h2 {
	text-align: center;
}

p {
	font-size: 14px;
	font-weight: 100;
	line-height: 20px;
	letter-spacing: 0.5px;
	margin: 20px 0 30px;
}

span {
	font-size: 12px;
}

a {
	color:#007bff;
	font-size: 14px;
	text-decoration: none;
	margin: 15px 0;
}

button {
	border-radius: 20px;
	border: 1px solid white;
	background-color:black;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
    transition: -webkit-transform 80ms ease-in;
    transition: transform 80ms ease-in;
    transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
    margin-top: 10px;
}

button:active {
	-webkit-transform: scale(0.95);
	        transform: scale(0.95);
}

button:focus {
	outline: none;
}

button.ghost {
	background-color: transparent;
	border-color: #FFFFFF;
}

form {
	background-color:transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 50px 50px;
    height: 100%;
    width: 400px;
	text-align: center;
	
}
.btn-signup{
	margin-top: 20px;
	border-radius: 5px;
}

.container input {
	background-color:rgb(48, 50, 64);
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 100%;
}

.container {
	background-color:transparent;
	
	position: relative;
	overflow: hidden;
	/* width: 400px;
	max-width: 100%; 
	min-height: 480px;*/
}

.form-container {
	position: absolute;
	top: 0;
	height: 100%;
	transition: all 0.6s ease-in-out;
}

.sign-in-container {
	left: 0;
	width: 50%;
	z-index: 2;
	
}

.container.right-panel-active .sign-in-container {
	-webkit-transform: translateX(100%);
	        transform: translateX(100%);
}

.sign-up-container {
	left: 0;
	width: 50%;
	opacity: 0;
	z-index: 1;
}

.container.right-panel-active .sign-up-container {
	-webkit-transform: translateX(100%);
	        transform: translateX(100%);
	opacity: 1;
	z-index: 5;
	-webkit-animation: show 0.6s;
	        animation: show 0.6s;
}

@-webkit-keyframes show {
	0%, 49.99% {
		opacity: 0;
		z-index: 1;
	}
	
	50%, 100% {
		opacity: 1;
		z-index: 5;
	}
}

@keyframes show {
	0%, 49.99% {
		opacity: 0;
		z-index: 1;
	}
	
	50%, 100% {
		opacity: 1;
		z-index: 5;
	}
}

.overlay-container {
	position: absolute;
	top: 0;
	left: 50%;
	width: 50%;
	height: 100%;
	overflow: hidden;
	transition: -webkit-transform 0.6s ease-in-out;
	transition: transform 0.6s ease-in-out;
	transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
	z-index: 100;
}

.container.right-panel-active .overlay-container{
	-webkit-transform: translateX(-100%);
	        transform: translateX(-100%);
}

.overlay {
	background: #FF416C;
	background: linear-gradient(to right, #FF4B2B, #FF416C);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0;
	color: #FFFFFF;
	position: relative;
	left: -100%;
	height: 100%;
	width: 200%;
  	-webkit-transform: translateX(0);
  	        transform: translateX(0);
	transition: -webkit-transform 0.6s ease-in-out;
	transition: transform 0.6s ease-in-out;
	transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

.container.right-panel-active .overlay {
  	-webkit-transform: translateX(50%);
  	        transform: translateX(50%);
}

.overlay-panel {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 40px;
	text-align: center;
	top: 0;
	height: 100%;
	width: 50%;
	-webkit-transform: translateX(0);
	        transform: translateX(0);
	transition: -webkit-transform 0.6s ease-in-out;
	transition: transform 0.6s ease-in-out;
	transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

.overlay-left {
	-webkit-transform: translateX(-20%);
	        transform: translateX(-20%);
}

.container.right-panel-active .overlay-left {
	-webkit-transform: translateX(0);
	        transform: translateX(0);
}

.overlay-right {
	right: 0;
	-webkit-transform: translateX(0);
	        transform: translateX(0);
}

.container.right-panel-active .overlay-right {
	-webkit-transform: translateX(20%);
	        transform: translateX(20%);
}

.social-container {
	margin: 20px 0;
}

.loginform input {
	background-color:rgb(48, 50, 64);
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 380px;
	color: white;
}

.social-container a {
	border: 1px solid #DDDDDD;
	border-radius: 50%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin: 0 5px;
	height: 40px;
	width: 40px;
}

.signImgContainer {
	padding-bottom:10px ;
	margin-left: 5px;

}

.errorMsg{
	color: red;
}

.user{
	color: white;
}

.signImage{
	height:200px;
	 width:380px
}

@media screen and (min-width: 250px) and (max-width: 750px)  {
	.imageSignup{
		justify-content: center;
		width:205px
	}
	form {
		display: inline-grid;
		align-items: center;
		
		padding: unset;
		height: 100%;
		width: -webkit-fill-available;
		text-align: center;
		margin-left: 30px;
	}
}

 /* Portrait and Landscape */
 @media only screen 
 and (min-device-width: 240px) 
 and (max-device-width: 320px)
 and (-webkit-min-device-pixel-ratio: 2) {
	.signImage{
		height:150px;
		 width:240px
	}
	.signImgContainer {
		padding-bottom:10px ;
		margin-right: 20%;
	}

.loginform input {
	background-color:rgb(48, 50, 64);
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 260px;
	color: white;
}
  

}
 @media only screen 
   and (min-device-width: 321px) 
   and (max-device-width: 360px)
   and (-webkit-min-device-pixel-ratio: 2) {
	.signImage{
		height:150px;
		 width:270px
	}
	.signImgContainer {
		padding-bottom:10px ;
		margin-right: 5%;
	}

.loginform input {
	background-color:rgb(48, 50, 64);
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 300px;
	color: white;
}

	   
	
 }
 @media only screen 
 and (min-device-width: 361px) 
 and (max-device-width: 375px)
 and (-webkit-min-device-pixel-ratio: 2) {
	.signImage{
		height:150px;
		 width:270px
	}
	.signImgContainer {
		padding-bottom:10px ;
		margin-right: 20%;
	}

.loginform input {
	background-color:rgb(48, 50, 64);
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 300px;
	color: white;
}
  
  
}
 @media only screen 
 and (min-device-width: 376px) 
 and (max-device-width: 414px)
 and (-webkit-min-device-pixel-ratio: 2) {
	.signImage{
		height:150px;
		 width:270px
	}
	.signImgContainer {
		padding-bottom:10px ;
		margin-right: 2%;
	}

.loginform input {
	background-color:rgb(48, 50, 64);
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 300px;
	color: white;
}
  
}
@media only screen 
and (min-device-width: 415px) 
and (max-device-width: 768px)
and (-webkit-min-device-pixel-ratio: 2) {
	.signImage{
		height:150px;
		 width:270px
	}
	.signImgContainer {
		padding-bottom:10px ;
		margin-right: 20%;
	}

.loginform input {
	background-color:rgb(48, 50, 64);
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 300px;
	color: white;
}
  

}

* {
	box-sizing: border-box;
}

h1 {
	font-weight: bold;
	margin: 0;
}

h2 {
	text-align: center;
}

p {
	font-size: 14px;
	font-weight: 100;
	line-height: 20px;
	letter-spacing: 0.5px;
	margin: 20px 0 30px;
}

span {
	font-size: 12px;
}

a {
	color:#007bff;
	font-size: 18px;
	text-decoration: none;
	margin: 15px 0;
}

button {
	border-radius: 20px;
	border: 1px solid white;
	background-color:black;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
	transition: -webkit-transform 80ms ease-in;
	transition: transform 80ms ease-in;
	transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
}

button:active {
	-webkit-transform: scale(0.95);
	        transform: scale(0.95);
}

button:focus {
	outline: none;
}

button.ghost {
	background-color: transparent;
	border-color: #FFFFFF;
}
.btn-signin{
	border-radius: 5px;
}

form {
	background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 50px 50px;
    height: 100%;
    width: 400px;
	text-align: center;
	
}

.loginform input {
	background-color:rgb(48, 50, 64);
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 380px;
	color: white;
}

.loginform input::-webkit-input-placeholder{
	color: white;
}

.loginform input:-ms-input-placeholder{
	color: white;
}

.loginform input::placeholder{
	color: white;
}


.loginform {
	background-color:transparent;
	position: relative;
	overflow: hidden;
	width: 400px;
	max-width: 100%;
	min-height: 480px;
	left: 35%;
	margin-top:50px;
	
}

.form-container {
	position: absolute;
	top: 0;
	height: 100%;
	transition: all 0.6s ease-in-out;
}
#rememberContainer{
	margin-right: 90px;
}

.sign-in-container {
	left: 0;
	width: 50%;
	z-index: 2;
	
}

.container.right-panel-active .sign-in-container {
	-webkit-transform: translateX(100%);
	        transform: translateX(100%);
}

.sign-up-container {
	left: 0;
	width: 50%;
	opacity: 0;
	z-index: 1;
}

.container.right-panel-active .sign-up-container {
	-webkit-transform: translateX(100%);
	        transform: translateX(100%);
	opacity: 1;
	z-index: 5;
	-webkit-animation: show 0.6s;
	        animation: show 0.6s;
}

@-webkit-keyframes show {
	0%, 49.99% {
		opacity: 0;
		z-index: 1;
	}
	
	50%, 100% {
		opacity: 1;
		z-index: 5;
	}
}

@keyframes show {
	0%, 49.99% {
		opacity: 0;
		z-index: 1;
	}
	
	50%, 100% {
		opacity: 1;
		z-index: 5;
	}
}

.overlay-container {
	position: absolute;
	top: 0;
	left: 50%;
	width: 50%;
	height: 100%;
	overflow: hidden;
	transition: -webkit-transform 0.6s ease-in-out;
	transition: transform 0.6s ease-in-out;
	transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
	z-index: 100;
}

.container.right-panel-active .overlay-container{
	-webkit-transform: translateX(-100%);
	        transform: translateX(-100%);
}

.overlay {
	background: #FF416C;
	background: linear-gradient(to right, #FF4B2B, #FF416C);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0;
	color: #FFFFFF;
	position: relative;
	left: -100%;
	height: 100%;
	width: 200%;
  	-webkit-transform: translateX(0);
  	        transform: translateX(0);
	transition: -webkit-transform 0.6s ease-in-out;
	transition: transform 0.6s ease-in-out;
	transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

.container.right-panel-active .overlay {
  	-webkit-transform: translateX(50%);
  	        transform: translateX(50%);
}

.overlay-panel {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 40px;
	text-align: center;
	top: 0;
	height: 100%;
	width: 50%;
	-webkit-transform: translateX(0);
	        transform: translateX(0);
	transition: -webkit-transform 0.6s ease-in-out;
	transition: transform 0.6s ease-in-out;
	transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

.overlay-left {
	-webkit-transform: translateX(-20%);
	        transform: translateX(-20%);
}

.container.right-panel-active .overlay-left {
	-webkit-transform: translateX(0);
	        transform: translateX(0);
}

.overlay-right {
	right: 0;
	-webkit-transform: translateX(0);
	        transform: translateX(0);
}

.container.right-panel-active .overlay-right {
	-webkit-transform: translateX(20%);
	        transform: translateX(20%);
}

.social-container {
	margin: 20px 0;
}

.social-container a {
	border: 1px solid #DDDDDD;
	border-radius: 50%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin: 0 5px;
	height: 40px;
	width: 40px;
}

#Remember{
	color: white;
	margin-top: 5px;
	margin-left: 10px;
}
#rememberContainer{
	width: 100%;
	display: flex;
}
#ischecked{
	height: 20px;
	width: 20px;
	margin: 5px;
}
.imageSignup{
	justify-content: center;
	width:400px
}
@media screen and (min-width: 250px) and (max-width: 750px)  {
	.loginform {
		background-color: transparent;
		position: relative;
		overflow: hidden;
		width: 400px;
		max-width: 100%;
		min-height: 480px;
		left: 0%;
		margin-top: 30px;
	}
	form{
		display: inline-grid;
		align-items: center;
  
    flex-direction: unset;
    padding: unset;
    height: 100%;
    width: -webkit-fill-available;
    text-align: center;
	}
	.imageSignup{
		justify-content: center;
		width:285px
	}
}


 /* Portrait and Landscape */
 @media only screen 
 and (min-device-width: 240px) 
 and (max-device-width: 320px)
 and (-webkit-min-device-pixel-ratio: 2) {


.loginform input {
	background-color:rgb(48, 50, 64);
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 300px;
	color: white;
}
  

}
 @media only screen 
   and (min-device-width: 321px) 
   and (max-device-width: 360px)
   and (-webkit-min-device-pixel-ratio: 2) {


.loginform input {
	background-color:rgb(48, 50, 64);
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 300px;
	color: white;
}

	   
	
 }
 @media only screen 
 and (min-device-width: 361px) 
 and (max-device-width: 375px)
 and (-webkit-min-device-pixel-ratio: 2) {

.loginform input {
	background-color:rgb(48, 50, 64);
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 300px;
	color: white;
}
  
  
}
 @media only screen 
 and (min-device-width: 376px) 
 and (max-device-width: 414px)
 and (-webkit-min-device-pixel-ratio: 2) {


.loginform input {
	background-color:rgb(48, 50, 64);
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 300px;
	color: white;
}
  
}
@media only screen 
and (min-device-width: 415px) 
and (max-device-width: 768px)
and (-webkit-min-device-pixel-ratio: 2) {


.loginform input {
	background-color:rgb(48, 50, 64);
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 300px;
	color: white;
}
  

}

h3{
    text-align: center;
}
/* 
body {
    background-color: rgb(25, 25, 35);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-family: 'Montserrat', sans-serif;
	height: 100vh;
	margin: -20px 0 50px;
} */

.parent{
    height: 400px;
    width: 400px;
    border-radius: 4px;
    position: relative;
    background-color: transparent;
    transition: 0.3s all;
    display: flex;
    justify-content:center;
    align-items: center;
}

.child{
    background-color:transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 50px 50px;
    height: 100%;
    width: 400px;
    text-align: center;
}
.child input{
    color:white !important;
}
.child input::-webkit-input-placeholder{
	color: white;
}
.child input:-ms-input-placeholder{
	color: white;
}
.child input::placeholder{
	color: white;
}

.parent input {
    position: relative;
    padding: 0px 16px;
    border: none;
    border-radius: 4px;
    font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    background-color: transparent;
    color: #282828;
    outline: none;
    box-shadow: 0px 4px 20px 0px transparent;
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out, 0.1s padding ease-in-out;
    -webkit-appearance: none;
    background:white;
    
  }
  
.parent input{
    background-color:rgb(48, 50, 64);
    border: none;
    padding: 12px 15px;
    margin: 8px 0;
    width: 100%;
    
}


#btn1 {
	border-radius: 15px;
	border: 1px solid white;
	background-color:black;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
    transition: -webkit-transform 80ms ease-in;
    transition: transform 80ms ease-in;
    transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
    margin-top: 10px;
}

#h6{
    color:white
}
.successContainer{
    margin-top: 40vh;
    margin-left: 40vw;

}
#successBtn{
    margin-top: 20px;
    margin-left: 40px;

}
/* Portrait and Landscape */
@media only screen 
and (min-device-width: 240px) 
and (max-device-width: 320px)
and (-webkit-min-device-pixel-ratio: 2) {
    
    .successContainer{
        margin-top: 40vh;
        margin-left: 15vw;
    
    }



  

}
@media only screen 
  and (min-device-width: 321px) 
  and (max-device-width: 360px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .successContainer{
        margin-top: 40vh;
        margin-left: 15vw;
    
    }

   
  
   
      
   
}
@media only screen 
and (min-device-width: 361px) 
and (max-device-width: 375px)
and (-webkit-min-device-pixel-ratio: 2) {
    .successContainer{
        margin-top: 40vh;
        margin-left: 15vw;
    
    }
  
    

 
 
}
@media only screen 
and (min-device-width: 376px) 
and (max-device-width: 414px)
and (-webkit-min-device-pixel-ratio: 2) {
    .successContainer{
        margin-top: 40vh;
        margin-left: 15vw;
    
    }
 
   
 

 
}
@media only screen 
and (min-device-width: 415px) 
and (max-device-width: 768px)
and (-webkit-min-device-pixel-ratio: 2) {
    .successContainer{
        margin-top: 40vh;
        margin-left: 15vw;
    
    }
  
  

 

 

}
#child{
    height: 400px;
    width: 400px;
   padding-left: 30px;
}

#parent-container{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 300px;
    margin-left: 25vw;
}

#child inp {
    position: relative;
    padding: 0px 16px;
    border: none;
    border-radius: 4px;
    font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    background-color: transparent;
    color: #282828;
    outline: none;
    box-shadow: 0px 4px 20px 0px transparent;
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out, 0.1s padding ease-in-out;
    -webkit-appearance: none;
    background:white;
    
  }
  
#inp{
    background-color:rgb(48, 50, 64);
    border: none;
    padding: 12px 15px;
    margin: 8px 0;
    width: 90%;
    
}

#failedBtn{
    
    margin-left: 60px;
    margin-top: 20px;
}

/* Portrait and Landscape */
@media only screen 
and (min-device-width: 240px) 
and (max-device-width: 320px)
and (-webkit-min-device-pixel-ratio: 2) {
    #parent-container{
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 300px;
        margin-left: 0;
    }
    #failedBtn{
    
        margin-left: 20px;
        margin-top: 20px;
    }
    



  

}
@media only screen 
  and (min-device-width: 321px) 
  and (max-device-width: 360px)
  and (-webkit-min-device-pixel-ratio: 2) {

    #parent-container{
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 300px;
        margin-left: 0;
    }
    #failedBtn{
    
        margin-left: 20px;
        margin-top: 20px;
    }
  
   
      
   
}
@media only screen 
and (min-device-width: 361px) 
and (max-device-width: 375px)
and (-webkit-min-device-pixel-ratio: 2) {
  
    #parent-container{
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 300px;
        margin-left: 0;
    }
    #failedBtn{
    
        margin-left: 20px;
        margin-top: 20px;
    }

 
 
}
@media only screen 
and (min-device-width: 376px) 
and (max-device-width: 414px)
and (-webkit-min-device-pixel-ratio: 2) {
 
    #parent-container{
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 300px;
        margin-left: 0;
    }
    #failedBtn{
    
        margin-left: 20px;
        margin-top: 20px;
    }
 

 
}
@media only screen 
and (min-device-width: 415px) 
and (max-device-width: 768px)
and (-webkit-min-device-pixel-ratio: 2) {
    #parent-container{
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 300px;
        margin-left: 0;
    }
    #failedBtn{
    
        margin-left: 20px;
        margin-top: 20px;
    }
  

 

 

}

h3{
    text-align: center;
}

/* body {
    background-color: rgb(25, 25, 35);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-family: 'Montserrat', sans-serif;
	height: 100vh;
	margin: -20px 0 50px;
} */

.parent{
    height: 400px;
    width: 400px;
    border-radius: 4px;
    position: relative;
    background-color: transparent;
    transition: 0.3s all;
    display: flex;
    justify-content:center;
    align-items: center;
}

.child{
    background-color:transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 50px 50px;
    height: 100%;
    width: 400px;
    text-align: center;
}

.parent input {
    position: relative;
    padding: 0px 16px;
    border: none;
    border-radius: 4px;
    font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    background-color: transparent;
    color: #282828;
    outline: none;
    box-shadow: 0px 4px 20px 0px transparent;
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out, 0.1s padding ease-in-out;
    -webkit-appearance: none;
    background:white;
    
  }
  
.parent input{
    background-color:rgb(48, 50, 64);
    border: none;
    padding: 12px 15px;
    margin: 8px 0;
    width: 100%;
    
}


button {
	border-radius: 20px;
	border: 1px solid white;
	background-color:black;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
    transition: -webkit-transform 80ms ease-in;
    transition: transform 80ms ease-in;
    transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
}

#h6{
    color:white
}

@media screen and (min-width: 250px) and (max-width: 750px)  {
    #btn{
     float: right;
     margin-top: 1em;
     margin-bottom: 1em;
     margin-right: 45px;
    }
    .test-container{
     padding: 0 0;
 }
 .testChild{
     height: 50vh;
 }
 }
 
 
  /* Portrait and Landscape */
  @media only screen 
  and (min-device-width: 240px) 
  and (max-device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .child{
        margin-left: -80%;
    }
 }
  @media only screen 
    and (min-device-width: 321px) 
    and (max-device-width: 360px)
    and (-webkit-min-device-pixel-ratio: 2) {
  
        .child{
            margin-left: -75%;
        }
     
        
     
  }
  @media only screen 
  and (min-device-width: 361px) 
  and (max-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 2) {
    
    .child{
        margin-left: -75%;
    }
   
   
 }
  @media only screen 
  and (min-device-width: 376px) 
  and (max-device-width: 414px)
  and (-webkit-min-device-pixel-ratio: 2) {
   
    .child{
        margin-left: -70%;
    }
 
 
   
 }
 @media only screen 
 and (min-device-width: 415px) 
 and (max-device-width: 768px)
 and (-webkit-min-device-pixel-ratio: 2) {
    
 
    .child{
        margin-left: -30%;
    }
 
   
 
 }
.this-week-li{
  padding: 8px !important;
  margin-top: 20px;
}
.this-week-li label{
    margin-right: 2em;
}
.this-week-li button{
  border-radius: 12px;
  margin-right: 30px;
}
.category-list {
  width: calc(100% - 1px);
  height: 61px;
  background: #2F4050;
  margin-left: 1px;
  display: table;
}
.list-table {
  display: table-cell;
    color: white;
    vertical-align: middle;
}
.list-table:active{
 background: white;
}
.list-value {
  width: 100px;
  margin-left: 30px;
  font-size: 18px;
  text-align: center;
}
.list-value:hover{
  cursor: pointer;
  background-color: #293850;
}
.select-button{
  position: absolute;
  top: 25%;
  left: 110%;
}

.task-date{
  position: absolute;
  top: 50px;
  left: 20%;
}
.project-name{
  position: absolute;
  top: 50px;
}
.mupp-path{
  margin-left: 115px;
  white-space: nowrap; 
  width: 100px; 
  overflow: hidden;
  text-overflow: ellipsis;
}
.mupp-path:hover{
  overflow: visible;

}
.taskDetailbtn{
  position: absolute;
  top:20px;
  left:80%;
  
}

.elipse-text-for-title{
  width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}



.elipse-text{
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn-nav-tabs-thisweek{
  display: flex;
  padding: 0;
  border-style: groove;
  /* border-bottom: 1px solid; */
  margin-bottom: 50px;
}

.btn-nav-tabs-thisweek li {
  padding: 10px;
  text-align: center;
  cursor: pointer;
  border-right: 1px solid;
  border-top: hidden;
  padding-right: 10%;
}

.btn-nav-tabs-thisweek li, .all-categories-tab-thisweek li{
  list-style-type: none;
}

@media (min-width: 576px){
  .btn-nav-tabs-thisweek li {
    width: 10%;
    display: inline-block;
  }
  .this-week-li {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .dis-flex{
    display: flex;
  }
  .inline-css {
    display: flex;
    height: 100%;
  }
  .add-task-header{
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
  .inbox-tabs .inbox-tab-li{
    display: flex;
    align-items: baseline;
  }
  .test-align-right{
    text-align: right;
  }

}
@media (max-width: 575px){
  .all-categories-li,  .add-task-header, .inbox-tab-li{
    text-align: center;   
  }
}

/* Portrait and Landscape */
@media only screen 
and (min-device-width: 240px) 
and (max-device-width: 320px)
and (-webkit-min-device-pixel-ratio: 2) {
  .all-categories-tab{
    margin-top: -21px;
    float: right;
    width: 337px;
  }
  .all-categories-li {
    background: white;
    color: black;
    margin-bottom: 5px;
    border-radius: 12px;
    padding: 5px 0 0 10px;
    margin-right: 10px;
    margin-left: 30px;
    width: 330px;
}
.btn-nav-tabs-thisweek{
  padding: 0; 
  margin-bottom: 50px;
  display: block;
}
.btn-nav-tabs-thisweek li {
  padding: 10px;
  text-align: center;
  cursor: pointer; 
}

}
@media only screen 
  and (min-device-width: 321px) 
  and (max-device-width: 360px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .all-categories-tab{
      margin-top: 20px;
      float: right;
      width: 377px;
    }
    .all-categories-li {
      background: white;
      color: black;
      margin-bottom: 5px;
      border-radius: 12px;
      padding: 5px 0 0 10px;
      margin-right: 10px;
      margin-left: 30px;
      width: 330px;
  }
  .btn-nav-tabs-thisweek{
    padding: 0; 
    margin-bottom: 50px;
    display: block;
  }
  .btn-nav-tabs-thisweek li {
    padding: 10px;
    text-align: center;
    cursor: pointer; 
  }
}
@media only screen 
and (min-device-width: 361px) 
and (max-device-width: 375px)
and (-webkit-min-device-pixel-ratio: 2) {
  .all-categories-tab{
    margin-top: 20px;
    float: right;
    width: 391px;
  }
  .all-categories-li {
    background: white;
    color: black;
    margin-bottom: 5px;
    border-radius: 12px;
    padding: 5px 0 0 10px;
    margin-right: 10px;
    margin-left: 30px;
    width: 330px;
}
.btn-nav-tabs-thisweek{
  padding: 0; 
  margin-bottom: 50px;
  display: block;
} .btn-nav-tabs li {
  padding: 10px;
  text-align: center;
  cursor: pointer; 
}

}
@media only screen 
and (min-device-width: 376px) 
and (max-device-width: 414px)
and (-webkit-min-device-pixel-ratio: 2) {
  .all-categories-tab{
    margin-top: 20px;
    float: right;
    width: 426px;
  }
  .all-categories-li {
    background: white;
    color: black;
    margin-bottom: 5px;
    border-radius: 12px;
    padding: 5px 0 0 10px;
    margin-right: 10px;
    margin-left: 30px;
    width: 330px;
}
.btn-nav-tabs-thisweek{
  padding: 0; 
  margin-bottom: 50px;
  display: block;
}
.btn-nav-tabs-thisweek li {
  padding: 10px;
  text-align: center;
  cursor: pointer; 
}
}
@media only screen 
and (min-device-width: 415px) 
and (max-device-width: 768px)
and (-webkit-min-device-pixel-ratio: 2) {
.all-categories-tab{
  margin-top: 20px;
  float: right;
  width: 564px;
}
.btn-nav-tabs-thisweek{
  padding: 0; 
  margin-bottom: 50px;
display: block;
}
.btn-nav-tabs-thisweek li {
  padding: 10px;
  text-align: center;
  cursor: pointer; 
}
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }
  
  .modal-main {
    position:fixed;
    background: white;
    width: 80%;
    height: auto;
    top:50%;
    left:50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
  }
  
  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }
  .nowButton{
    height: 30%;
     width: 40%
  }
  .laterButton{
    margin-left: 20%;
     height: 30%;
      width: 40%
  }

  /* Portrait and Landscape */
 @media only screen 
 and (min-device-width: 240px) 
 and (max-device-width: 320px)
 and (-webkit-min-device-pixel-ratio: 2) {
 
  
 
  .nowButton{
    height: 40%;
    width: 60vw;
  }
  .laterButton{
    margin-left: 10%;
    height: 40%;
    width: 60vw;
  }
    
  
 

}
 @media only screen 
   and (min-device-width: 321px) 
   and (max-device-width: 360px)
   and (-webkit-min-device-pixel-ratio: 2) {
 
    .nowButton{
      height: 40%;
      width: 60vw;
    }
    .laterButton{
      margin-left: 1%;
      height: 40%;
      width: 35vw;
    }
      
      
    
 }
 @media only screen 
 and (min-device-width: 361px) 
 and (max-device-width: 375px)
 and (-webkit-min-device-pixel-ratio: 2) {
  
  .nowButton{
    height: 40%;
    width: 60vw;
  }
  .laterButton{
    margin-left: 10%;
    height: 40%;
    width: 60vw;
  }
    
  
  
}
 @media only screen 
 and (min-device-width: 376px) 
 and (max-device-width: 414px)
 and (-webkit-min-device-pixel-ratio: 2) {
  
  .nowButton{
    height: 40%;
    width: 60vw;
  }
  .laterButton{
    margin-left: 10%;
    height: 40%;
    width: 60vw;
  }
    
  
  
}
@media only screen 
and (min-device-width: 415px) 
and (max-device-width: 768px)
and (-webkit-min-device-pixel-ratio: 2) {


  .nowButton{
    height: 40%;
    width: 60vw;
  }
  .laterButton{
    margin-left: 10%;
    height: 40%;
    width: 60vw;
  }
    
  

}
.homeContainer{
    height: 100vh;
    width: 100vw;
    background: green;
    display: flex
}

.sideBar{
    height: 100%;
    width: 20%;
    background:black;
}
.content{
    height: 100%;
    width: 80%;
}
.menu{
    height: 5%;
    width: 100%;
    background: grey;
    
}
.content1{
    height: 100%;
    width: 100%;
    background: blueviolet;
}
.content2{
    height: 100%;
    width: 100%;
    background:rgb(92, 6, 6);
}
/* .sidenav {
  min-height: 100vh;
  max-height: 200vh;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #f0f0f0;
  overflow-x: hidden;
  color: white;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.sidenav div{
  margin-top: 72px;
}

.sidenav div a {
  padding: 6px 0px 6px 0px;
  text-decoration: none;
  font-size: 20px;
  color: #293846;
  display: block;
  text-align: center;
}

.active {
  background-color: #293846;
  color: white !important;
  border-radius: 20px;
}

.sidenav div a:hover {
  background-color: #293846;
  color: white !important;
  cursor: pointer;
  border-radius: 20px;
}
  */

.mob_sidebar {
  height: 100%;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  background-color: #f0f0f0;
  color: white;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}
.active {
  background-color: #293846;
  color: white !important;
  border-radius: 20px;
}
.mob_sidebar a,
.closebutton_link {
  /* padding: 8px 8px 8px 32px; */
  padding: 0.5rem 1rem;
  text-decoration: none;
  font-size: 25px;
  color: #293846;
  display: block;
  transition: 0.3s;
  /* margin-top:20px; */
}

.mob_sidebar a:hover,
.closebutton_link:hover {
  /* color:  #293846; */
  color: white;
  background-color: #293846;
  cursor: pointer;
  border-radius: 20px;
}

.mob_sidebar .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.openbtn {
  font-size: 20px;
  cursor: pointer;
  background-color: #293846;
  color: white;
  padding: 10px 15px;
  border: none;
}

.openbtn:hover {
  background-color: #293846;
}

#main {
  transition: margin-left 0.5s;
  padding: 16px;
}

.sidebar__notication {
  display: flex;
  justify-content: space-between;
}

.sidebar__notication span {
  background-color: green;
  /* width: 25px; */
  /* height: 27px; */
  padding: 5px 8px;
  width: 5vw;
  height: 5vw;

  margin-left: 4px;
  color: white;
  border-radius: 50%;
  text-align: center;
}

.sidebar__notication span:nth-child(2) {
  background-color: red;
}

@media screen and (max-height: 450px) {
  .mob_sidebar {
    padding-top: 15px;
  }
  .mob_sidebar a {
    font-size: 18px;
  }
}

@media screen and (min-width: 850px) and (max-width: 2800px) {
  .mob_sidebar {
    padding-top: 15px;
  }
  .mob_sidebar a,
  .sidebar__notication,
  .sidebar__notication span {
    font-size: 18px;
  }
  #mySidebar {
    width: 250px;
  }
  .mob_sidebar .closebtn {
    display: none;
  }
  #main {
    display: none !important;
  }
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }
  
  .modal-main {
    position:fixed;
    background: white;
    width: 80%;
    height: auto;
    top:50%;
    left:50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
  }
  
  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }
  .modalContainer{
    height: 10vh;
     width: 50vw
  }

  .modalButton{
    margin-left: 40%;
     border-radius: 12px

  }

  
  /* Portrait and Landscape */
  @media only screen 
  and (min-device-width: 240px) 
  and (max-device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .modalContainer{
      height: 10vh;
       width: 70vw;
       margin-top: 15px;
    }
    .modalButton{
      margin-left: 25%;
       border-radius: 12px
  
    }
   
}
  @media only screen 
    and (min-device-width: 321px) 
    and (max-device-width: 360px)
    and (-webkit-min-device-pixel-ratio: 2) {
      .modalContainer{
        height: 10vh;
         width: 70vw;
         margin-top: 15px;
      }
      .modalButton{
        margin-left: 25%;
         border-radius: 12px
    
      }
      
    
  }
  @media only screen 
  and (min-device-width: 361px) 
  and (max-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .modalContainer{
      height: 10vh;
       width: 70vw;
       margin-top: 15px;
    }
    .modalButton{
      margin-left: 25%;
       border-radius: 12px
  
    }
    
  

}
  @media only screen 
  and (min-device-width: 376px) 
  and (max-device-width: 414px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .modalContainer{
      height: 10vh;
       width: 70vw;
       margin-top: 15px;
    }
    .modalButton{
      margin-left: 25%;
       border-radius: 12px
  
    }
   
}
@media only screen 
and (min-device-width: 415px) 
and (max-device-width: 768px)
and (-webkit-min-device-pixel-ratio: 2) {
  .modalContainer{
    height: 10vh;
     width: 70vw;
     margin-top: 15px;
  }
  .modalButton{
    margin-left: 25%;
     border-radius: 12px

  }
 
}

/* @media (min-width: 576px){
    .padding-0{
        padding: 0 !important;
    }
    .img-fluid {
        max-width: 100%;
        height: auto;
        min-height: 100px;
        max-height: 200px;
    }
} */

.card-style {
  display: inline-block;
  margin-bottom: 2em;
  /* margin-left: 50px; */
  margin-top: 50px;
}
.h-title {
  color: black;
  font-size: 18px;
}
.text-secondary {
  color: #6c757d !important;
  max-height: 200px;
  min-height: 100px;
  padding: 0 10px;
  text-align: justify;
  font-size: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.image-container {
  text-align: center;
  border: 1px solid darkgrey;
  background-color: white;
  margin-top: 1em;
  border-radius: 15px;
  box-shadow: 0 2px 8px 0 white;
}
.no-cards-available {
  display: flex;
  justify-content: center;
  margin-top: 5em;
}
.btn-outline-success {
  color: #28a745 !important;
  border-color: #28a745;
  border-radius: 15px !important;
}
.btn-outline-success:hover {
  color: #fff !important;
  background-color: #28a745;
  border-color: #28a745;
}
.CardsLoader {
  margin-top: 40%;
  margin-left: 55%;
}
@media screen and (min-width: 250px) and (max-width: 750px) {
  .CardsLoader {
    margin-top: 60%;
    margin-left: 40%;
  }
  .rightContainer {
    padding-left: 0;
    padding-right: 0;
  }
}

/* Portrait and Landscape */
@media only screen and (min-device-width: 240px) and (max-device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
  .CardsLoader {
    margin-top: 60%;
    margin-left: 40%;
  }
  .rightContainer {
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (min-device-width: 321px) and (max-device-width: 360px) and (-webkit-min-device-pixel-ratio: 2) {
  .rightContainer {
    padding-left: 0;
    padding-right: 0;
  }

  .CardsLoader {
    margin-top: 60%;
    margin-left: 40%;
  }
}
@media only screen and (min-device-width: 361px) and (max-device-width: 375px) and (-webkit-min-device-pixel-ratio: 2) {
  .rightContainer {
    padding-left: 0;
    padding-right: 0;
  }

  .CardsLoader {
    margin-top: 60%;
    margin-left: 40%;
  }
}
@media only screen and (min-device-width: 376px) and (max-device-width: 414px) and (-webkit-min-device-pixel-ratio: 2) {
  .CardsLoader {
    margin-top: 60%;
    margin-left: 40%;
  }
  .rightContainer {
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (min-device-width: 415px) and (max-device-width: 768px) and (-webkit-min-device-pixel-ratio: 2) {
  .CardsLoader {
    margin-top: 60%;
    margin-left: 40%;
  }

  .rightContainer {
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (min-device-width: 400px) and (max-device-width: 850px) and (-webkit-min-device-pixel-ratio: 2) {
  .rightContainer {
    padding-left: 0;
    padding-right: 0;
  }
}

.inbox-tabs{
    padding: 0px 20px;
    margin-bottom: 10px;
    margin-top:20px;
    margin-left: 80px;
    margin-right: 80px;
}
.inbox-tabs li{
    list-style-type: none;
    border-radius: 12px;
}
.inbox-tabs .inbox-tab-li{
    background: white;
    color: black;
    padding: 10px;
    padding-left: 20px;
}
.inbox-tab-li button{
    border-radius: 12px;
    margin-left:-20px;
}

.text-ellipses-h4 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 20px;
}
.task-accordion{
    position: absolute;
    right: 0;
    height: 100%;
    padding: 1em;
    z-index: 1;
    top: 0;
    background-color: #f0f0f0;
    width: 35%;
    overflow-x: hidden;
    padding-top: 20px;
    color: black;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #2F4050 !important;
    color: white;
}
.accordion > .card > .card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0 !important;
    background-color: rgb(191 44 44 / 3%);
    border-bottom: 1px solid white;
}
.card-header a:hover {
    color: white !important;
    text-decoration: none !important;
}
.card-title {
    font-size: 18px;
    color: white;
}
.inside-mupps{
    padding: 10px;
    font-size: 16px;
}
.inside-mupps label{
    margin-right: 6px !important;
}
.accordion .card-body {
    padding: 5px 0px !important;
}
.last-div{
    /* background-color: #2F4050; */
     color: white;
}
.plus-icon, .vertical-ellipses{
    float: right;
    cursor: pointer;
}
.card-body ul{
    margin: 0 !important;
}
.card-body ul li{
    margin: 0 23px 0 0;
    padding: 5px 0;
}
.nested-collabpse-div{
    text-align: right;
}
.edit-icon{
    margin-right: 5px;
}
.edit-input-field{
    cursor: pointer;
    margin: 0 !important;
}
.check-icon{
    position: absolute;
    right: 25px;
    top: 70px;
}
.home-mupps-form-div{
    display: flex;
    justify-content: center;
    align-items: center;
}
.home-mupps-form-div .form-group{
    text-align: center;
    background: #2F4050;
    padding: 2em;
    color: white;
}
.home-mupps-form-div .form-group label{
    font-size: 22px;
}
.home-mupps-form-div .form-group button{
    border-radius: 35px;
    margin-top: 10px;
    padding: 6px 26px !important;
}
.back-button{
    text-align: right;
    font-size: 25px;
    margin-right: 10px;
}
.fa {
    cursor: pointer;
}
.yes-button{
    margin-right: 5px;
}
.project-cursor{
    cursor: pointer;
}
.render-task{
    padding: 0 !important;
    cursor: pointer;
}
.render-task li{
    background: white;
    color: black;
    padding: 2px 10px;
    margin: 1em;
    list-style-type: none;
}
.render-task div span {
    font-size: 14px;
    margin-right: 20px;
}
.render-task h4{
    font-size: 20px !important;
}
.render-task li h4{
    text-align: left;
}
.form-div{
    display: flex;
    justify-content: center;
}
.inline-text{
    display: inline-block;
}
.inline-text h4{
    font-size: 18px !important;
}
.add-mupps-button{
    text-align: right;
}
.add-mupps-button button{
    border-radius: 35px;
    margin-top: 10px;
    padding: 6px 26px !important;
}
.task-type{
    margin-right: 1em;
}
.close-icon{
    font-size: 25px;
    position: fixed;
    right: 0;
    z-index: 9999;
    top: 0;
    color: black;
}
.calendar-icon{
    margin-right: 5px;
}

@media only screen 
and (min-device-width: 240px) 
and (max-device-width: 320px)
and (-webkit-min-device-pixel-ratio: 2) {
  
    .inbox-tabs {
        padding: 0px 20px;
        margin-bottom: 10px;
        margin-top: 20px;
        margin-left: -47px;
        margin-right: -47px;
    }
    .close-icon{
        font-size: 25px;
        position: absolute;
        right: 0;
        z-index: 9999;
        top: 0;
        color: black;
    }
}
@media only screen 
  and (min-device-width: 321px) 
  and (max-device-width: 360px)
  and (-webkit-min-device-pixel-ratio: 2) {
     
    .inbox-tabs {
        padding: 0px 20px;
        margin-bottom: 10px;
        margin-top: 20px;
        margin-left: -47px;
        margin-right: -47px;
    }
    .close-icon{
        font-size: 25px;
        position: absolute;
        right: 0;
        z-index: 9999;
        top: 0;
        color: black;
    }
}
@media only screen 
and (min-device-width: 361px) 
and (max-device-width: 375px)
and (-webkit-min-device-pixel-ratio: 2) {
    .inbox-tabs {
        padding: 0px 20px;
        margin-bottom: 10px;
        margin-top: 20px;
        margin-left: -47px;
        margin-right: -47px;
    }
    .close-icon{
        font-size: 25px;
        position: absolute;
        right: 0;
        z-index: 9999;
        top: 0;
        color: black;
    }

}
@media only screen 
and (min-device-width: 376px) 
and (max-device-width: 414px)
and (-webkit-min-device-pixel-ratio: 2) {
 
    .inbox-tabs {
        padding: 0px 20px;
        margin-bottom: 10px;
        margin-top: 20px;
        margin-left: -47px;
        margin-right: -47px;
    }
    .close-icon{
        font-size: 25px;
        position: absolute;
        right: 0;
        z-index: 9999;
        top: 0;
        color: black;
    }
}
@media only screen 
and (min-device-width: 415px) 
and (max-device-width: 768px)
and (-webkit-min-device-pixel-ratio: 2) {

    .inbox-tabs {
        padding: 0px 20px;
        margin-bottom: 10px;
        margin-top: 20px;
        margin-left: -47px;
        margin-right: -47px;
    }
    .close-icon{
        font-size: 25px;
        position: absolute;
        right: 0;
        z-index: 9999;
        top: 0;
        color: black;
    }
}


.missionStatementContainer1{
    width: 40vw;
     height: 60vh;
     overflow-y: scroll;


}
.missionLeftContainer{
     width: 50%
}

.missionContainer{
    display: flex;
     margin-top: 1em
}

.missionStatementImg{

     height:150px;
      width:300px;
      margin-left: 15px;
}

  /* Portrait and Landscape */
  @media only screen 
  and (min-device-width: 240px) 
  and (max-device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
   

    .missionContainer{
        display: inline;
         margin-top: 1em
    }
     
   
  

}
  @media only screen 
    and (min-device-width: 321px) 
    and (max-device-width: 360px)
    and (-webkit-min-device-pixel-ratio: 2) {
       
                .missionContainer{
                    display: inline;
                     margin-top: 1em
                }
     
       
     
  }
  @media only screen 
  and (min-device-width: 361px) 
  and (max-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 2) {
   
            .missionContainer{
                display: inline;
                 margin-top: 1em
            }
 
   
   
}
  @media only screen 
  and (min-device-width: 376px) 
  and (max-device-width: 414px)
  and (-webkit-min-device-pixel-ratio: 2) {
   
            .missionContainer{
                display: inline;
                 margin-top: 1em
            }
  
}
@media only screen 
and (min-device-width: 415px) 
and (max-device-width: 768px)
and (-webkit-min-device-pixel-ratio: 2) {
    
            .missionContainer{
                display: inline;
                 margin-top: 1em
            }
 
   
 
}

.paradigm_parentcontainer {
  padding: 2rem 0;
}

.paradigm_container {
  /* padding: 2rem; */
  display: flex;
  flex: 1 1;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;
  /* border: 2px solid cadetblue; */
}

.paradigm_video-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  /* border: 2px solid aqua; */
}
.paradigm_videoCard {
  margin-top: 1rem;
  width: 570px;
  /* height: 50vh; */
  cursor: pointer;
  border: 2px solid white;
  border-radius: 2%;
  margin: 1vw;
  /* margin-bottom: 1vh; */
  aspect-ratio: 16/9;
}
.paradigm_videoCard iframe {
  /* width: 560px; */
  width: 100%;
  height: 315px;
}
.paradigm_videoCard a {
  color: white;
  text-decoration: none;
}

.paradigm_videoCard a:hover {
  color: white;
}

.paradigm_videoCard_a {
  color: white;
  text-decoration: none;
}
.paradigm_videoCard_a:hover {
  color: white;
}
.paradigm_sessions {
  text-align: center;
}
.paradigm_sessions h1 {
  margin: 1rem 0;
}

.paradigm_title-heading {
  margin: 1rem 0;
}

.paradigm_articleList {
  height: 100%;
  /* overflow: scroll; */
  width: 100%;
  padding: 0 2rem;
  /* text-align: center; */
}

.paradigm_articleList h1 {
  font-family: "Josefin Sans", sans-serif;
}
.paradigm_articleList p {
  font-family: "Quicksand", sans-serif;
  font-size: 1rem;
}

.paradigm_sessions_logo {
  width: 15vw;
  height: 15vh;
  margin: 1rem 0;
  object-fit: contain;
}
/* 
? --------------------
? Card
? --------------------
*/
.paradigm__cardContainer {
  width: 25vw;
  height: 100%;
  cursor: pointer;
  border: 2px solid white;
  border-radius: 2%;
  margin: 1vw;
  /* margin-bottom: 1vh; */
  aspect-ratio: 16/9;
}
.paradigm__cardContainer h3 {
  margin-top: 1rem;
  text-decoration: none;
  color: white;
  padding: 0 1rem;
  font-size: 1.5vw;
}

.paradigm__cardContainer p {
  /* max-width: 25vw; */
  text-align: center;
  padding: 0 0.5rem;
  font-size: 1rem;
  color: rgba(170, 162, 162, 0.966);
  overflow: hidden;
  /* border: 2px solid rebeccapurple; */
}

.paradigm__card_img {
  width: 100%;
  /* height: 18vw; */
  /* height: 15vw; */
  object-fit: contain;
  aspect-ratio: 16/9;
}
.paradigm__card_button {
  margin: 1rem auto;
  display: block;
  border-radius: 500px;
}

/* 
* ---------------------------
* Pdf 
* ---------------------------
*/

.paradigm_pdfParentContainer {
  /* border: 2px solid red; */
  min-height: 90vh;
}

.paradigm_pdfCard {
  height: 45vh;
  /* border: 2px solid rebeccapurple; */
}
.paradigm__PDFcard_img {
  width: 100%;
  height: 25vh;
  /* height: 18vw; */
  /* height: 15vw; */
  object-fit: contain;
  aspect-ratio: 16/9;
}
.paradigm__pdfContainer_p {
  height: 10vh;
  /* font-size: 1vw !important; */
  /* margin: 0; */
}

.paradigm__pdfViewer {
  width: 83vw;
  height: 85vh;
}
@media only screen and (max-width: 1025px) {
  .paradigm__pdfViewer {
    width: 75vw;
  }
}

@media only screen and (max-width: 825px) {
  .paradigm_pdfCard {
    height: 50vh;
  }
}

@media only screen and (max-width: 801px) {
  .paradigm__pdfViewer {
    width: calc(100vw - 16rem);
  }
}

@media only screen and (min-width: 769px) {
  .paradigm_vid_Box {
    /* width: 39vw !important; */
    width: 560px !important;
  }

  .paradigm__pdfContainer_p {
    font-size: 1.25vw !important;
    margin: 0;
  }
}

@media only screen and (max-width: 769px) {
  .paradigm_container {
    margin-left: 33vw;
  }
  .paradigm__cardContainer {
    margin-bottom: 1vh;
  }
  .paradigm__pdfViewer {
    width: 90%;
    height: 99vh;
  }
}
@media only screen and (max-width: 769px) {
  .paradigm_container {
    margin-left: 0;
    display: block;
  }
  /* .paradigm__cardContainer {
    width: 90vw;
  }
  .paradigm__cardContainer h3 {
    font-size: 6vw;
  } */
  .paradigm_videoCard {
    width: 90vw;
  }
}

@media only screen and (max-width: 500px) {
  .paradigm__cardContainer {
    width: 90vw;
  }
  .paradigm__cardContainer h3 {
    font-size: 6vw;
  }
  .paradigm_pdfCard {
    height: 20rem;
  }
  .paradigm__PDFcard_img {
    height: 10rem;
  }
}

.page__container {
  position: relative;
}
.mainPDFContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.page-controls {
  position: fixed;
  z-index: 1002;
  bottom: 0;
  background: #101010;
  width: calc(100% - 250px);
  left: 0;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 0.2rem;
  margin-left: 250px;
}

.page-controls button {
  width: 44px;
  height: 44px;
  background: white;
  color: black;
  border: 0;
  font: inherit;
  font-size: 0.8em;
  border-radius: 4px;
}

.page-controls span {
  font: inherit;
  font-size: 0.8em;
  padding: 0 0.5em;
}

@media screen, (min-width: 500px) and (hover) {
  .react-pdf__Page__canva {
    margin: 0 auto;
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    height: 90vh !important;
    margin-top: 1rem;
  }
  .react-pdf__Page__annotations {
    display: none;
  }
}

.sucess-picture-page, .table{
	background: white;
	margin-top: 2em;
}
.picos-button{
    border-radius: 20px;
	border: 1px solid white;
	background-color:black;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
    transition: -webkit-transform 80ms ease-in;
    transition: transform 80ms ease-in;
    transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
}
.no-result-found{
	text-align: center;
    padding: 2em !important;
    font-size: 20px;
    font-weight: bold;
}

@media (min-width: 576px){
    .success-picture-picos-page .col-sm-4, .success-picture-picos-page .col-sm-8{
        padding: 0 !important;
    }
    .success-picture-picos-page{
        display: flex;
    }
    .path-div, .desc-div{
        text-align: center;
    }
    .continue{
        display: flex;
        justify-content: flex-end;
    }
}
@media (max-width: 576px){
    .desc-div{
        padding: 1em;
        min-height: 180px;
        border-bottom: 1px solid white;
    }
}
.success-picture-picos-page{
    margin-top: 40px;
}

.image1-div{
    padding: 2em;
    min-height: 180px;
    border-bottom: 1px solid white;
}

.image2-div{
    padding: 3em;
    min-height: 290px;
}

.left-block{
    border-right: 1px solid white;
}
.path-div, .rough-div, .heading-div{
    border-bottom: 1px solid white;
}
.path-div{
    padding: 2em;
    min-height: 180px;
}
.desc-div{
    padding: 3em;
}
.rough-div, .heading-div, .description-div{
    min-height: 180px;
    /* padding: 2em; */
}

.continue-button{
    border-radius: 20px;
	border: 1px solid white;
	background-color:black;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
    transition: -webkit-transform 80ms ease-in;
    transition: transform 80ms ease-in;
    transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
}

.image1, .image2{
    display: inline-grid;
}

.thumbnail{
    max-width: 75%;
    max-height: 250px;
    cursor: pointer;
    border: 1px solid;
    cursor: pointer;
    margin-left:-20px;
}
.image-input{
    cursor: pointer;
    margin-left:-18px;
}

.hidden{
    visibility: hidden;
}
.path-div-label{
    margin-top: 1em;
    font-size: 20px;
}
.cross-icon{
    font-size: 20px;
    color:black;
    margin-top:-20px ;
    margin-left: 90px;
    cursor: pointer;
}
.add-mupps-footer-btn{
    display: flex;
    justify-content: space-around;
    margin-top: 1em;
    margin-right: 1em;
}
.add-mupps-footer-btn-isHidden{
    display: flex;
    justify-content: flex-end;
    margin-top: 1em;
    margin-right: 1em;
}
.nextBtn{
    margin-right: 20px;
    justify-content: flex-end;
    border-radius: 15px;
    padding: 10px 24px;
}
.backBtn{
   border-radius: 15px;
   padding: 10px 24px;

}
.addBtn{
    margin-left:20px;
    border-radius: 15px;
    padding: 10px 24px;
}
.addMuppContainer{
    display: flex;
     margin-left: 10px
}

  /* Portrait and Landscape */
  @media only screen 
  and (min-device-width: 240px) 
  and (max-device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
     .addMuppContainer{
         display: inline;
     }
     
   
  

}
  @media only screen 
    and (min-device-width: 321px) 
    and (max-device-width: 360px)
    and (-webkit-min-device-pixel-ratio: 2) {
        .addMuppContainer{
            display: inline;
        }
     
       
     
  }
  @media only screen 
  and (min-device-width: 361px) 
  and (max-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .addMuppContainer{
        display: inline;
    }
  
   
}
  @media only screen 
  and (min-device-width: 376px) 
  and (max-device-width: 414px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .addMuppContainer{
        display: inline;
    }
  
   
}
@media only screen 
and (min-device-width: 415px) 
and (max-device-width: 768px)
and (-webkit-min-device-pixel-ratio: 2) {
    .addMuppContainer{
        display: inline;
    }
  
 
}
.test-container{
    padding: 0 3em;
}
.test-header{
    text-align: center;
    margin-bottom: 2em;
}
.test-header p, .test-header a{
    font-size: 25px;
    padding-top: 35px;
    margin-bottom: 5px;
    margin-left: 20px;
    cursor: pointer;
    color:white
}

.artQuestion{
    line-height: normal;
}
.testChild{
    height: 50vh;
}

#btn{
    float: right;
    margin-top: 1em;
    margin-bottom: 1em;
}
#btn:hover {
    background-color: rgb(29, 26, 26); /* Green */
    color: white;
  }

.test-link-div1 {
    display: flex;
    background-color: white;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    height: 65vh;
    width: 75vw !important;
}
.test-link-div2 {
    display: flex;
    background-color: white;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    height: 40vh;
    width: 75vw !important;
}
.highlightBtn{
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 14px;
}
.content-editor-one{
    border-style: none !important;
    min-height: 100px;
}
.content-editor-one p{
    font-size: 18px;
    line-height: normal;
}
.content-editor-two{
    min-height: 200px;
    font-size:18px;
}

@media screen and (min-width: 250px) and (max-width: 750px)  {
   #btn{
    float: right;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-right: 45px;
   }
   .test-container{
    padding: 0 0;
}
.testChild{
    height: 50vh;
}
}


 /* Portrait and Landscape */
 @media only screen 
 and (min-device-width: 240px) 
 and (max-device-width: 320px)
 and (-webkit-min-device-pixel-ratio: 2) {
    #btn{
        float: right;
        margin-top: 1em;
        margin-bottom: 1em;
        margin-right: 45px;
       }
       .test-container{
        padding: 0 0;
    }


   

}
 @media only screen 
   and (min-device-width: 321px) 
   and (max-device-width: 360px)
   and (-webkit-min-device-pixel-ratio: 2) {
    #btn{
        float: right;
        margin-top: 1em;
        margin-bottom: 1em;
        margin-right: 45px;
       }
       .test-container{
        padding: 0 0;
    }

    
	   
	
 }
 @media only screen 
 and (min-device-width: 361px) 
 and (max-device-width: 375px)
 and (-webkit-min-device-pixel-ratio: 2) {
    #btn{
        float: right;
        margin-top: 1em;
        margin-bottom: 1em;
        margin-right: 45px;
       }
       .test-container{
        padding: 0 0;
    }

  
  
  
}
 @media only screen 
 and (min-device-width: 376px) 
 and (max-device-width: 414px)
 and (-webkit-min-device-pixel-ratio: 2) {
  
    #btn{
        float: right;
        margin-top: 1em;
        margin-bottom: 1em;
        margin-right: 45px;
       }
       .test-container{
        padding: 0 0;
    }


  
}
@media only screen 
and (min-device-width: 415px) 
and (max-device-width: 768px)
and (-webkit-min-device-pixel-ratio: 2) {
    #btn{
        float: right;
        margin-top: 1em;
        margin-bottom: 1em;
        margin-right: 45px;
       }
       .test-container{
        padding: 0 0;
    }
  



  

}
.ratingContainer {
    height: 100vh;
    /* overflow-y: auto;
    max-height: 90vh; */
  }
  
  .btn {
    margin-left: 10px;
  }
  .textEditor {
    height: 100%;
    width: 100%;
    border-style: solid;
    background: white;
  }
  .textEditor1 {
    height: 33%;
    width: 100%;
    border-style: solid;
    background: white;
  }
  .textEditor2 {
    margin-top: 1px;
    height: 33%;
    width: 100%;
    padding-top: 2%;
    border-style: solid;
    background: white;
  }
  
  
  p {
    margin-bottom: 0px;
  }
  
  
  #title {
    text-align: center;
    font-family: arial, sans-serif;
  }
  
  #mupps {
    text-align: center;
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    border: 3px solid #ddd;
    width: 100%;
  }
  
  #mupps td, #mupps th {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  
  #mupps tr:hover {background-color: #f2f2f2}
  
  #mupps th {
    position: sticky;
    top: 0;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #a0a0a0;
    color: white;
    z-index: 9999;
  }
  
  .tableFixHead {
    overflow-y: auto; 
    max-height: 65vh;
  }
  .tableFixHead thead th {
    position: sticky;
    top: 0;
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }
  th,
  td {
    padding: 8px 16px;
    border: 1px solid #ccc;
  }
  th {
    background: #eee;
  }
  
  
  .react-numeric-input input {
    color: red;
    font-size: smaller;
    width:50px;
  }
  
.selected-mupps{
    padding: 0;
    margin: 1em 0;
}
.selected-mupps-h4{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    color: black;
    padding: 10px 10px;
    border-radius: 10px;
}
.selected-mupps-h4 h4{
    font-size: 18px;
}
.selected-mupps-checkbox{
    min-width: 30px;
    min-height: 30px;
    width: 30px;
    height: 30px;
    cursor: pointer;
}
.selfContainer{
    height: 100vh;
    
    display: flex;
    justify-content: center;
    
}
.nameContainer{
    color: red;
}

.selfContainer .selfchildContainer{
    background-color:transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 50px 50px;
    height: 100%;
    width: 400px;
    text-align: center;
}
.contineBtn{
    width: 50%;
    height: 7%;
    text-align: center;
    padding: 4px;
    border-radius: 20px
}
.text{
    font-size: 25px;
    color: 'white';
    cursor: pointer;
    font-weight: 400;
    margin-top: 15px;
    margin-bottom: 50px;
    color:white;
    
}


.ItemContainer{
   
    margin-bottom: 25px;
    cursor: pointer;
}

.ItemContainer:hover{
   color:blue;

}

.ItemContainer p{
    width: 50vh;
    text-decoration: none;
    display: inline-block;
    margin: 0 10px;
    padding:20px 0;
    color: #fff;
    z-index: 1;
    background-color:rgb(51, 50, 50);
    border-radius: 30px;
    font-size: 20px;
    font-weight: 400;
    line-height: normal;


}

.self-container{
    padding-left: 0 !important; 
    padding-right: 0 !important;  
}

.selfPage1Img{
  height:150px;
   width:300px
}

.text1{
  height: 100%;
   color: black;
   overflow: scroll
}
.testChild1{
  display: flex;
  margin-left: 20px;
}

.self-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.self-footer-next-button{
    text-align: center;
    margin-top: 1em;
}
.self-footer-next-button button{
    border-radius: 15px;
}
.self-header-h2{
    background: white;
    color: black;
    padding: 1em;
    margin-left: 30px;
}
.highlightBtn{
    margin-left: 5px;
}
[contenteditable=true]:empty:before {
    content: attr(placeholder);
    display: block;
    color: #aaa;
  }

  .test-link-div {
    display: flex;
    background-color: white;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    height: 70vh;
    width: 85vw;
    margin-left:10px;
    margin-bottom: 10px;
}
  .selfpage1Container{
    display: flex;
     height: 60vh;
    margin-Left: 10px;
    margin-Top: 10px
  }
.missionStatementContainer{

    display: flex;
     margin-Top: 1em
}
.selfButton{
  margin-left: 45%;
  margin-top: 2%;
  margin-bottom: 2%;
 
}
  


   /* Portrait and Landscape */
   @media only screen 
   and (min-device-width: 240px) 
   and (max-device-width: 320px)
   and (-webkit-min-device-pixel-ratio: 2) {
       .self-header{
        display: inline;
        justify-content: space-between;
        align-items: center;
       }
       .self-header-h2{
        background: white;
        width: 120%;
        color: black;
        margin-right: 10px;
    }
    .selfpage1Container{
        display: inline;
       
      }
      .text1{
        height: 10%;
         color: black;
         overflow: scroll
      }
       .selfPage1Img{
        height: 180px;
        width: 280px;
        margin-left: 20px;
        margin-top: 10px;
        margin-bottom: 10px;
      } 
      .missionStatementContainer{

        display: inline;
         margin-Top: 1em
    }
    .testChild1{
      display: inline;
    }
    .selfButton{
      margin-left: 30%;     
    }
      
      
    
   
 
 }
   @media only screen 
     and (min-device-width: 321px) 
     and (max-device-width: 360px)
     and (-webkit-min-device-pixel-ratio: 2) {
        .self-header{
            display: inline;
            justify-content: space-between;
            align-items: center;
    
           }
           .self-header-h2{
            background: white;
            color: black;
            margin-left: 20px;
            margin-right: 20px;
        }
        .selfpage1Container{
            display: inline;

       
        }
        .selfPage1Img{
            height: 150px;
            width: 250px;
            margin-left: 30px;
            margin-top: 10px;
            margin-bottom: 10px;
          } 
          .missionStatementContainer{

            display: inline;
             margin-Top: 1em
        }
        .text1{
          height: 10%;
           color: black;
           overflow: scroll
        }
        .testChild1{
          display: inline;
        }
        .selfButton{
          margin-left: 30%;
     
     
        }
        
      
   }
   @media only screen 
   and (min-device-width: 361px) 
   and (max-device-width: 375px)
   and (-webkit-min-device-pixel-ratio: 2) {
    .self-header{
        display: inline;
        justify-content: space-between;
        align-items: center;

       }
       .self-header-h2{
        background: white;
        width: 100%;
        color: black;
    }
    .selfpage1Container{
        display: inline;

       
    }
    .selfPage1Img{
        height: 180px;
        width: 280px;
        margin-left: 20px;
        margin-top: 10px;
        margin-bottom: 10px;
      } 
      .missionStatementContainer{

        display: inline;
         margin-Top: 1em
    }
    .text1{
      height: 10%;
       color: black;
       overflow: scroll
    }
    .testChild1{
      display: inline;
    }
    .selfButton{
      margin-left: 30%;     
    }
    
    
 }
   @media only screen 
   and (min-device-width: 376px) 
   and (max-device-width: 414px)
   and (-webkit-min-device-pixel-ratio: 2) {
    .self-header{
        display: inline;
        justify-content: space-between;
        align-items: center;

       }
       .self-header-h2{
        background: white;
        width: 100%;
        color: black;
    }
    .selfpage1Container{
        display: inline;
    }
    .selfPage1Img{
        height: 180px;
        width: 280px;
        margin-left: 35px;
        margin-top: 10px;
        margin-bottom: 10px;
      } 
      .missionStatementContainer{

        display: inline;
         margin-Top: 1em
    }
    .text1{
      height: 10%;
       color: black;
       overflow: scroll
    }
    .testChild1{
      display: inline;
    }
    .selfButton{
      margin-left: 30%;     
    }
 }
 @media only screen 
 and (min-device-width: 415px) 
 and (max-device-width: 768px)
 and (-webkit-min-device-pixel-ratio: 2) {
    .self-header{
        display: flex;
        justify-content: space-between;
        align-items: center;

       }
       .self-header-h2{
        background: white;
        width: 110%;
        color: black;
    }
    .selfpage1Container{
        display: flex;
    }
    .selfPage1Img{
        height: 180px;
        width: 280px;
        margin-left: 20px;
        margin-top: 10px;
        margin-bottom: 10px;
      } 
      .missionStatementContainer{

        display: inline;
         margin-Top: 1em
    }
    .text1{
      height: 50%;
       color: black;
       overflow: scroll;
       
    }
    .testChild1{
      display: inline;
      justify-content: center;
      align-items: center;
      
    }
    .selfButton{
      margin-left: 30%;

     
     
    }
    
  
 }

.tab-navs a{
    font-size: 16px;
    cursor: pointer;
}
.nav-link{
    padding: 15px;
    margin-top: 7px;
}
.nav-link.active{
    background-color: #293846 !important;
    color: white !important;
    border: none !important;
}
.nodata{
    margin-left: 40%;
}

.text-ellipses-h4-todo{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 20px;
}



.todo-tabs .todo-tab-li{
  background: white;
  color: black;
  padding: 10px;
  padding-left: 20px;
}
.todo-tab-li button{
  border-radius: 12px;
  margin-left:-20px;
}
  .btn-nav-tabs-todo{
    display: flex;
    padding: 0;
    border-style: groove;
    margin-bottom: 50px;
  }
  
  .btn-nav-tabs-todo li {
    padding: 10px;
    text-align: center;
    cursor: pointer;
    border-right: 1px solid;
    border-top: hidden;
    padding-right: 10%;
  }

  .btn-nav-tabs-todo li, .all-categories-tab-todo li{
    list-style-type: none;
  }

  .dailyHabbitBtn{
    margin-left: 0;
  }

  .btn-nav-tabs-todo li {
    padding: 10px;
    text-align: center;
    cursor: pointer;
    border-right: 1px solid;
    padding-right: 10%;

  }

.text-ellipses{
    white-space: nowrap;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.all-categories-tab{
  margin-top: 20px;
  
}
 .all-categories-tab-later li{
  list-style-type: none;
}
.all-categories-li{
  background: white;
  color: black;
  margin-bottom: 5px;
  border-radius: 12px;
  padding: 5px 0 0 10px;
  margin-right:10px;
  margin-left:10px;
}

.todo-tabs{
    padding: 0px 20px;
    margin-bottom: 10px;
    margin-top:20px;
    
}
.todo-tabs li{
    list-style-type: none;
    border-radius: 12px;
    display: flex;
}


a:focus {
    outline: none;
    border: none;
}
@media (max-width: 575px){
    .all-categories-li,  .add-task-header, .inbox-tab-li{
      text-align: center;   
    }
  }
  
  /* Portrait and Landscape */
  @media only screen 
  and (min-device-width: 240px) 
  and (max-device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .all-categories-tab{
      margin-top: -21px;
      float: right;
      width: 337px;
    }
    .elipse-text-for-title{
      width: 250px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
    }
    .all-categories-li {
      background: white;
      color: black;
      margin-bottom: 5px;
      border-radius: 12px;
      padding: 5px 0 0 10px;
      margin-right: 10px;
      margin-left: 10px;
      width: 322px;
  }
  .btn-nav-tabs-todo{
    padding: 0; 
    margin-bottom: 50px;
    display: block;
  }
  .btn-nav-tabs-todo li {
    padding: 10px;
    text-align: center;
    cursor: pointer; 
  }
  .todo-tabs{
    padding: 0px 20px;
    margin-bottom: 10px;
    margin-top:20px;
    margin-left: 15%;
    margin-right: -5%;
}
.todo-tabs .todo-tab-li{
  background: white;
  color: black;
  padding: 10px;
  padding-left: 20px;
  display: flow-root;
}
.dailyHabbitBtn{
  margin-left: 30%;
}
.dis-flex{
  display:grid;
  text-align: center;

}


}
  @media only screen 
    and (min-device-width: 321px) 
    and (max-device-width: 360px)
    and (-webkit-min-device-pixel-ratio: 2) {
      .all-categories-tab{
        margin-top: 20px;
        float: right;
        width: 377px;
      }
      .all-categories-li {
        background: white;
        color: black;
        margin-bottom: 5px;
        border-radius: 12px;
        padding: 5px 0 0 10px;
        margin-right: 10px;
        margin-left: 10px;
        width: 362px;
    }
    .elipse-text-for-title{
      width: 250px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
    }
    .btn-nav-tabs-todo{
      padding: 0; 
      margin-bottom: 50px;
      display: block;
    }
    .btn-nav-tabs-todo li {
      padding: 10px;
      text-align: center;
      cursor: pointer; 
    }
    .todo-tabs{
      padding: 0px 20px;
      margin-bottom: 10px;
      margin-top:20px;
      margin-left: 15%;
      margin-right: -5%;
  }
  .todo-tabs .todo-tab-li{
    background: white;
    color: black;
    padding: 10px;
    padding-left: 20px;
    display: flow-root;
  }
  .dailyHabbitBtn{
    margin-left: 30%;
  }
  .dis-flex{
    display:grid;
    text-align: center;

  }
  }
  @media only screen 
  and (min-device-width: 361px) 
  and (max-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .all-categories-tab{
      margin-top: 20px;
      float: right;
      width: 391px;
    }
    .elipse-text-for-title{
      width: 250px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
    }
    .all-categories-li {
      background: white;
      color: black;
      margin-bottom: 5px;
      border-radius: 12px;
      padding: 5px 0 0 10px;
      margin-right: 10px;
      margin-left: 10px;
      width: 372px;
  }
  .btn-nav-tabs-todo{
    padding: 0; 
    margin-bottom: 50px;
    display: block;

  } 
  
  .btn-nav-tabs li {
    padding: 10px;
    text-align: center;
    cursor: pointer; 
  }
  .todo-tabs{
    padding: 0px 20px;
    margin-bottom: 10px;
    margin-top:20px;
    margin-left: 15%;
    margin-right: -5%;
}
.todo-tabs .todo-tab-li{
  background: white;
  color: black;
  padding: 10px;
  padding-left: 20px;
  display: flow-root;
}
.dailyHabbitBtn{
  margin-left: 30%;
}
.dis-flex{
  display:grid;
  text-align: center;

}

}
  @media only screen 
  and (min-device-width: 376px) 
  and (max-device-width: 414px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .all-categories-tab{
      margin-top: 20px;
      float: right;
      width: 426px;
    }
    .elipse-text-for-title{
      width: 250px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
    }
    .all-categories-li {
      background: white;
      color: black;
      margin-bottom: 5px;
      border-radius: 12px;
      padding: 5px 0 0 10px;
      margin-right: 10px;
      margin-left: 10px;
      width: 412px;
  }
  .btn-nav-tabs-todo{
    padding: 0; 
    margin-bottom: 50px;
    display: block;
  }
  .btn-nav-tabs-todo li {
    padding: 10px;
    text-align: center;
    cursor: pointer; 
  }
  .todo-tabs{
    padding: 0px 20px;
    margin-bottom: 10px;
    margin-top:20px;
    margin-left: 15%;
    margin-right: -5%;
}
.todo-tabs .todo-tab-li{
  background: white;
  color: black;
  padding: 10px;
  padding-left: 20px;
  display: flow-root;
}
.dailyHabbitBtn{
  margin-left: 30%;
}
.dis-flex{
  display:grid;
  text-align: center;

}
}
@media only screen 
and (min-device-width: 415px) 
and (max-device-width: 768px)
and (-webkit-min-device-pixel-ratio: 2) {
  .all-categories-tab{
    margin-top: 20px;
    float: right;
    width: 564px;
  }
  .btn-nav-tabs-todo{
    padding: 0; 
    margin-bottom: 50px;
    display: block;
  }
  .elipse-text-for-title{
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }
  .btn-nav-tabs-todo li {
    padding: 10px;
    text-align: center;
    cursor: pointer; 
  }
  .todo-tabs{
    padding: 0px 20px;
    margin-bottom: 10px;
    margin-top:20px;
    margin-left: 15%;
    margin-right: -5%;
}
.todo-tabs .todo-tab-li{
  background: white;
  color: black;
  padding: 10px;
  padding-left: 20px;
  display: flow-root;
}
.dailyHabbitBtn{
  margin-left: 30%;
}
.dis-flex{
  display:grid;
  text-align: center;
}
}

body {
    margin: unset !important;
  }
  .App {
    margin: unset !important;
    padding: unset !important;
  }
  
  .sidebar{
    height: 100%;
    width: 180px;
    background-color: #2F4050;
  }
  .wrapper {
    position: absolute;
    top: 0;
    
    height: 100vh;
    width: 100%;
    background: #2F4050;
    display: table;
  }
  
  .category-list {
    width: calc(100% - 1px);
    height: 61px;
    background: #2F4050;
    margin-left: 1px;
    display: table;
  }
  .list-table {
    display: table-cell;
      color: white;
      vertical-align: middle;
  }
  .list-table:active{
    background : white;
  }
  .list-value {
    width: 100px;
    margin-left: 30px;
    font-size: 18px;
    text-align: center;
  }
  .list-value:hover{
    cursor: pointer;
    background-color: #293850;
  }
  .select-button{
    position: absolute;
    top: 25%;
    left: 110%;
  }
  
  .task-date{
    position: absolute;
    top: 50px;
    left: 20%;
  }
  .project-name{
    position: absolute;
    top: 50px;
  }
  .mupp-path{
    margin-left: 115px;
  }
  .taskDetailbtn{
    position: absolute;
    top:20px;
    left:80%;
    
  }
  /* .list-tabs{
    height: 50px;
    width:150px;
    background:black;
    margin-top: 10px;
    border-radius: 10px;
    font-weight: 400;
    padding-top: 15px;
  }
   */
  
  .inactive{
    /* height: 50px;
    width:150px;
    background:blue;
    margin-top: 10px;
    border-radius: 10px;
    font-weight: 400;
    padding-top: 15px; */
  }

  .btn-nav-tabs-awaiting{
    display: flex;
    padding: 0;
    border-style: groove;
    /* border-bottom: 1px solid; */
  }
  
  .btn-nav-tabs-awaiting li {
    padding: 10px;
    text-align: center;
    cursor: pointer;
    border-right: 1px solid;
    border-top: hidden;
    padding-right: 10%;
  }
  
  .btn-nav-tabs-awaiting li, .all-categories-tab-awaiting li{
    list-style-type: none;
  }
  
  @media (min-width: 576px){
    .btn-nav-tabs-awaiting li {
      width: 10%;
      display: inline-block;
    }
    .this-week-li {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .dis-flex{
      display: flex;
    }
    .inline-css {
      display: flex;
      height: 100%;
    }
    .add-task-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .inbox-tabs .inbox-tab-li{
      display: flex;
      align-items: baseline;
    }
    .test-align-right{
      text-align: right;
    }
  
  }
  @media (max-width: 575px){
    .all-categories-li,  .add-task-header, .inbox-tab-li{
      text-align: center;   
    }
  }
 
  /* Portrait and Landscape */
  @media only screen 
  and (min-device-width: 240px) 
  and (max-device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .all-categories-tab{
      margin-top: -21px;
      float: right;
      width: 337px;
    }
    .all-categories-li {
      background: white;
      color: black;
      margin-bottom: 5px;
      border-radius: 12px;
      padding: 5px 0 0 10px;
      margin-right: 10px;
      margin-left: 10px;
      width: 322px;
  }
  .btn-nav-tabs-awaiting{
    padding: 0; 
    margin-bottom: 50px;
    display: block;
  }
  .btn-nav-tabs-awaiting li {
    padding: 10px;
    text-align: center;
    cursor: pointer; 
  }
  
  }
  @media only screen 
    and (min-device-width: 321px) 
    and (max-device-width: 360px)
    and (-webkit-min-device-pixel-ratio: 2) {

      
      .all-categories-tab{
        margin-top: 20px;
        float: right;
        width: 377px;
      }
      .all-categories-li {
        background: white;
        color: black;
        margin-bottom: 5px;
        border-radius: 12px;
        padding: 5px 0 0 10px;
        margin-right: 10px;
        margin-left: 10px;
        width: 362px;
    }
    .btn-nav-tabs-awaiting{
      padding: 0; 
      margin-bottom: 50px;
      display: block;
    }
    .btn-nav-tabs-awaiting li {
      padding: 10px;
      text-align: center;
      cursor: pointer; 
    }
  }
  @media only screen 
  and (min-device-width: 361px) 
  and (max-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .all-categories-tab{
      margin-top: 20px;
      float: right;
      width: 391px;
    }
    .all-categories-li {
      background: white;
      color: black;
      margin-bottom: 5px;
      border-radius: 12px;
      padding: 5px 0 0 10px;
      margin-right: 10px;
      margin-left: 10px;
      width: 372px;
  }
  .btn-nav-tabs-awaiting{
    padding: 0; 
    margin-bottom: 50px;
    display: block;
  }
  } .btn-nav-tabs li {
    padding: 10px;
    text-align: center;
    cursor: pointer; 
  }
  
  
  @media only screen 
  and (min-device-width: 376px) 
  and (max-device-width: 414px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .all-categories-tab{
      margin-top: 20px;
      float: right;
      width: 426px;
    }
    .all-categories-li {
      background: white;
      color: black;
      margin-bottom: 5px;
      border-radius: 12px;
      padding: 5px 0 0 10px;
      margin-right: 10px;
      margin-left: 10px;
      width: 412px;
  }
  .btn-nav-tabs-awaiting{
    padding: 0; 
    margin-bottom: 50px;
    display: block;
  }
  .btn-nav-tabs-awaiting li {
    padding: 10px;
    text-align: center;
    cursor: pointer; 
  }
  }
  @media only screen 
  and (min-device-width: 415px) 
  and (max-device-width: 768px)
  and (-webkit-min-device-pixel-ratio: 2) {
  .all-categories-tab{
    margin-top: 20px;
    float: right;
    width: 564px;
  }
  .btn-nav-tabs-awaiting{
    padding: 0; 
    margin-bottom: 50px;
    display: block;
  }
  .btn-nav-tabs-awaiting li {
    padding: 10px;
    text-align: center;
    cursor: pointer; 
  }
  }
  

/* Work Mupp Picos Css */
.work-mupp-picos .table thead tr th, .mupp-picos-project .table thead tr th, .inner-table tbody tr td:nth-child(2), 
.inner-table tbody tr td:nth-child(3), .project-review-inner-table tbody tr td:nth-child(2), 
.project-review-inner-table tbody tr td:nth-child(3), .project-review .table thead tr th, .rank-priority-table tbody tr td{
   text-align: center;
} 
.work-mupp-picos .table tbody tr td:first-child, .work-mupp-picos .table tbody tr td:nth-child(2){
    width: 45% !important;
} 
.work-mupp-picos .table tbody tr td:nth-child(3){
    width: 10% !important;
} 

/* Mupp Picos Projects Css */
.mupp-picos-project .table th, .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: none !important;
}
/* .mupp-picos-project .outer-table tbody tr td:first-child{
    width: 10%;
}  */
/* .mupp-picos-project .outer-table tbody tr td:nth-child(2){
    width: 40%;*/
    /* line-height: 2.5; */
/*} */
.padd-0{
    padding: 0 !important;
}
/* .mupp-picos-project .outer-table tbody tr td:nth-child(3){
    width: 50% !important;
} */
.mupp-picos-project .outer-table tbody tr td:nth-child(3) .table-bordered{
    margin: 0;
}
.mupp-picos-project .add-project-btn-div{
    text-align: right;
}
.mupp-picos-project .add-project-btn-div .modal.show .modal-dialog {
    -webkit-transform: none;
            transform: none;
    color: black;
}
.add-project-btn-div .modal-dialog{
    text-align: left;
}
.modal-div{
    display: flex !important;
    align-items: baseline !important;
}
.is-project-checkbox{
    cursor: pointer;
    width:25px;
    height:25px;
}
.inner-table thead tr th:first-child{
    width: 90%;
}
.inner-table thead tr th:nth-child(2), .inner-table thead tr th:nth-child(3){
    width: 10%;
}
/* Review Project */
.project-review .table th, .table td {
    border-top: none !important;
}
.project-review .table tbody tr td:nth-child(3) .table-bordered{
    margin: 0;
}
.project-review-inner-table tbody tr td:first-child{
    width: 90%;
}

.project-td{
    display: flex;
}
@media (min-width: 576px){
    .title-table{
        width: 480px !important;
    }
}

button:focus {
    outline: none !important;
}
button:disabled{
    cursor: not-allowed;
}

.mupptitle{
    width: 20% !important;

}


/* .mupp .picos {
    padding: 0 !important;
    cursor: pointer;
    height: 10vh;
    overflow-y:scroll;
}

tbody {
    height: 100px;       
    overflow-y: auto;    
    overflow-x: hidden;  
} */


.mupps-accordion {
    height: 100%;
    z-index: 1;
    top: 0;
    left: -25px;
    /* background-color: #2F4050; */
    overflow-x: hidden;
    color: white;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.task-accordion{
    position: fixed;
    height:52rem;
    padding: 1em;
    z-index: 1;
    top: 0;
    background-color: #f0f0f0;
    width:20%;
    overflow-x: hidden;
    padding-top: 20px;
    color: black;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.task-accordion h4{
    margin-top: 5px;
}
.aPath{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 90%;
    margin-left: 10px;

}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #2F4050;
    color: white;
}
.accordion{
    margin-top: 5px;
}
.accordion > .card > .card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0 !important;
    background-color: rgb(191 44 44 / 3%);
    border-bottom: 1px solid white;
}
.card-header a:hover {
    color: white !important;
    text-decoration: none !important;
}
.card-title {
    font-size: 18px;
    color: white;
}
.inside-mupps{
    padding: 10px;
    font-size: 16px;
}
.inside-mupps label{
    margin-right: 6px !important;
}
.accordion .card-body {
    padding: 5px 0px !important;
}
.last-div{
    /* background-color: #2F4050; */
     color: white;
     height:100%;
}
.plus-icon, .vertical-ellipses{
    float: right;
    cursor: pointer;
}
.card-body ul{
    margin: 0 !important;
    background: rgb(25,25,36)
}
.elipse-text-for-title-mupp{
    width: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.card-body ul li{
    margin: 0;
    padding: 5px 0;
}
.nested-collabpse-div{
    text-align: right;
}
.edit-icon{
    margin-right: 15px;
    cursor: pointer;
}
.trash-icon{
    margin-right: 10px;
    cursor:pointer;
}
.edit-input-field{
    cursor: pointer;
    margin: 0 !important;
}
.check-icon{
    position: absolute;
    right: 25px;
    top: 70px;
}
.home-mupps-form-div{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top:50px;
}
.home-mupps-form-div .form-group{
    text-align: center;
    background: #2F4050;
    padding: 2em;
    color: white;
}
.home-mupps-form-div .form-group label{
    font-size: 22px;
}
.home-mupps-form-div .form-group button{
    border-radius: 35px;
    margin-top: 10px;
    padding: 6px 26px !important;
}
.back-button{
    /* text-align: right; */
    margin-right: 10px;
}
.fa {
    cursor: pointer;
}
.yes-button{
    margin-right: 5px;
}
/* .project-cursor{
    cursor: pointer;
} */
.render-task{
    padding: 0 !important;
    cursor: pointer;
    /* height: 90vh; */
    overflow-y:auto;
}
.project-renderer{
    max-height: 90vh;
    overflow-y:auto;
}

/* .render-task li{
    background: white;
    color: black;
    padding: 2px 10px;
    margin: 1em;
    list-style-type: none;
    border-radius: 5px; 
} */

.border-left-only{
    border-width: 1px 2em 5px 0; /* top right bottom left */
border-style: solid ;
border-color: #191922 #191922 #191922 #191922;
}
.render-task div label {
    font-size: 14px;
    margin-right: 20px;
}
.render-task h4{
    font-size: 20px !important;
}
.render-task li h4{
    text-align: left;
}
.form-div{
    display: flex;
    justify-content: center;
    margin-top:50px;
}
.inline-text{
    display: inline-block;
}
.inline-text h4{
    font-size: 18px !important;
}
.add-mupps-button{
    text-align: right;
}
.add-mupps-button button{
    border-radius: 35px;
    margin-top: 10px;
    padding: 6px 26px !important;
}
.task-type{
    margin-right: 1em;
}
.close-icon{
    font-size: 25px;
    position: fixed;
    right: 15px;
    z-index: 9999;
    top: 15px;
    color: black;
}
.calendar-icon{
    margin-right: 5px;
}

.active-button{
    color: green;
}
.project-header {
    padding-left: 0.75rem;
    margin-bottom: 0 !important;
    background:rgb(48, 47, 47);
    border-bottom: 1px solid white;
    cursor: pointer;
}
.project-header a:hover {
    color: white ;
    text-decoration: none !important;
}
 
.active-background{
    padding-left: 0.75rem;
    margin-bottom: 0 !important;
    background-color:rgb(13, 13, 32);
    border-bottom: 1px solid white;
    cursor: pointer;
} 
.project-imp{
    padding-left: 0.75rem;
    margin-bottom: 0 !important;
    background:#1b2c40;
    border-bottom: 1px solid white;
    cursor: pointer;
}

.elipse-text-mupps{
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .borderStrip{
     border-style: solid;
      border-left-color: red;
         border-width: 8px 
  }

 /* Portrait and Landscape */
 @media only screen 
 and (min-device-width: 240px) 
 and (max-device-width: 320px)
 and (-webkit-min-device-pixel-ratio: 2) {
    .task-accordion{
        position: absolute;
        right: -30px;
        height:52rem;
        padding: 1em;
        z-index: 1;
        top: 0;
        background-color: #f0f0f0;
        width: 70%;
        overflow-x: hidden;
        padding-top: 20px;
        color: black;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    }
   
    
  
 

}
 @media only screen 
   and (min-device-width: 321px) 
   and (max-device-width: 360px)
   and (-webkit-min-device-pixel-ratio: 2) {
    .task-accordion{
        position: absolute;
        right: -30px;
        height:52rem;
        padding: 1em;
        z-index: 1;
        top: 0;
        background-color: #f0f0f0;
        width: 70%;
        overflow-x: hidden;
        padding-top: 20px;
        color: black;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    }
   
     
      
    
 }
 @media only screen 
 and (min-device-width: 361px) 
 and (max-device-width: 375px)
 and (-webkit-min-device-pixel-ratio: 2) {
 
    .task-accordion{
        position: absolute;
        right: -30px;
        height:58rem;
        padding: 1em;
        z-index: 1;
        top: 0;
        background-color: #f0f0f0;
        width: 70%;
        overflow-x: hidden;
        padding-top: 20px;
        color: black;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    }
   
  
}
 @media only screen 
 and (min-device-width: 376px) 
 and (max-device-width: 414px)
 and (-webkit-min-device-pixel-ratio: 2) {
    .task-accordion{
        position: absolute;
        right: -30px;
        height:78rem;
        padding: 1em;
        z-index: 1;
        top: 0;
        background-color: #f0f0f0;
        width: 70%;
        overflow-x: hidden;
        padding-top: 20px;
        color: black;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    }
   
  
  
}
@media only screen 
and (min-device-width: 415px) 
and (max-device-width: 768px)
and (-webkit-min-device-pixel-ratio: 2) {
    .task-accordion{
        position: absolute;
        right: -30px;
        height:68rem;
        padding: 1em;
        z-index: 1;
        top: 0;
        background-color: #f0f0f0;
        width: 70%;
        overflow-x: hidden;
        padding-top: 20px;
        color: black;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    }
   
  
  

}

th, td {
        border: none;
}


/* 
overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 90%;
} */
.react-responsive-modal-modal {
  margin-top: 145px;
  border-radius: 12px;
}
.react-responsive-modal-closeButton {
  top: 2px;
}
.textAreamodal {
  margin-top: 15px;
  height: 10vh;
  width: 50vw;
}
.monthlymodalButton {
  margin-left: 20%;
  border-radius: 12px;
  margin-top: 10px;
}

/* Portrait and Landscape */
@media only screen and (min-device-width: 240px) and (max-device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
  .react-responsive-modal-modal {
    margin-top: 145px;
    border-radius: 12px;
    width: 80%;
  }

  .textAreamodal {
    height: 10vh;
    width: 70vw;
    margin-top: 15px;
  }
  .monthlymodalButton {
    margin-left: 20%;
    border-radius: 12px;
    margin-top: 10px;
  }
}
@media only screen and (min-device-width: 321px) and (max-device-width: 360px) and (-webkit-min-device-pixel-ratio: 2) {
  .react-responsive-modal-modal {
    margin-top: 145px;
    border-radius: 12px;
    width: 80%;
  }
  .textAreamodal {
    height: 10vh;
    width: 70vw;
    margin-top: 15px;
  }
  .monthlymodalButton {
    margin-left: 20%;
    border-radius: 12px;
    margin-top: 10px;
  }
}
@media only screen and (min-device-width: 361px) and (max-device-width: 375px) and (-webkit-min-device-pixel-ratio: 2) {
  .react-responsive-modal-modal {
    margin-top: 150px;
    border-radius: 12px;
    width: 80%;
  }
  .textAreamodal {
    height: 10vh;
    width: 70vw;
    margin-top: 15px;
  }
  .monthlymodalButton {
    margin-left: 20%;
    border-radius: 12px;
    margin-top: 10px;
  }
}
@media only screen and (min-device-width: 376px) and (max-device-width: 414px) and (-webkit-min-device-pixel-ratio: 2) {
  .react-responsive-modal-modal {
    margin-top: 145px;
    border-radius: 12px;
    width: 80%;
  }
  .textAreamodal {
    height: 10vh;
    width: 70vw;
    margin-top: 15px;
  }
  .monthlymodalButton {
    margin-left: 20%;
    border-radius: 12px;
    margin-top: 10px;
  }
}
@media only screen and (min-device-width: 415px) and (max-device-width: 768px) and (-webkit-min-device-pixel-ratio: 2) {
  .react-responsive-modal-modal {
    margin-top: 145px;
    border-radius: 12px;
    width: 80%;
  }
  .textAreamodal {
    height: 10vh;
    width: 70vw;
    margin-top: 15px;
  }
  .monthlymodalButton {
    margin-left: 20%;
    border-radius: 12px;
    margin-top: 10px;
  }
}






.add-task-header{
  margin: 10px 0px;
  border-bottom: 1px solid;
  padding-bottom: 8px;
  margin-bottom: 50px;
}
.add-task-header button{
  border-radius: 12px;
}
.add-task-header h4{
  margin-left: 30px;
}

.btn-nav-tabs-later{
  display: flex;
  padding: 0;
  border-style: groove;
  /* border-bottom: 1px solid; */
  margin-bottom: 50px;
}

.btn-nav-tabs-later li {
  padding: 10px;
  text-align: center;
  cursor: pointer;
  border-right: 1px solid;
  border-top: hidden;
  padding-right: 10%;
}
.all-categories-tab{
  margin-top: 20px;
  margin: 20px;
  
}

.btn-nav-tabs-later li, .all-categories-tab-later li{
  list-style-type: none;
}
.all-categories-li{
  background: white;
  color: black;
  margin-bottom: 5px;
  border-radius: 12px;
  padding: 5px 0 0 10px;
  margin-right:10px;
  margin-left:10px;
}

.all-categories-li h4{
  font-size: 22px;
}
  
  .category-list {
    width: calc(100% - 1px);
    height: 61px;
    background: #2F4050;
    margin-left: 1px;
    display: table;
  }
  .list-table {
    display: table-cell;
      color: white;
      vertical-align: middle;
  }
  .list-table:active{
    background : white;
  }
  .list-value {
    width: 100px;
    margin-left: 30px;
    font-size: 18px;
    text-align: center;
  }
  .list-value:hover{
    cursor: pointer;
    background-color: #293850;
  }
  .task-data{
    margin-top: 10px;
    margin-left: 10px;
  }
  .task-detail{
    background: white;
    color: black;
    margin-bottom: 5px;
    padding: 5px 0 0 10px;
    position: relative;
    height: 100px;
  }
  .task-detail ul{
    padding: 0;
  }
  .task-detail ul h4{
    margin: 0;
  }
  .select-button{
    position: absolute;
    top: 25%;
    left: 110%;
  }
  
  .task-date{
    position: absolute;
    top: 50px;
    left: 20%;
  }
  .project-name{
    position: absolute;
    top: 50px;
  }
  .mupp-path{
    margin-left: 115px;
  }
  .taskDetailbtn{
    position: absolute;
    top:20px;
    left:80%;
    
  }
  @media (min-width: 576px){
    .btn-nav-tabs-later li {
      width: 10%;
      display: inline-block;
    }
    .this-week-li {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .dis-flex{
      display: flex;
    }
    .inline-css {
      display: flex;
      height: 100%;
    }
    .add-task-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .inbox-tabs .inbox-tab-li{
      display: flex;
      align-items: baseline;
    }
    .test-align-right{
      text-align: right;
    }
  
  }
  @media (max-width: 575px){
    .all-categories-li,  .add-task-header, .inbox-tab-li{
      text-align: center;   
    }
  }
  
  /* Portrait and Landscape */
  @media only screen 
  and (min-device-width: 240px) 
  and (max-device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .all-categories-tab{
      margin-top: -21px;
      float: right;
      width: 337px;
    }
    .all-categories-li {
      background: white;
      color: black;
      margin-bottom: 5px;
      border-radius: 12px;
      padding: 5px 0 0 10px;
      margin-right: 10px;
      margin-left: 10px;
      width: 322px;
  }
  .btn-nav-tabs-later{
    padding: 0; 
    margin-bottom: 50px;
    display: block;
  }
  .btn-nav-tabs-later li {
    padding: 10px;
    text-align: center;
    cursor: pointer; 
  }

}
  @media only screen 
    and (min-device-width: 321px) 
    and (max-device-width: 360px)
    and (-webkit-min-device-pixel-ratio: 2) {
      .all-categories-tab{
        margin-top: 20px;
        float: right;
        width: 377px;
      }
      .all-categories-li {
        background: white;
        color: black;
        margin-bottom: 5px;
        border-radius: 12px;
        padding: 5px 0 0 10px;
        margin-right: 10px;
        margin-left: 10px;
        width: 362px;
    }
    .btn-nav-tabs-later{
      padding: 0; 
      margin-bottom: 50px;
      display: block;
    }
    .btn-nav-tabs-later li {
      padding: 10px;
      text-align: center;
      cursor: pointer; 
    }
  }
  @media only screen 
  and (min-device-width: 361px) 
  and (max-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .all-categories-tab{
      margin-top: 20px;
      float: right;
      width: 391px;
    }
    .all-categories-li {
      background: white;
      color: black;
      margin-bottom: 5px;
      border-radius: 12px;
      padding: 5px 0 0 10px;
      margin-right: 10px;
      margin-left: 10px;
      width: 372px;
  }
  .btn-nav-tabs-later{
    padding: 0; 
    margin-bottom: 50px;
    display: block;

  } .btn-nav-tabs li {
    padding: 10px;
    text-align: center;
    cursor: pointer; 
  }

}
  @media only screen 
  and (min-device-width: 376px) 
  and (max-device-width: 414px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .all-categories-tab{
      margin-top: 20px;
      float: right;
      width: 426px;
    }
    .all-categories-li {
      background: white;
      color: black;
      margin-bottom: 5px;
      border-radius: 12px;
      padding: 5px 0 0 10px;
      margin-right: 10px;
      margin-left: 10px;
      width: 412px;
  }
  .btn-nav-tabs-later{
    padding: 0; 
    margin-bottom: 50px;
    display: block;

  }
  .btn-nav-tabs-later li {
    padding: 10px;
    text-align: center;
    cursor: pointer; 
  }
}
@media only screen 
and (min-device-width: 415px) 
and (max-device-width: 768px)
and (-webkit-min-device-pixel-ratio: 2) {
  .all-categories-tab{
    margin-top: 20px;
    float: right;
    width: 564px;
  }
  .btn-nav-tabs-later{
    padding: 0; 
    margin-bottom: 50px;
    display: block;

  }
  .btn-nav-tabs-later li {
    padding: 10px;
    text-align: center;
    cursor: pointer; 
  }
}

.react-responsive-modal-modal {
    margin-top: 145px;
    border-radius: 12px;
}
.react-responsive-modal-closeButton{
    top:2px;
}
.textAreamodal{
  margin-top: 15px;
  height:10vh; 
  width: 50vw
}
.monthlymodalButton{
  margin-left: 20%;
   border-radius: 12px;
   margin-top:10px;
}



 /* Portrait and Landscape */
 @media only screen 
 and (min-device-width: 240px) 
 and (max-device-width: 320px)
 and (-webkit-min-device-pixel-ratio: 2) {
  .react-responsive-modal-modal {
    margin-top: 145px;
    border-radius: 12px;
    width: 80%;
}
   
.textAreamodal{
  height:10vh; 
  width: 70vw;
  margin-top: 15px;
}
.monthlymodalButton{

  margin-left: 20%;
   border-radius: 12px;
   margin-top: 10px;
}
  
    
  
 

}
 @media only screen 
   and (min-device-width: 321px) 
   and (max-device-width: 360px)
   and (-webkit-min-device-pixel-ratio: 2) {
    .react-responsive-modal-modal {
      margin-top: 145px;
      border-radius: 12px;
      width: 80%;
  }
  .textAreamodal{
    height:10vh; 
    width: 70vw;
    margin-top: 15px;
  }
  .monthlymodalButton{
  
    margin-left: 20%;
     border-radius: 12px;
     margin-top: 10px;
  }
    
      
      
    
 }
 @media only screen 
 and (min-device-width: 361px) 
 and (max-device-width: 375px)
 and (-webkit-min-device-pixel-ratio: 2) {
  .react-responsive-modal-modal {
    margin-top: 150px;
    border-radius: 12px;
    width: 80%;
}
.textAreamodal{
  height:10vh; 
  width: 70vw;
  margin-top: 15px;
}
.monthlymodalButton{

  margin-left: 20%;
   border-radius: 12px;
   margin-top: 10px;
}
  
  
}
 @media only screen 
 and (min-device-width: 376px) 
 and (max-device-width: 414px)
 and (-webkit-min-device-pixel-ratio: 2) {
  .react-responsive-modal-modal {
    margin-top: 145px;
    border-radius: 12px;
    width:80%;
}
.textAreamodal{
  height:10vh; 
  width: 70vw;
  margin-top: 15px;
}
.monthlymodalButton{

  margin-left: 20%;
   border-radius: 12px;
   margin-top: 10px;
}
  
  
}
@media only screen 
and (min-device-width: 415px) 
and (max-device-width: 768px)
and (-webkit-min-device-pixel-ratio: 2) {
  .react-responsive-modal-modal {
    margin-top: 145px;
    border-radius: 12px;
    width: 80%;
}
.textAreamodal{
  height:10vh; 
  width: 70vw;
  margin-top: 15px;
}
.monthlymodalButton{

  margin-left: 20%;
   border-radius: 12px;
   margin-top: 10px;
}
  
  

}
.Page4-container{
    min-height: 100vh;
    display: flex;
    justify-content: center;
    overflow-y: auto;
}
a:hover {
    text-decoration: none !important;
}
.Page4-childContainer{
    /* height: 100%; */
    width: 50%;
    position: relative;
}
.Page4-childContainer.childContainer{
    height: 100;
}
/* #childContainer1{
    height: 80%;
    width: 90%;
    position:absolute;
    left: 5%;
    top: 35%;
} */
#childContainer1 a:hover {
    color: white !important;
}
.page4-title{
    color: white;
    font-size: 18px;
}
.review-project {
    font-size: 18px;
}
.imgContainer{
    position: absolute;
     left: 15%;
     top: 5% 
}
#PageImage{
    height:150px;
     width:350px
}


 
  /* Portrait and Landscape */
  @media only screen 
  and (min-device-width: 240px) 
  and (max-device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .Page4-childContainer{
        width: 100%;

        
    }
   
    #PageImage{
        height: 150px;
        width: 290px;
    }
    .imgContainer{
        left: 0;
        top:0

    }

}
  @media only screen 
    and (min-device-width: 321px) 
    and (max-device-width: 360px)
    and (-webkit-min-device-pixel-ratio: 2) {
        .Page4-childContainer{
            width: 100%;
        }
       
        #PageImage{
            height: 150px;
            width: 290px;
        }
        .imgContainer{
            left:5%;
        }
     
  }
  @media only screen 
  and (min-device-width: 361px) 
  and (max-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .Page4-childContainer{
        width: 100%;
    }
 
    #PageImage{
        height: 250px;
        width: 300px;
    }
    .imgContainer{
        left:10%;
    }
   
}
  @media only screen 
  and (min-device-width: 376px) 
  and (max-device-width: 414px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .Page4-childContainer{
        width: 100%;
    }
   
    #PageImage{
        height: 200px;
        width: 350px;
    }
    .imgContainer{
        left:5%;
    }
}
@media only screen 
and (min-device-width: 415px) 
and (max-device-width: 768px)
and (-webkit-min-device-pixel-ratio: 2) {
    .Page4-childContainer{
        width: 100%;
    }
  
     #PageImage{
        margin-left: 40%;
        height: 150px;
        width: 290px;
    } 

}
.scienceText{
    margin-bottom: 25px; 
    }

.scienceText:hover{
    
        border-color:blue;
        color:blue;
     
     
}

.completedBtn{
    border-radius: 30px;

}

.scienceText p{
    width: 150px;
    text-decoration: none;
    display: inline-block;
    margin: 0 10px;
    padding:20px 0;
    color: #fff;
    z-index: 1;
    background-color:rgb(51, 50, 50);
    border-radius: 30px;
    font-size: 24px;
    font-weight: 400;
}
.SelectedWeekExample .DayPicker-Month {
    border-collapse: separate;
  }
  .SelectedWeekExample .DayPicker-WeekNumber {
    outline: none;
  }
  .SelectedWeekExample .DayPicker-Day {
    outline: none;
    border: 1px solid transparent;
  }
  .SelectedWeekExample .DayPicker-Day--hoverRange {
    background-color: red !important;
  } 
  .calenderTaskli{
    border: 1px solid;
    padding: 10px;
    border-radius: 20px;
    background: white;
    color: black;
    margin-bottom: 10px;
}
  .SelectedWeekExample .DayPicker-Day--selectedRange {
    background-color: #fff7ba !important;
    border-top-color: #FFEB3B;
    border-bottom-color: #FFEB3B;
    border-left-color: #fff7ba;
    border-right-color: #fff7ba;
  }

  .SelectedWeekExample .DayPicker-Day--selectedRangeStart {
    background-color: #FFEB3B !important;
    border-left: 1px solid #FFEB3B;
  }

  .SelectedWeekExample .DayPicker-Day--selectedRangeEnd {
    background-color: #FFEB3B !important;
    border-right: 1px solid #FFEB3B;
  }

  .SelectedWeekExample .DayPicker-Day--selectedRange:not(.DayPicker-Day--outside).DayPicker-Day--selected,
  .SelectedWeekExample .DayPicker-Day--hoverRange:not(.DayPicker-Day--outside).DayPicker-Day--selected {
    border-radius: 0 !important;
    color: black !important;
  }
  .SelectedWeekExample .DayPicker-Day--hoverRange:hover {
    border-radius: 0 !important;
  }
  .btn-nav-tabs{
    padding: 0;
    /* border-bottom: 1px solid; */
    margin-bottom: 50px;
  }
  
  .btn-nav-tabs li {
    padding: 10px;
    text-align: center;
    cursor: pointer;
    border-right: 1px solid;
  }
  .btnRem{
    margin-top: 5px;
    margin-right:10px;
    padding: 10px;
  }
  .btnTask{
    margin-top:10px;
    padding: 10px 20px;
  }
  @media only screen 
  and (min-device-width: 240px) 
  and (max-device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
    
    .btn-nav-tabs{
      padding: 0;
      /* border-bottom: 1px solid; */
      margin-bottom: 50px;
    }
    
    .btn-nav-tabs li {
      padding: 10px;
      text-align: center;
      cursor: pointer;
      /* border-right: 1px solid; */
    }
}
  @media only screen 
    and (min-device-width: 321px) 
    and (max-device-width: 360px)
    and (-webkit-min-device-pixel-ratio: 2) {
       
      .btn-nav-tabs{
        padding: 0;
        /* border-bottom: 1px solid; */
        margin-bottom: 50px;
      }
      
      .btn-nav-tabs li {
        padding: 10px;
        text-align: center;
        cursor: pointer;
        /* border-right: 1px solid; */
      }
  }
  @media only screen 
  and (min-device-width: 361px) 
  and (max-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .btn-nav-tabs{
      padding: 0;
      /* border-bottom: 1px solid; */
      margin-bottom: 50px;
    }
    
    .btn-nav-tabs li {
      padding: 10px;
      text-align: center;
      cursor: pointer;
      /* border-right: 1px solid; */
    }

}
  @media only screen 
  and (min-device-width: 376px) 
  and (max-device-width: 414px)
  and (-webkit-min-device-pixel-ratio: 2) {
   
    .btn-nav-tabs{
      padding: 0;
      /* border-bottom: 1px solid; */
      margin-bottom: 50px;
    }
    
    .btn-nav-tabs li {
      padding: 10px;
      text-align: center;
      cursor: pointer;
      /* border-right: 1px solid; */
    }
}
@media only screen 
and (min-device-width: 415px) 
and (max-device-width: 768px)
and (-webkit-min-device-pixel-ratio: 2) {
  
  .btn-nav-tabs{
    padding: 0;
    /* border-bottom: 1px solid; */
    margin-bottom: 50px;
  }
  
  .btn-nav-tabs li {
    padding: 10px;
    text-align: center;
    cursor: pointer;
    /* border-right: 1px solid; */
  }
}

.editmodalText{
    height: 15%;
     width: 100%;
      margin-top: 1%;
       margin-bottom: 5%


}
.privacy{
    margin-top: 20px;
}
.tableContent{
    font-size: 15px;
}
.capital{
    text-transform: uppercase;
}

.select-mupp-checkbox{
width: 25px;
height: 25px;
cursor: pointer;
}
.muppOrderDiv{
    padding:0 !important;
}
.muppOrderTable{
    position: sticky;
    z-index: 9999;
    background-color: white;
    color: black;  
    margin-top: 10px;
}
.terms{
    margin-top: 20px;
}


.react-responsive-modal-modal {
  margin-top: 145px;
  border-radius: 12px;
}
.react-responsive-modal-closeButton {
  top: 2px;
}
.textAreamodal {
  margin-top: 15px;
  height: 10vh;
  width: 50vw;
}
.log__monthlymodalButton {
  /* margin-left: 30%; */
  margin-left: 0;
  border-radius: 12px;
  margin-top: 2vh;
  width: 100%;
}

.react-quill {
  color: black;
}
/* Portrait and Landscape */
@media only screen and (min-device-width: 240px) and (max-device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
  .react-responsive-modal-modal {
    margin-top: 145px;
    border-radius: 12px;
    width: 80%;
  }

  .textAreamodal {
    height: 10vh;
    width: 70vw;
    margin-top: 15px;
  }
  .monthlymodalButton {
    margin-left: 20%;
    border-radius: 12px;
    margin-top: 10px;
  }
}
@media only screen and (min-device-width: 321px) and (max-device-width: 360px) and (-webkit-min-device-pixel-ratio: 2) {
  .react-responsive-modal-modal {
    margin-top: 145px;
    border-radius: 12px;
    width: 80%;
  }
  .textAreamodal {
    height: 10vh;
    width: 70vw;
    margin-top: 15px;
  }
  .monthlymodalButton {
    margin-left: 20%;
    border-radius: 12px;
    margin-top: 10px;
  }
}
@media only screen and (min-device-width: 361px) and (max-device-width: 375px) and (-webkit-min-device-pixel-ratio: 2) {
  .react-responsive-modal-modal {
    margin-top: 150px;
    border-radius: 12px;
    width: 80%;
  }
  .textAreamodal {
    height: 10vh;
    width: 70vw;
    margin-top: 15px;
  }
  .monthlymodalButton {
    margin-left: 20%;
    border-radius: 12px;
    margin-top: 10px;
  }
}
@media only screen and (min-device-width: 376px) and (max-device-width: 414px) and (-webkit-min-device-pixel-ratio: 2) {
  .react-responsive-modal-modal {
    margin-top: 145px;
    border-radius: 12px;
    width: 80%;
  }
  .textAreamodal {
    height: 10vh;
    width: 70vw;
    margin-top: 15px;
  }
  .monthlymodalButton {
    margin-left: 20%;
    border-radius: 12px;
    margin-top: 10px;
  }
}
@media only screen and (min-device-width: 415px) and (max-device-width: 768px) and (-webkit-min-device-pixel-ratio: 2) {
  .react-responsive-modal-modal {
    margin-top: 145px;
    border-radius: 12px;
    width: 80%;
  }
  .textAreamodal {
    height: 10vh;
    width: 70vw;
    margin-top: 15px;
  }
  .monthlymodalButton {
    margin-left: 20%;
    border-radius: 12px;
    margin-top: 10px;
  }
}

h3 {
  text-align: center;
}

/* body {
    background-color: rgb(25, 25, 35);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-family: 'Montserrat', sans-serif;
	height: 100vh;
	margin: -20px 0 50px;
} */

.journal_parent {
  /* height: 400px; */
  /* width: 400px; */
  border-radius: 4px;
  position: relative;
  background-color: transparent;
  transition: 0.3s all;
  display: flex;
  justify-content: center;
  align-items: center;
}

.journal_child {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px 50px;
  height: 100%;
  width: 400px;
  text-align: center;
}

.journal_parent input {
  position: relative;
  padding: 0px 16px;
  border: none;
  border-radius: 4px;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  background-color: transparent;
  color: #e6dcdc;
  outline: none;
  box-shadow: 0px 4px 20px 0px transparent;
  transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out,
    0.1s padding ease-in-out;
  -webkit-appearance: none;
  background: white;
}

.journal_parent input {
  background-color: rgb(48, 50, 64);
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}

.journal_button {
  border-radius: 20px;
  border: 1px solid white;
  background-color: black;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: -webkit-transform 80ms ease-in;
  transition: transform 80ms ease-in;
  transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
}

#h6 {
  color: white;
}

@media screen and (min-width: 250px) and (max-width: 750px) {
  #btn {
    float: right;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-right: 45px;
  }
  .test-container {
    padding: 0 0;
  }
  .testChild {
    height: 50vh;
  }
}

.menuVertical {
  margin-left: 2rem;
}

.menuVertical_dropdown {
  font-size: 1.3rem;
  background-color: #e6dcdc;
  padding: 0.7rem;
  border-radius: 5px;
  color: black;
  position: fixed;
  z-index: 20;
  cursor: pointer;
  /* width: fit-content; */
}

/* Portrait and Landscape */
/* @media only screen and (min-device-width: 240px) and (max-device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
  .journal_child {
    margin-left: -80%;
  }
}
@media only screen and (min-device-width: 321px) and (max-device-width: 360px) and (-webkit-min-device-pixel-ratio: 2) {
  .journal_child {
    margin-left: -75%;
  }
}
@media only screen and (min-device-width: 361px) and (max-device-width: 375px) and (-webkit-min-device-pixel-ratio: 2) {
  .journal_child {
    margin-left: -75%;
  }
}
@media only screen and (min-device-width: 376px) and (max-device-width: 414px) and (-webkit-min-device-pixel-ratio: 2) {
  .journal_child {
    margin-left: -70%;
  }
}
@media only screen and (min-device-width: 415px) and (max-device-width: 768px) and (-webkit-min-device-pixel-ratio: 2) {
  .journal_child {
    margin-left: -30%;
  }
} */

.react-responsive-modal-modal {
  margin-top: 145px;
  border-radius: 12px;
}
.react-responsive-modal-closeButton {
  top: 2px;
}
.textAreamodal {
  margin-top: 15px;
  height: 10vh;
  width: 50vw;
}
.monthlymodalButton {
  margin-left: 20%;
  border-radius: 12px;
  margin-top: 10px;
}

.react-quill {
  height: 35vh;
}

/* Portrait and Landscape */
@media only screen and (min-device-width: 240px) and (max-device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
  .react-responsive-modal-modal {
    margin-top: 145px;
    border-radius: 12px;
    width: 80%;
  }

  .textAreamodal {
    height: 10vh;
    width: 70vw;
    margin-top: 15px;
  }
  .monthlymodalButton {
    margin-left: 20%;
    border-radius: 12px;
    margin-top: 10px;
  }
}
@media only screen and (min-device-width: 321px) and (max-device-width: 360px) and (-webkit-min-device-pixel-ratio: 2) {
  .react-responsive-modal-modal {
    margin-top: 145px;
    border-radius: 12px;
    width: 80%;
  }
  .textAreamodal {
    height: 10vh;
    width: 70vw;
    margin-top: 15px;
  }
  .monthlymodalButton {
    margin-left: 20%;
    border-radius: 12px;
    margin-top: 10px;
  }
}
@media only screen and (min-device-width: 361px) and (max-device-width: 375px) and (-webkit-min-device-pixel-ratio: 2) {
  .react-responsive-modal-modal {
    margin-top: 150px;
    border-radius: 12px;
    width: 80%;
  }
  .textAreamodal {
    height: 10vh;
    width: 70vw;
    margin-top: 15px;
  }
  .monthlymodalButton {
    margin-left: 20%;
    border-radius: 12px;
    margin-top: 10px;
  }
}
@media only screen and (min-device-width: 376px) and (max-device-width: 414px) and (-webkit-min-device-pixel-ratio: 2) {
  .react-responsive-modal-modal {
    margin-top: 145px;
    border-radius: 12px;
    width: 80%;
  }
  .textAreamodal {
    height: 10vh;
    width: 70vw;
    margin-top: 15px;
  }
  .monthlymodalButton {
    margin-left: 20%;
    border-radius: 12px;
    margin-top: 10px;
  }
}
@media only screen and (min-device-width: 415px) and (max-device-width: 768px) and (-webkit-min-device-pixel-ratio: 2) {
  .react-responsive-modal-modal {
    margin-top: 145px;
    border-radius: 12px;
    width: 80%;
  }
  .textAreamodal {
    height: 10vh;
    width: 70vw;
    margin-top: 15px;
  }
  .monthlymodalButton {
    margin-left: 20%;
    border-radius: 12px;
    margin-top: 10px;
  }
}

