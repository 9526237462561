body {
    margin: unset !important;
  }
  .App {
    margin: unset !important;
    padding: unset !important;
  }
  
  .sidebar{
    height: 100%;
    width: 180px;
    background-color: #2F4050;
  }
  .wrapper {
    position: absolute;
    top: 0;
    
    height: 100vh;
    width: 100%;
    background: #2F4050;
    display: table;
  }
  
  .category-list {
    width: calc(100% - 1px);
    height: 61px;
    background: #2F4050;
    margin-left: 1px;
    display: table;
  }
  .list-table {
    display: table-cell;
      color: white;
      vertical-align: middle;
  }
  .list-table:active{
    background : white;
  }
  .list-value {
    width: 100px;
    margin-left: 30px;
    font-size: 18px;
    text-align: center;
  }
  .list-value:hover{
    cursor: pointer;
    background-color: #293850;
  }
  .select-button{
    position: absolute;
    top: 25%;
    left: 110%;
  }
  
  .task-date{
    position: absolute;
    top: 50px;
    left: 20%;
  }
  .project-name{
    position: absolute;
    top: 50px;
  }
  .mupp-path{
    margin-left: 115px;
  }
  .taskDetailbtn{
    position: absolute;
    top:20px;
    left:80%;
    
  }
  /* .list-tabs{
    height: 50px;
    width:150px;
    background:black;
    margin-top: 10px;
    border-radius: 10px;
    font-weight: 400;
    padding-top: 15px;
  }
   */
  
  .inactive{
    /* height: 50px;
    width:150px;
    background:blue;
    margin-top: 10px;
    border-radius: 10px;
    font-weight: 400;
    padding-top: 15px; */
  }

  .btn-nav-tabs-awaiting{
    display: flex;
    padding: 0;
    border-style: groove;
    /* border-bottom: 1px solid; */
  }
  
  .btn-nav-tabs-awaiting li {
    padding: 10px;
    text-align: center;
    cursor: pointer;
    border-right: 1px solid;
    border-top: hidden;
    padding-right: 10%;
  }
  
  .btn-nav-tabs-awaiting li, .all-categories-tab-awaiting li{
    list-style-type: none;
  }
  
  @media (min-width: 576px){
    .btn-nav-tabs-awaiting li {
      width: 10%;
      display: inline-block;
    }
    .this-week-li {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .dis-flex{
      display: flex;
    }
    .inline-css {
      display: flex;
      height: 100%;
    }
    .add-task-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .inbox-tabs .inbox-tab-li{
      display: flex;
      align-items: baseline;
    }
    .test-align-right{
      text-align: right;
    }
  
  }
  @media (max-width: 575px){
    .all-categories-li,  .add-task-header, .inbox-tab-li{
      text-align: center;   
    }
  }
 
  /* Portrait and Landscape */
  @media only screen 
  and (min-device-width: 240px) 
  and (max-device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .all-categories-tab{
      margin-top: -21px;
      float: right;
      width: 337px;
    }
    .all-categories-li {
      background: white;
      color: black;
      margin-bottom: 5px;
      border-radius: 12px;
      padding: 5px 0 0 10px;
      margin-right: 10px;
      margin-left: 10px;
      width: 322px;
  }
  .btn-nav-tabs-awaiting{
    padding: 0; 
    margin-bottom: 50px;
    display: block;
  }
  .btn-nav-tabs-awaiting li {
    padding: 10px;
    text-align: center;
    cursor: pointer; 
  }
  
  }
  @media only screen 
    and (min-device-width: 321px) 
    and (max-device-width: 360px)
    and (-webkit-min-device-pixel-ratio: 2) {

      
      .all-categories-tab{
        margin-top: 20px;
        float: right;
        width: 377px;
      }
      .all-categories-li {
        background: white;
        color: black;
        margin-bottom: 5px;
        border-radius: 12px;
        padding: 5px 0 0 10px;
        margin-right: 10px;
        margin-left: 10px;
        width: 362px;
    }
    .btn-nav-tabs-awaiting{
      padding: 0; 
      margin-bottom: 50px;
      display: block;
    }
    .btn-nav-tabs-awaiting li {
      padding: 10px;
      text-align: center;
      cursor: pointer; 
    }
  }
  @media only screen 
  and (min-device-width: 361px) 
  and (max-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .all-categories-tab{
      margin-top: 20px;
      float: right;
      width: 391px;
    }
    .all-categories-li {
      background: white;
      color: black;
      margin-bottom: 5px;
      border-radius: 12px;
      padding: 5px 0 0 10px;
      margin-right: 10px;
      margin-left: 10px;
      width: 372px;
  }
  .btn-nav-tabs-awaiting{
    padding: 0; 
    margin-bottom: 50px;
    display: block;
  }
  } .btn-nav-tabs li {
    padding: 10px;
    text-align: center;
    cursor: pointer; 
  }
  
  
  @media only screen 
  and (min-device-width: 376px) 
  and (max-device-width: 414px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .all-categories-tab{
      margin-top: 20px;
      float: right;
      width: 426px;
    }
    .all-categories-li {
      background: white;
      color: black;
      margin-bottom: 5px;
      border-radius: 12px;
      padding: 5px 0 0 10px;
      margin-right: 10px;
      margin-left: 10px;
      width: 412px;
  }
  .btn-nav-tabs-awaiting{
    padding: 0; 
    margin-bottom: 50px;
    display: block;
  }
  .btn-nav-tabs-awaiting li {
    padding: 10px;
    text-align: center;
    cursor: pointer; 
  }
  }
  @media only screen 
  and (min-device-width: 415px) 
  and (max-device-width: 768px)
  and (-webkit-min-device-pixel-ratio: 2) {
  .all-categories-tab{
    margin-top: 20px;
    float: right;
    width: 564px;
  }
  .btn-nav-tabs-awaiting{
    padding: 0; 
    margin-bottom: 50px;
    display: block;
  }
  .btn-nav-tabs-awaiting li {
    padding: 10px;
    text-align: center;
    cursor: pointer; 
  }
  }
  