
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');

* {
	box-sizing: border-box;
}
/* 
body  {
    background-color: rgb(25, 25, 35);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-family: 'Montserrat', sans-serif;
	height: 100vh;
	margin: -20px 0 50px;
} */
.imageSignup{
	justify-content: center;
	width:400px
}
h1 {
	font-weight: bold;
	margin: 0;
}

h2 {
	text-align: center;
}

p {
	font-size: 14px;
	font-weight: 100;
	line-height: 20px;
	letter-spacing: 0.5px;
	margin: 20px 0 30px;
}

span {
	font-size: 12px;
}

a {
	color:#007bff;
	font-size: 14px;
	text-decoration: none;
	margin: 15px 0;
}

button {
	border-radius: 20px;
	border: 1px solid white;
	background-color:black;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
    transition: transform 80ms ease-in;
    margin-top: 10px;
}

button:active {
	transform: scale(0.95);
}

button:focus {
	outline: none;
}

button.ghost {
	background-color: transparent;
	border-color: #FFFFFF;
}

form {
	background-color:transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 50px 50px;
    height: 100%;
    width: 400px;
	text-align: center;
	
}
.btn-signup{
	margin-top: 20px;
	border-radius: 5px;
}

.container input {
	background-color:rgb(48, 50, 64);
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 100%;
}

.container {
	background-color:transparent;
	
	position: relative;
	overflow: hidden;
	/* width: 400px;
	max-width: 100%; 
	min-height: 480px;*/
}

.form-container {
	position: absolute;
	top: 0;
	height: 100%;
	transition: all 0.6s ease-in-out;
}

.sign-in-container {
	left: 0;
	width: 50%;
	z-index: 2;
	
}

.container.right-panel-active .sign-in-container {
	transform: translateX(100%);
}

.sign-up-container {
	left: 0;
	width: 50%;
	opacity: 0;
	z-index: 1;
}

.container.right-panel-active .sign-up-container {
	transform: translateX(100%);
	opacity: 1;
	z-index: 5;
	animation: show 0.6s;
}

@keyframes show {
	0%, 49.99% {
		opacity: 0;
		z-index: 1;
	}
	
	50%, 100% {
		opacity: 1;
		z-index: 5;
	}
}

.overlay-container {
	position: absolute;
	top: 0;
	left: 50%;
	width: 50%;
	height: 100%;
	overflow: hidden;
	transition: transform 0.6s ease-in-out;
	z-index: 100;
}

.container.right-panel-active .overlay-container{
	transform: translateX(-100%);
}

.overlay {
	background: #FF416C;
	background: -webkit-linear-gradient(to right, #FF4B2B, #FF416C);
	background: linear-gradient(to right, #FF4B2B, #FF416C);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0;
	color: #FFFFFF;
	position: relative;
	left: -100%;
	height: 100%;
	width: 200%;
  	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}

.container.right-panel-active .overlay {
  	transform: translateX(50%);
}

.overlay-panel {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 40px;
	text-align: center;
	top: 0;
	height: 100%;
	width: 50%;
	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}

.overlay-left {
	transform: translateX(-20%);
}

.container.right-panel-active .overlay-left {
	transform: translateX(0);
}

.overlay-right {
	right: 0;
	transform: translateX(0);
}

.container.right-panel-active .overlay-right {
	transform: translateX(20%);
}

.social-container {
	margin: 20px 0;
}

.loginform input {
	background-color:rgb(48, 50, 64);
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 380px;
	color: white;
}

.social-container a {
	border: 1px solid #DDDDDD;
	border-radius: 50%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin: 0 5px;
	height: 40px;
	width: 40px;
}

.signImgContainer {
	padding-bottom:10px ;
	margin-left: 5px;

}

.errorMsg{
	color: red;
}

.user{
	color: white;
}

.signImage{
	height:200px;
	 width:380px
}

@media screen and (min-width: 250px) and (max-width: 750px)  {
	.imageSignup{
		justify-content: center;
		width:205px
	}
	form {
		display: inline-grid;
		align-items: center;
		
		padding: unset;
		height: 100%;
		width: -webkit-fill-available;
		text-align: center;
		margin-left: 30px;
	}
}

 /* Portrait and Landscape */
 @media only screen 
 and (min-device-width: 240px) 
 and (max-device-width: 320px)
 and (-webkit-min-device-pixel-ratio: 2) {
	.signImage{
		height:150px;
		 width:240px
	}
	.signImgContainer {
		padding-bottom:10px ;
		margin-right: 20%;
	}

.loginform input {
	background-color:rgb(48, 50, 64);
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 260px;
	color: white;
}
  

}
 @media only screen 
   and (min-device-width: 321px) 
   and (max-device-width: 360px)
   and (-webkit-min-device-pixel-ratio: 2) {
	.signImage{
		height:150px;
		 width:270px
	}
	.signImgContainer {
		padding-bottom:10px ;
		margin-right: 5%;
	}

.loginform input {
	background-color:rgb(48, 50, 64);
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 300px;
	color: white;
}

	   
	
 }
 @media only screen 
 and (min-device-width: 361px) 
 and (max-device-width: 375px)
 and (-webkit-min-device-pixel-ratio: 2) {
	.signImage{
		height:150px;
		 width:270px
	}
	.signImgContainer {
		padding-bottom:10px ;
		margin-right: 20%;
	}

.loginform input {
	background-color:rgb(48, 50, 64);
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 300px;
	color: white;
}
  
  
}
 @media only screen 
 and (min-device-width: 376px) 
 and (max-device-width: 414px)
 and (-webkit-min-device-pixel-ratio: 2) {
	.signImage{
		height:150px;
		 width:270px
	}
	.signImgContainer {
		padding-bottom:10px ;
		margin-right: 2%;
	}

.loginform input {
	background-color:rgb(48, 50, 64);
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 300px;
	color: white;
}
  
}
@media only screen 
and (min-device-width: 415px) 
and (max-device-width: 768px)
and (-webkit-min-device-pixel-ratio: 2) {
	.signImage{
		height:150px;
		 width:270px
	}
	.signImgContainer {
		padding-bottom:10px ;
		margin-right: 20%;
	}

.loginform input {
	background-color:rgb(48, 50, 64);
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 300px;
	color: white;
}
  

}