
h3{
    text-align: center;
}
/* 
body {
    background-color: rgb(25, 25, 35);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-family: 'Montserrat', sans-serif;
	height: 100vh;
	margin: -20px 0 50px;
} */

.parent{
    height: 400px;
    width: 400px;
    border-radius: 4px;
    position: relative;
    background-color: transparent;
    transition: 0.3s all;
    display: flex;
    justify-content:center;
    align-items: center;
}

.child{
    background-color:transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 50px 50px;
    height: 100%;
    width: 400px;
    text-align: center;
}
.child input{
    color:white !important;
}
.child input::placeholder{
	color: white;
}

.parent input {
    position: relative;
    padding: 0px 16px;
    border: none;
    border-radius: 4px;
    font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    background-color: transparent;
    color: #282828;
    outline: none;
    box-shadow: 0px 4px 20px 0px transparent;
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out, 0.1s padding ease-in-out;
    -webkit-appearance: none;
    background:white;
    
  }
  
.parent input{
    background-color:rgb(48, 50, 64);
    border: none;
    padding: 12px 15px;
    margin: 8px 0;
    width: 100%;
    
}


#btn1 {
	border-radius: 15px;
	border: 1px solid white;
	background-color:black;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
    transition: transform 80ms ease-in;
    margin-top: 10px;
}

#h6{
    color:white
}