.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }
  
  .modal-main {
    position:fixed;
    background: white;
    width: 80%;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
  }
  
  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }
  .modalContainer{
    height: 10vh;
     width: 50vw
  }

  .modalButton{
    margin-left: 40%;
     border-radius: 12px

  }

  
  /* Portrait and Landscape */
  @media only screen 
  and (min-device-width: 240px) 
  and (max-device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .modalContainer{
      height: 10vh;
       width: 70vw;
       margin-top: 15px;
    }
    .modalButton{
      margin-left: 25%;
       border-radius: 12px
  
    }
   
}
  @media only screen 
    and (min-device-width: 321px) 
    and (max-device-width: 360px)
    and (-webkit-min-device-pixel-ratio: 2) {
      .modalContainer{
        height: 10vh;
         width: 70vw;
         margin-top: 15px;
      }
      .modalButton{
        margin-left: 25%;
         border-radius: 12px
    
      }
      
    
  }
  @media only screen 
  and (min-device-width: 361px) 
  and (max-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .modalContainer{
      height: 10vh;
       width: 70vw;
       margin-top: 15px;
    }
    .modalButton{
      margin-left: 25%;
       border-radius: 12px
  
    }
    
  

}
  @media only screen 
  and (min-device-width: 376px) 
  and (max-device-width: 414px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .modalContainer{
      height: 10vh;
       width: 70vw;
       margin-top: 15px;
    }
    .modalButton{
      margin-left: 25%;
       border-radius: 12px
  
    }
   
}
@media only screen 
and (min-device-width: 415px) 
and (max-device-width: 768px)
and (-webkit-min-device-pixel-ratio: 2) {
  .modalContainer{
    height: 10vh;
     width: 70vw;
     margin-top: 15px;
  }
  .modalButton{
    margin-left: 25%;
     border-radius: 12px

  }
 
}
