.this-week-li{
  padding: 8px !important;
  margin-top: 20px;
}
.this-week-li label{
    margin-right: 2em;
}
.this-week-li button{
  border-radius: 12px;
  margin-right: 30px;
}
.category-list {
  width: calc(100% - 1px);
  height: 61px;
  background: #2F4050;
  margin-left: 1px;
  display: table;
}
.list-table {
  display: table-cell;
    color: white;
    vertical-align: middle;
}
.list-table:active{
 background: white;
}
.list-value {
  width: 100px;
  margin-left: 30px;
  font-size: 18px;
  text-align: center;
}
.list-value:hover{
  cursor: pointer;
  background-color: #293850;
}
.select-button{
  position: absolute;
  top: 25%;
  left: 110%;
}

.task-date{
  position: absolute;
  top: 50px;
  left: 20%;
}
.project-name{
  position: absolute;
  top: 50px;
}
.mupp-path{
  margin-left: 115px;
  white-space: nowrap; 
  width: 100px; 
  overflow: hidden;
  text-overflow: ellipsis;
}
.mupp-path:hover{
  overflow: visible;

}
.taskDetailbtn{
  position: absolute;
  top:20px;
  left:80%;
  
}

.elipse-text-for-title{
  width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}



.elipse-text{
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn-nav-tabs-thisweek{
  display: flex;
  padding: 0;
  border-style: groove;
  /* border-bottom: 1px solid; */
  margin-bottom: 50px;
}

.btn-nav-tabs-thisweek li {
  padding: 10px;
  text-align: center;
  cursor: pointer;
  border-right: 1px solid;
  border-top: hidden;
  padding-right: 10%;
}

.btn-nav-tabs-thisweek li, .all-categories-tab-thisweek li{
  list-style-type: none;
}

@media (min-width: 576px){
  .btn-nav-tabs-thisweek li {
    width: 10%;
    display: inline-block;
  }
  .this-week-li {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .dis-flex{
    display: flex;
  }
  .inline-css {
    display: flex;
    height: 100%;
  }
  .add-task-header{
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
  .inbox-tabs .inbox-tab-li{
    display: flex;
    align-items: baseline;
  }
  .test-align-right{
    text-align: right;
  }

}
@media (max-width: 575px){
  .all-categories-li,  .add-task-header, .inbox-tab-li{
    text-align: center;   
  }
}

/* Portrait and Landscape */
@media only screen 
and (min-device-width: 240px) 
and (max-device-width: 320px)
and (-webkit-min-device-pixel-ratio: 2) {
  .all-categories-tab{
    margin-top: -21px;
    float: right;
    width: 337px;
  }
  .all-categories-li {
    background: white;
    color: black;
    margin-bottom: 5px;
    border-radius: 12px;
    padding: 5px 0 0 10px;
    margin-right: 10px;
    margin-left: 30px;
    width: 330px;
}
.btn-nav-tabs-thisweek{
  padding: 0; 
  margin-bottom: 50px;
  display: block;
}
.btn-nav-tabs-thisweek li {
  padding: 10px;
  text-align: center;
  cursor: pointer; 
}

}
@media only screen 
  and (min-device-width: 321px) 
  and (max-device-width: 360px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .all-categories-tab{
      margin-top: 20px;
      float: right;
      width: 377px;
    }
    .all-categories-li {
      background: white;
      color: black;
      margin-bottom: 5px;
      border-radius: 12px;
      padding: 5px 0 0 10px;
      margin-right: 10px;
      margin-left: 30px;
      width: 330px;
  }
  .btn-nav-tabs-thisweek{
    padding: 0; 
    margin-bottom: 50px;
    display: block;
  }
  .btn-nav-tabs-thisweek li {
    padding: 10px;
    text-align: center;
    cursor: pointer; 
  }
}
@media only screen 
and (min-device-width: 361px) 
and (max-device-width: 375px)
and (-webkit-min-device-pixel-ratio: 2) {
  .all-categories-tab{
    margin-top: 20px;
    float: right;
    width: 391px;
  }
  .all-categories-li {
    background: white;
    color: black;
    margin-bottom: 5px;
    border-radius: 12px;
    padding: 5px 0 0 10px;
    margin-right: 10px;
    margin-left: 30px;
    width: 330px;
}
.btn-nav-tabs-thisweek{
  padding: 0; 
  margin-bottom: 50px;
  display: block;
} .btn-nav-tabs li {
  padding: 10px;
  text-align: center;
  cursor: pointer; 
}

}
@media only screen 
and (min-device-width: 376px) 
and (max-device-width: 414px)
and (-webkit-min-device-pixel-ratio: 2) {
  .all-categories-tab{
    margin-top: 20px;
    float: right;
    width: 426px;
  }
  .all-categories-li {
    background: white;
    color: black;
    margin-bottom: 5px;
    border-radius: 12px;
    padding: 5px 0 0 10px;
    margin-right: 10px;
    margin-left: 30px;
    width: 330px;
}
.btn-nav-tabs-thisweek{
  padding: 0; 
  margin-bottom: 50px;
  display: block;
}
.btn-nav-tabs-thisweek li {
  padding: 10px;
  text-align: center;
  cursor: pointer; 
}
}
@media only screen 
and (min-device-width: 415px) 
and (max-device-width: 768px)
and (-webkit-min-device-pixel-ratio: 2) {
.all-categories-tab{
  margin-top: 20px;
  float: right;
  width: 564px;
}
.btn-nav-tabs-thisweek{
  padding: 0; 
  margin-bottom: 50px;
display: block;
}
.btn-nav-tabs-thisweek li {
  padding: 10px;
  text-align: center;
  cursor: pointer; 
}
}
