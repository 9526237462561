.ratingContainer {
    height: 100vh;
    /* overflow-y: auto;
    max-height: 90vh; */
  }
  
  .btn {
    margin-left: 10px;
  }
  .textEditor {
    height: 100%;
    width: 100%;
    border-style: solid;
    background: white;
  }
  .textEditor1 {
    height: 33%;
    width: 100%;
    border-style: solid;
    background: white;
  }
  .textEditor2 {
    margin-top: 1px;
    height: 33%;
    width: 100%;
    padding-top: 2%;
    border-style: solid;
    background: white;
  }
  
  
  p {
    margin-bottom: 0px;
  }
  
  
  #title {
    text-align: center;
    font-family: arial, sans-serif;
  }
  
  #mupps {
    text-align: center;
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    border: 3px solid #ddd;
    width: 100%;
  }
  
  #mupps td, #mupps th {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  
  #mupps tr:hover {background-color: #f2f2f2}
  
  #mupps th {
    position: sticky;
    top: 0;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #a0a0a0;
    color: white;
    z-index: 9999;
  }
  
  .tableFixHead {
    overflow-y: auto; 
    max-height: 65vh;
  }
  .tableFixHead thead th {
    position: sticky;
    top: 0;
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }
  th,
  td {
    padding: 8px 16px;
    border: 1px solid #ccc;
  }
  th {
    background: #eee;
  }
  
  
  .react-numeric-input input {
    color: red;
    font-size: smaller;
    width:50px;
  }
  