.selected-mupps{
    padding: 0;
    margin: 1em 0;
}
.selected-mupps-h4{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    color: black;
    padding: 10px 10px;
    border-radius: 10px;
}
.selected-mupps-h4 h4{
    font-size: 18px;
}
.selected-mupps-checkbox{
    min-width: 30px;
    min-height: 30px;
    width: 30px;
    height: 30px;
    cursor: pointer;
}