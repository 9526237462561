.test-container{
    padding: 0 3em;
}
.test-header{
    text-align: center;
    margin-bottom: 2em;
}
.test-header p, .test-header a{
    font-size: 25px;
    padding-top: 35px;
    margin-bottom: 5px;
    margin-left: 20px;
    cursor: pointer;
    color:white
}

.artQuestion{
    line-height: normal;
}
.testChild{
    height: 50vh;
}

#btn{
    float: right;
    margin-top: 1em;
    margin-bottom: 1em;
}
#btn:hover {
    background-color: rgb(29, 26, 26); /* Green */
    color: white;
  }

.test-link-div1 {
    display: flex;
    background-color: white;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    height: 65vh;
    width: 75vw !important;
}
.test-link-div2 {
    display: flex;
    background-color: white;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    height: 40vh;
    width: 75vw !important;
}
.highlightBtn{
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 14px;
}
.content-editor-one{
    border-style: none !important;
    min-height: 100px;
}
.content-editor-one p{
    font-size: 18px;
    line-height: normal;
}
.content-editor-two{
    min-height: 200px;
    font-size:18px;
}

@media screen and (min-width: 250px) and (max-width: 750px)  {
   #btn{
    float: right;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-right: 45px;
   }
   .test-container{
    padding: 0 0;
}
.testChild{
    height: 50vh;
}
}


 /* Portrait and Landscape */
 @media only screen 
 and (min-device-width: 240px) 
 and (max-device-width: 320px)
 and (-webkit-min-device-pixel-ratio: 2) {
    #btn{
        float: right;
        margin-top: 1em;
        margin-bottom: 1em;
        margin-right: 45px;
       }
       .test-container{
        padding: 0 0;
    }


   

}
 @media only screen 
   and (min-device-width: 321px) 
   and (max-device-width: 360px)
   and (-webkit-min-device-pixel-ratio: 2) {
    #btn{
        float: right;
        margin-top: 1em;
        margin-bottom: 1em;
        margin-right: 45px;
       }
       .test-container{
        padding: 0 0;
    }

    
	   
	
 }
 @media only screen 
 and (min-device-width: 361px) 
 and (max-device-width: 375px)
 and (-webkit-min-device-pixel-ratio: 2) {
    #btn{
        float: right;
        margin-top: 1em;
        margin-bottom: 1em;
        margin-right: 45px;
       }
       .test-container{
        padding: 0 0;
    }

  
  
  
}
 @media only screen 
 and (min-device-width: 376px) 
 and (max-device-width: 414px)
 and (-webkit-min-device-pixel-ratio: 2) {
  
    #btn{
        float: right;
        margin-top: 1em;
        margin-bottom: 1em;
        margin-right: 45px;
       }
       .test-container{
        padding: 0 0;
    }


  
}
@media only screen 
and (min-device-width: 415px) 
and (max-device-width: 768px)
and (-webkit-min-device-pixel-ratio: 2) {
    #btn{
        float: right;
        margin-top: 1em;
        margin-bottom: 1em;
        margin-right: 45px;
       }
       .test-container{
        padding: 0 0;
    }
  



  

}