#child{
    height: 400px;
    width: 400px;
   padding-left: 30px;
}

#parent-container{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 300px;
    margin-left: 25vw;
}

#child inp {
    position: relative;
    padding: 0px 16px;
    border: none;
    border-radius: 4px;
    font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    background-color: transparent;
    color: #282828;
    outline: none;
    box-shadow: 0px 4px 20px 0px transparent;
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out, 0.1s padding ease-in-out;
    -webkit-appearance: none;
    background:white;
    
  }
  
#inp{
    background-color:rgb(48, 50, 64);
    border: none;
    padding: 12px 15px;
    margin: 8px 0;
    width: 90%;
    
}

#failedBtn{
    
    margin-left: 60px;
    margin-top: 20px;
}

/* Portrait and Landscape */
@media only screen 
and (min-device-width: 240px) 
and (max-device-width: 320px)
and (-webkit-min-device-pixel-ratio: 2) {
    #parent-container{
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 300px;
        margin-left: 0;
    }
    #failedBtn{
    
        margin-left: 20px;
        margin-top: 20px;
    }
    



  

}
@media only screen 
  and (min-device-width: 321px) 
  and (max-device-width: 360px)
  and (-webkit-min-device-pixel-ratio: 2) {

    #parent-container{
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 300px;
        margin-left: 0;
    }
    #failedBtn{
    
        margin-left: 20px;
        margin-top: 20px;
    }
  
   
      
   
}
@media only screen 
and (min-device-width: 361px) 
and (max-device-width: 375px)
and (-webkit-min-device-pixel-ratio: 2) {
  
    #parent-container{
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 300px;
        margin-left: 0;
    }
    #failedBtn{
    
        margin-left: 20px;
        margin-top: 20px;
    }

 
 
}
@media only screen 
and (min-device-width: 376px) 
and (max-device-width: 414px)
and (-webkit-min-device-pixel-ratio: 2) {
 
    #parent-container{
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 300px;
        margin-left: 0;
    }
    #failedBtn{
    
        margin-left: 20px;
        margin-top: 20px;
    }
 

 
}
@media only screen 
and (min-device-width: 415px) 
and (max-device-width: 768px)
and (-webkit-min-device-pixel-ratio: 2) {
    #parent-container{
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 300px;
        margin-left: 0;
    }
    #failedBtn{
    
        margin-left: 20px;
        margin-top: 20px;
    }
  

 

 

}