
.missionStatementContainer1{
    width: 40vw;
     height: 60vh;
     overflow-y: scroll;


}
.missionLeftContainer{
     width: 50%
}

.missionContainer{
    display: flex;
     margin-top: 1em
}

.missionStatementImg{

     height:150px;
      width:300px;
      margin-left: 15px;
}

  /* Portrait and Landscape */
  @media only screen 
  and (min-device-width: 240px) 
  and (max-device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
   

    .missionContainer{
        display: inline;
         margin-top: 1em
    }
     
   
  

}
  @media only screen 
    and (min-device-width: 321px) 
    and (max-device-width: 360px)
    and (-webkit-min-device-pixel-ratio: 2) {
       
                .missionContainer{
                    display: inline;
                     margin-top: 1em
                }
     
       
     
  }
  @media only screen 
  and (min-device-width: 361px) 
  and (max-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 2) {
   
            .missionContainer{
                display: inline;
                 margin-top: 1em
            }
 
   
   
}
  @media only screen 
  and (min-device-width: 376px) 
  and (max-device-width: 414px)
  and (-webkit-min-device-pixel-ratio: 2) {
   
            .missionContainer{
                display: inline;
                 margin-top: 1em
            }
  
}
@media only screen 
and (min-device-width: 415px) 
and (max-device-width: 768px)
and (-webkit-min-device-pixel-ratio: 2) {
    
            .missionContainer{
                display: inline;
                 margin-top: 1em
            }
 
   
 
}
