.self-container{
    padding-left: 0 !important; 
    padding-right: 0 !important;  
}

.selfPage1Img{
  height:150px;
   width:300px
}

.text1{
  height: 100%;
   color: black;
   overflow: scroll
}
.testChild1{
  display: flex;
  margin-left: 20px;
}

.self-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.self-footer-next-button{
    text-align: center;
    margin-top: 1em;
}
.self-footer-next-button button{
    border-radius: 15px;
}
.self-header-h2{
    background: white;
    color: black;
    padding: 1em;
    margin-left: 30px;
}
.highlightBtn{
    margin-left: 5px;
}
[contenteditable=true]:empty:before {
    content: attr(placeholder);
    display: block;
    color: #aaa;
  }

  .test-link-div {
    display: flex;
    background-color: white;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    height: 70vh;
    width: 85vw;
    margin-left:10px;
    margin-bottom: 10px;
}
  .selfpage1Container{
    display: flex;
     height: 60vh;
    margin-Left: 10px;
    margin-Top: 10px
  }
.missionStatementContainer{

    display: flex;
     margin-Top: 1em
}
.selfButton{
  margin-left: 45%;
  margin-top: 2%;
  margin-bottom: 2%;
 
}
  


   /* Portrait and Landscape */
   @media only screen 
   and (min-device-width: 240px) 
   and (max-device-width: 320px)
   and (-webkit-min-device-pixel-ratio: 2) {
       .self-header{
        display: inline;
        justify-content: space-between;
        align-items: center;
       }
       .self-header-h2{
        background: white;
        width: 120%;
        color: black;
        margin-right: 10px;
    }
    .selfpage1Container{
        display: inline;
       
      }
      .text1{
        height: 10%;
         color: black;
         overflow: scroll
      }
       .selfPage1Img{
        height: 180px;
        width: 280px;
        margin-left: 20px;
        margin-top: 10px;
        margin-bottom: 10px;
      } 
      .missionStatementContainer{

        display: inline;
         margin-Top: 1em
    }
    .testChild1{
      display: inline;
    }
    .selfButton{
      margin-left: 30%;     
    }
      
      
    
   
 
 }
   @media only screen 
     and (min-device-width: 321px) 
     and (max-device-width: 360px)
     and (-webkit-min-device-pixel-ratio: 2) {
        .self-header{
            display: inline;
            justify-content: space-between;
            align-items: center;
    
           }
           .self-header-h2{
            background: white;
            color: black;
            margin-left: 20px;
            margin-right: 20px;
        }
        .selfpage1Container{
            display: inline;

       
        }
        .selfPage1Img{
            height: 150px;
            width: 250px;
            margin-left: 30px;
            margin-top: 10px;
            margin-bottom: 10px;
          } 
          .missionStatementContainer{

            display: inline;
             margin-Top: 1em
        }
        .text1{
          height: 10%;
           color: black;
           overflow: scroll
        }
        .testChild1{
          display: inline;
        }
        .selfButton{
          margin-left: 30%;
     
     
        }
        
      
   }
   @media only screen 
   and (min-device-width: 361px) 
   and (max-device-width: 375px)
   and (-webkit-min-device-pixel-ratio: 2) {
    .self-header{
        display: inline;
        justify-content: space-between;
        align-items: center;

       }
       .self-header-h2{
        background: white;
        width: 100%;
        color: black;
    }
    .selfpage1Container{
        display: inline;

       
    }
    .selfPage1Img{
        height: 180px;
        width: 280px;
        margin-left: 20px;
        margin-top: 10px;
        margin-bottom: 10px;
      } 
      .missionStatementContainer{

        display: inline;
         margin-Top: 1em
    }
    .text1{
      height: 10%;
       color: black;
       overflow: scroll
    }
    .testChild1{
      display: inline;
    }
    .selfButton{
      margin-left: 30%;     
    }
    
    
 }
   @media only screen 
   and (min-device-width: 376px) 
   and (max-device-width: 414px)
   and (-webkit-min-device-pixel-ratio: 2) {
    .self-header{
        display: inline;
        justify-content: space-between;
        align-items: center;

       }
       .self-header-h2{
        background: white;
        width: 100%;
        color: black;
    }
    .selfpage1Container{
        display: inline;
    }
    .selfPage1Img{
        height: 180px;
        width: 280px;
        margin-left: 35px;
        margin-top: 10px;
        margin-bottom: 10px;
      } 
      .missionStatementContainer{

        display: inline;
         margin-Top: 1em
    }
    .text1{
      height: 10%;
       color: black;
       overflow: scroll
    }
    .testChild1{
      display: inline;
    }
    .selfButton{
      margin-left: 30%;     
    }
 }
 @media only screen 
 and (min-device-width: 415px) 
 and (max-device-width: 768px)
 and (-webkit-min-device-pixel-ratio: 2) {
    .self-header{
        display: flex;
        justify-content: space-between;
        align-items: center;

       }
       .self-header-h2{
        background: white;
        width: 110%;
        color: black;
    }
    .selfpage1Container{
        display: flex;
    }
    .selfPage1Img{
        height: 180px;
        width: 280px;
        margin-left: 20px;
        margin-top: 10px;
        margin-bottom: 10px;
      } 
      .missionStatementContainer{

        display: inline;
         margin-Top: 1em
    }
    .text1{
      height: 50%;
       color: black;
       overflow: scroll;
       
    }
    .testChild1{
      display: inline;
      justify-content: center;
      align-items: center;
      
    }
    .selfButton{
      margin-left: 30%;

     
     
    }
    
  
 }
