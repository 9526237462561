.inbox-tabs{
    padding: 0px 20px;
    margin-bottom: 10px;
    margin-top:20px;
    margin-left: 80px;
    margin-right: 80px;
}
.inbox-tabs li{
    list-style-type: none;
    border-radius: 12px;
}
.inbox-tabs .inbox-tab-li{
    background: white;
    color: black;
    padding: 10px;
    padding-left: 20px;
}
.inbox-tab-li button{
    border-radius: 12px;
    margin-left:-20px;
}

.text-ellipses-h4 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 20px;
}
.task-accordion{
    position: absolute;
    right: 0;
    height: 100%;
    padding: 1em;
    z-index: 1;
    top: 0;
    background-color: #f0f0f0;
    width: 35%;
    overflow-x: hidden;
    padding-top: 20px;
    color: black;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #2F4050 !important;
    color: white;
}
.accordion > .card > .card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0 !important;
    background-color: rgb(191 44 44 / 3%);
    border-bottom: 1px solid white;
}
.card-header a:hover {
    color: white !important;
    text-decoration: none !important;
}
.card-title {
    font-size: 18px;
    color: white;
}
.inside-mupps{
    padding: 10px;
    font-size: 16px;
}
.inside-mupps label{
    margin-right: 6px !important;
}
.accordion .card-body {
    padding: 5px 0px !important;
}
.last-div{
    /* background-color: #2F4050; */
     color: white;
}
.plus-icon, .vertical-ellipses{
    float: right;
    cursor: pointer;
}
.card-body ul{
    margin: 0 !important;
}
.card-body ul li{
    margin: 0 23px 0 0;
    padding: 5px 0;
}
.nested-collabpse-div{
    text-align: right;
}
.edit-icon{
    margin-right: 5px;
}
.edit-input-field{
    cursor: pointer;
    margin: 0 !important;
}
.check-icon{
    position: absolute;
    right: 25px;
    top: 70px;
}
.home-mupps-form-div{
    display: flex;
    justify-content: center;
    align-items: center;
}
.home-mupps-form-div .form-group{
    text-align: center;
    background: #2F4050;
    padding: 2em;
    color: white;
}
.home-mupps-form-div .form-group label{
    font-size: 22px;
}
.home-mupps-form-div .form-group button{
    border-radius: 35px;
    margin-top: 10px;
    padding: 6px 26px !important;
}
.back-button{
    text-align: right;
    font-size: 25px;
    margin-right: 10px;
}
.fa {
    cursor: pointer;
}
.yes-button{
    margin-right: 5px;
}
.project-cursor{
    cursor: pointer;
}
.render-task{
    padding: 0 !important;
    cursor: pointer;
}
.render-task li{
    background: white;
    color: black;
    padding: 2px 10px;
    margin: 1em;
    list-style-type: none;
}
.render-task div span {
    font-size: 14px;
    margin-right: 20px;
}
.render-task h4{
    font-size: 20px !important;
}
.render-task li h4{
    text-align: left;
}
.form-div{
    display: flex;
    justify-content: center;
}
.inline-text{
    display: inline-block;
}
.inline-text h4{
    font-size: 18px !important;
}
.add-mupps-button{
    text-align: right;
}
.add-mupps-button button{
    border-radius: 35px;
    margin-top: 10px;
    padding: 6px 26px !important;
}
.task-type{
    margin-right: 1em;
}
.close-icon{
    font-size: 25px;
    position: fixed;
    right: 0;
    z-index: 9999;
    top: 0;
    color: black;
}
.calendar-icon{
    margin-right: 5px;
}

@media only screen 
and (min-device-width: 240px) 
and (max-device-width: 320px)
and (-webkit-min-device-pixel-ratio: 2) {
  
    .inbox-tabs {
        padding: 0px 20px;
        margin-bottom: 10px;
        margin-top: 20px;
        margin-left: -47px;
        margin-right: -47px;
    }
    .close-icon{
        font-size: 25px;
        position: absolute;
        right: 0;
        z-index: 9999;
        top: 0;
        color: black;
    }
}
@media only screen 
  and (min-device-width: 321px) 
  and (max-device-width: 360px)
  and (-webkit-min-device-pixel-ratio: 2) {
     
    .inbox-tabs {
        padding: 0px 20px;
        margin-bottom: 10px;
        margin-top: 20px;
        margin-left: -47px;
        margin-right: -47px;
    }
    .close-icon{
        font-size: 25px;
        position: absolute;
        right: 0;
        z-index: 9999;
        top: 0;
        color: black;
    }
}
@media only screen 
and (min-device-width: 361px) 
and (max-device-width: 375px)
and (-webkit-min-device-pixel-ratio: 2) {
    .inbox-tabs {
        padding: 0px 20px;
        margin-bottom: 10px;
        margin-top: 20px;
        margin-left: -47px;
        margin-right: -47px;
    }
    .close-icon{
        font-size: 25px;
        position: absolute;
        right: 0;
        z-index: 9999;
        top: 0;
        color: black;
    }

}
@media only screen 
and (min-device-width: 376px) 
and (max-device-width: 414px)
and (-webkit-min-device-pixel-ratio: 2) {
 
    .inbox-tabs {
        padding: 0px 20px;
        margin-bottom: 10px;
        margin-top: 20px;
        margin-left: -47px;
        margin-right: -47px;
    }
    .close-icon{
        font-size: 25px;
        position: absolute;
        right: 0;
        z-index: 9999;
        top: 0;
        color: black;
    }
}
@media only screen 
and (min-device-width: 415px) 
and (max-device-width: 768px)
and (-webkit-min-device-pixel-ratio: 2) {

    .inbox-tabs {
        padding: 0px 20px;
        margin-bottom: 10px;
        margin-top: 20px;
        margin-left: -47px;
        margin-right: -47px;
    }
    .close-icon{
        font-size: 25px;
        position: absolute;
        right: 0;
        z-index: 9999;
        top: 0;
        color: black;
    }
}
