.page__container {
  position: relative;
}
.mainPDFContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.page-controls {
  position: fixed;
  z-index: 1002;
  bottom: 0;
  background: #101010;
  width: calc(100% - 250px);
  left: 0;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 0.2rem;
  margin-left: 250px;
}

.page-controls button {
  width: 44px;
  height: 44px;
  background: white;
  color: black;
  border: 0;
  font: inherit;
  font-size: 0.8em;
  border-radius: 4px;
}

.page-controls span {
  font: inherit;
  font-size: 0.8em;
  padding: 0 0.5em;
}

@media screen, (min-width: 500px) and (hover) {
  .react-pdf__Page__canva {
    margin: 0 auto;
    width: fit-content !important;
    height: 90vh !important;
    margin-top: 1rem;
  }
  .react-pdf__Page__annotations {
    display: none;
  }
}
