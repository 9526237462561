.SelectedWeekExample .DayPicker-Month {
    border-collapse: separate;
  }
  .SelectedWeekExample .DayPicker-WeekNumber {
    outline: none;
  }
  .SelectedWeekExample .DayPicker-Day {
    outline: none;
    border: 1px solid transparent;
  }
  .SelectedWeekExample .DayPicker-Day--hoverRange {
    background-color: red !important;
  } 
  .calenderTaskli{
    border: 1px solid;
    padding: 10px;
    border-radius: 20px;
    background: white;
    color: black;
    margin-bottom: 10px;
}
  .SelectedWeekExample .DayPicker-Day--selectedRange {
    background-color: #fff7ba !important;
    border-top-color: #FFEB3B;
    border-bottom-color: #FFEB3B;
    border-left-color: #fff7ba;
    border-right-color: #fff7ba;
  }

  .SelectedWeekExample .DayPicker-Day--selectedRangeStart {
    background-color: #FFEB3B !important;
    border-left: 1px solid #FFEB3B;
  }

  .SelectedWeekExample .DayPicker-Day--selectedRangeEnd {
    background-color: #FFEB3B !important;
    border-right: 1px solid #FFEB3B;
  }

  .SelectedWeekExample .DayPicker-Day--selectedRange:not(.DayPicker-Day--outside).DayPicker-Day--selected,
  .SelectedWeekExample .DayPicker-Day--hoverRange:not(.DayPicker-Day--outside).DayPicker-Day--selected {
    border-radius: 0 !important;
    color: black !important;
  }
  .SelectedWeekExample .DayPicker-Day--hoverRange:hover {
    border-radius: 0 !important;
  }
  .btn-nav-tabs{
    padding: 0;
    /* border-bottom: 1px solid; */
    margin-bottom: 50px;
  }
  
  .btn-nav-tabs li {
    padding: 10px;
    text-align: center;
    cursor: pointer;
    border-right: 1px solid;
  }
  .btnRem{
    margin-top: 5px;
    margin-right:10px;
    padding: 10px;
  }
  .btnTask{
    margin-top:10px;
    padding: 10px 20px;
  }
  @media only screen 
  and (min-device-width: 240px) 
  and (max-device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
    
    .btn-nav-tabs{
      padding: 0;
      /* border-bottom: 1px solid; */
      margin-bottom: 50px;
    }
    
    .btn-nav-tabs li {
      padding: 10px;
      text-align: center;
      cursor: pointer;
      /* border-right: 1px solid; */
    }
}
  @media only screen 
    and (min-device-width: 321px) 
    and (max-device-width: 360px)
    and (-webkit-min-device-pixel-ratio: 2) {
       
      .btn-nav-tabs{
        padding: 0;
        /* border-bottom: 1px solid; */
        margin-bottom: 50px;
      }
      
      .btn-nav-tabs li {
        padding: 10px;
        text-align: center;
        cursor: pointer;
        /* border-right: 1px solid; */
      }
  }
  @media only screen 
  and (min-device-width: 361px) 
  and (max-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .btn-nav-tabs{
      padding: 0;
      /* border-bottom: 1px solid; */
      margin-bottom: 50px;
    }
    
    .btn-nav-tabs li {
      padding: 10px;
      text-align: center;
      cursor: pointer;
      /* border-right: 1px solid; */
    }

}
  @media only screen 
  and (min-device-width: 376px) 
  and (max-device-width: 414px)
  and (-webkit-min-device-pixel-ratio: 2) {
   
    .btn-nav-tabs{
      padding: 0;
      /* border-bottom: 1px solid; */
      margin-bottom: 50px;
    }
    
    .btn-nav-tabs li {
      padding: 10px;
      text-align: center;
      cursor: pointer;
      /* border-right: 1px solid; */
    }
}
@media only screen 
and (min-device-width: 415px) 
and (max-device-width: 768px)
and (-webkit-min-device-pixel-ratio: 2) {
  
  .btn-nav-tabs{
    padding: 0;
    /* border-bottom: 1px solid; */
    margin-bottom: 50px;
  }
  
  .btn-nav-tabs li {
    padding: 10px;
    text-align: center;
    cursor: pointer;
    /* border-right: 1px solid; */
  }
}