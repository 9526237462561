.add-mupps-footer-btn{
    display: flex;
    justify-content: space-around;
    margin-top: 1em;
    margin-right: 1em;
}
.add-mupps-footer-btn-isHidden{
    display: flex;
    justify-content: flex-end;
    margin-top: 1em;
    margin-right: 1em;
}
.nextBtn{
    margin-right: 20px;
    justify-content: flex-end;
    border-radius: 15px;
    padding: 10px 24px;
}
.backBtn{
   border-radius: 15px;
   padding: 10px 24px;

}
.addBtn{
    margin-left:20px;
    border-radius: 15px;
    padding: 10px 24px;
}
.addMuppContainer{
    display: flex;
     margin-left: 10px
}

  /* Portrait and Landscape */
  @media only screen 
  and (min-device-width: 240px) 
  and (max-device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
     .addMuppContainer{
         display: inline;
     }
     
   
  

}
  @media only screen 
    and (min-device-width: 321px) 
    and (max-device-width: 360px)
    and (-webkit-min-device-pixel-ratio: 2) {
        .addMuppContainer{
            display: inline;
        }
     
       
     
  }
  @media only screen 
  and (min-device-width: 361px) 
  and (max-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .addMuppContainer{
        display: inline;
    }
  
   
}
  @media only screen 
  and (min-device-width: 376px) 
  and (max-device-width: 414px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .addMuppContainer{
        display: inline;
    }
  
   
}
@media only screen 
and (min-device-width: 415px) 
and (max-device-width: 768px)
and (-webkit-min-device-pixel-ratio: 2) {
    .addMuppContainer{
        display: inline;
    }
  
 
}